import { Routes, BrowserRouter, Route } from 'react-router-dom';
import Home from '../pages/Home'
import Login from '../pages/Login';

export default function RoutesLink(){
    return(
      <BrowserRouter>
        <Routes>
    
        <Route path="/" element={<Home />}></Route>
        <Route path="/login" element={<Login />}></Route>

          
        </Routes>
      </BrowserRouter>
    )
  }
import "./header.css";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState, useContext } from "react";
import Select from "react-select";
import logoneg from "../assets/AreaInsight_Logo_Negativo-Linkages.png";
import { FiHome, FiUser, FiSettings, FiUsers } from "react-icons/fi";
import {
  FaSignOutAlt,
  FaHourglassHalf,
  FaFileInvoiceDollar,
  FaCity,
} from "react-icons/fa";
import { SiBookstack } from "react-icons/si";
import { HiDocumentReport } from "react-icons/hi";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { BsFillLayersFill, BsCloudDownloadFill } from "react-icons/bs";
import { BiMapAlt } from "react-icons/bi";
import { RiLock2Fill } from "react-icons/ri";
import { Accordion } from "flowbite-react";

import estadosmunicipios from "../municipios/estadosmunicipios.json";
import estadosmunicipiosTheJoy from "../municipios/thejoy.json";
import estadosmunicipiosluka from "../municipios/luka.json";
import estadosmunicipiosTheJoySchool from "../municipios/thejoyschool.json";
import estadosmunicipiosGenericos from "../municipios/gen.json";
import estadosmunicipiosGurgel from "../municipios/gurgel.json";
import estadosmunicipiosEdu from "../municipios/pizzaedu.json";
import estadosCRCA from "../municipios/crca.json";
import estadosMiada from "../municipios/miada.json";
import estadosManaPoke from "../municipios/manapoke.json";
import estadosBuddahSPA from "../municipios/buddahspa.json";
import estadosBaldoni from "../municipios/baldoni.json";
import estadosCaribu from "../municipios/caribu.json";
import estadosTony from "../municipios/tony.json";
import estadosAccenture from "../municipios/accenture.json";
import estadosRSG from "../municipios/reformasg.json";
import estadosPeconi from "../municipios/peconi.json";
import estadosUnicamp from "../municipios/pesquisaunicamp.json";
import estadosBoibrabo from "../municipios/boibrabo.json";
import estadosDidio from "../municipios/didio.json";
import estadosAccenture2 from "../municipios/accenture2.json";
import estadosAccenture3 from "../municipios/accenture3.json";
import estadosSucao from "../municipios/sucao.json";
import estadosBRZ from "../municipios/brz.json";
import estadosCirculagem from "../municipios/circulagem.json";
import estadosMRV from "../municipios/mrv.json";
import estadosIC from "../municipios/investcorp.json";
import estadosMelten from "../municipios/melten.json";
import estadosVenture from "../municipios/venturehub.json";
import estadosEmccamp from "../municipios/emccamp.json";
import estadosWBGI from "../municipios/wbgi.json";
import estadosTenda from "../municipios/tenda.json";
import estadosGB from "../municipios/gb.json";
import estadosRiva from "../municipios/riva.json";
import estadosTenda2 from "../municipios/tenda2.json";
import estadosAureo from "../municipios/aureo.json";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from "react-tooltip";
import { AuthContext } from "../contexts/auth";

export default function Header({
  estadoChange,
  municipioChange,
  camadasChange,
  labelsVisibleChange,
  imprimir,
}) {
  const { user, signOut } = useContext(AuthContext);
  const navigate = useNavigate();

  const [labelsVisible, setLabelsVisible] = useState(true);

  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedEstado, setSelectedEstado] = useState(null);
  const [municipios, setMunicipios] = useState([]);
  const [selectedMun, setSelectedMun] = useState(null);
  const [userType, setUserType] = useState("All");
  const [estadosNames, setEstadosNames] = useState([]);

  useEffect(() => {
    setLoading(true);
    var userlg = JSON.parse(localStorage.getItem("@loggedAreaInsight"));

    if (userlg) {
      if (userlg.email === "rosekillertomaz@hotmail.com") {
        setUserType("TheJoy");
        let { estados } = estadosmunicipiosTheJoy;
        setEstadosNames(
          estados.map(({ sigla, nome }) => ({ value: sigla, label: nome }))
        );
      } else if (userlg.email === "jonas.zandona@linkages.com.br") {
        let { estados } = estadosmunicipios;
        setEstadosNames(
          estados.map(({ sigla, nome }) => ({ value: sigla, label: nome }))
        );
        setUserType("All");
      } else if (userlg.email === "lucas@lukaimoveis.com.br") {
        let { estados } = estadosmunicipiosluka;
        setEstadosNames(
          estados.map(({ sigla, nome }) => ({ value: sigla, label: nome }))
        );
        setUserType("luka");
      } else if (userlg.email === "pedagogicofranquia@thejoyschool.com.br") {
        let { estados } = estadosmunicipiosTheJoySchool;
        setEstadosNames(
          estados.map(({ sigla, nome }) => ({ value: sigla, label: nome }))
        );
        setUserType("thejoyschool");
      } else if (userlg.email === "atendimento@linkages.com.br") {
        let { estados } = estadosmunicipiosGenericos;
        setEstadosNames(
          estados.map(({ sigla, nome }) => ({ value: sigla, label: nome }))
        );
        setUserType("gen");
      } else if (
        userlg.email === "gurgel@inova.unicamp.br" ||
        userlg.email === "mariana.zanatta@inova.unicamp.br" ||
        userlg.email === "renato.lopes@inova.unicamp.br" ||
        userlg.email === "rangel.arthur@inova.unicamp.br"
      ) {
        let { estados } = estadosmunicipiosGurgel;
        setEstadosNames(
          estados.map(({ sigla, nome }) => ({ value: sigla, label: nome }))
        );
        setUserType("gurgel");
      } else if (userlg.email === "eduamato@gmail.com") {
        let { estados } = estadosmunicipiosEdu;
        setEstadosNames(
          estados.map(({ sigla, nome }) => ({ value: sigla, label: nome }))
        );
        setUserType("edu");
      } else if (userlg.email === "goncalves.dimas@gmail.com") {
        let { estados } = estadosCRCA;
        setEstadosNames(
          estados.map(({ sigla, nome }) => ({ value: sigla, label: nome }))
        );
        setUserType("crca");
      } else if (userlg.email === "elisabeth.miada@br.mcd.com") {
        let { estados } = estadosMiada;
        setEstadosNames(
          estados.map(({ sigla, nome }) => ({ value: sigla, label: nome }))
        );
        setUserType("miada");
      } else if (userlg.email === "simone.sousa@manapoke.com.br") {
        let { estados } = estadosManaPoke;
        setEstadosNames(
          estados.map(({ sigla, nome }) => ({ value: sigla, label: nome }))
        );
        setUserType("manapoke");
      } else if (userlg.email === "anderson.ribeiro@buddhaspa.com.br") {
        let { estados } = estadosBuddahSPA;
        setEstadosNames(
          estados.map(({ sigla, nome }) => ({ value: sigla, label: nome }))
        );
        setUserType("buddahspa");
      } else if (userlg.email === "baldoniagropesca@hotmail.com") {
        let { estados } = estadosBaldoni;
        setEstadosNames(
          estados.map(({ sigla, nome }) => ({ value: sigla, label: nome }))
        );
        setUserType("baldoni");
      } else if (userlg.email === "igor-barbosa@hotmail.com") {
        let { estados } = estadosCaribu;
        setEstadosNames(
          estados.map(({ sigla, nome }) => ({ value: sigla, label: nome }))
        );
        setUserType("caribu");
      } else if (userlg.email === "antonio.casarotti@acjup.com.br") {
        let { estados } = estadosTony;
        setEstadosNames(
          estados.map(({ sigla, nome }) => ({ value: sigla, label: nome }))
        );
        setUserType("tony");
      } else if (userlg.email === "l.figueiredo@accenture.com") {
        let { estados } = estadosAccenture;
        setEstadosNames(
          estados.map(({ sigla, nome }) => ({ value: sigla, label: nome }))
        );
        setUserType("accenture");
      } else if (userlg.email === "desordi@reformassemgrilo.com.br") {
        let { estados } = estadosRSG;
        setEstadosNames(
          estados.map(({ sigla, nome }) => ({ value: sigla, label: nome }))
        );
        setUserType("rsg");
      } else if (userlg.email === "rqformigoni@gmail.com") {
        let { estados } = estadosPeconi;
        setEstadosNames(
          estados.map(({ sigla, nome }) => ({ value: sigla, label: nome }))
        );
        setUserType("peconi");
        // } else if (userlg.email === "mariana.zanatta@inova.unicamp.br") {
        //   let { estados } = estadosUnicamp;
        //   setEstadosNames(
        //     estados.map(({ sigla, nome }) => ({ value: sigla, label: nome }))
        //   );
        //   setUserType("pesquisaunicamp");
      } else if (userlg.email === "analuisa.godoy@btgpactual.com") {
        let { estados } = estadosBoibrabo;
        setEstadosNames(
          estados.map(({ sigla, nome }) => ({ value: sigla, label: nome }))
        );
        setUserType("boibrabo");
      } else if (userlg.email === "marcelo@didio.com.br") {
        let { estados } = estadosDidio;
        setEstadosNames(
          estados.map(({ sigla, nome }) => ({ value: sigla, label: nome }))
        );
        setUserType("didio");
      } else if (
        userlg.email === "gabriel.leitao@accenture.com" ||
        userlg.email === "nilton.cordeiro@vli-logistica.com.br" ||
        userlg.email === "priscila.souza@vli-logistica.com.br"
      ) {
        let { estados } = estadosAccenture2;
        setEstadosNames(
          estados.map(({ sigla, nome }) => ({ value: sigla, label: nome }))
        );
        setUserType("acc2");
      } else if (userlg.email === "sucaoparceiro@gmail.com") {
        let { estados } = estadosSucao;
        setEstadosNames(
          estados.map(({ sigla, nome }) => ({ value: sigla, label: nome }))
        );
        setUserType("sucao");
      } else if (userlg.email === "cristiano.matos@brz.eng.br") {
        let { estados } = estadosBRZ;
        setEstadosNames(
          estados.map(({ sigla, nome }) => ({ value: sigla, label: nome }))
        );
        setUserType("brz");
      } else if (userlg.email === "daniela.circulagem@gmail.com") {
        let { estados } = estadosCirculagem;
        setEstadosNames(
          estados.map(({ sigla, nome }) => ({ value: sigla, label: nome }))
        );
        setUserType("circulagem");
      } else if (
        // userlg.email === "mateus.squito@mrv.com.br" ||
        // userlg.email === "rogerio.nagazawa@mrv.com.br" ||
        // userlg.email === "mariana.matheus@mrv.com.br" ||
        // userlg.email === "murilo.quesada@mrv.com.br" ||
        // userlg.email === "marcela.hmello@mrv.com.br" ||
        // userlg.email === "gustavo.vitorino@mrv.com.br" ||
        // userlg.email === "osmar.nunes@gmail.com" ||
        // userlg.email === "patrick@meuinc.com.br" ||
        userlg.email === "malu.magalhaes@linkages.com.br" || 
        userlg.email === "jguazz@technopark.com.br"
      ) {
        let { estados } = estadosMRV;
        setEstadosNames(
          estados.map(({ sigla, nome }) => ({ value: sigla, label: nome }))
        );
        setUserType("mrv");
      } else if (userlg.email === "nelson@investcorp.com.br") {
        let { estados } = estadosIC;
        setEstadosNames(
          estados.map(({ sigla, nome }) => ({ value: sigla, label: nome }))
        );
        setUserType("investcorp");
      } else if (userlg.email === "lucas@meltenco.com.br") {
        let { estados } = estadosMelten;
        setEstadosNames(
          estados.map(({ sigla, nome }) => ({ value: sigla, label: nome }))
        );
        setUserType("melten");
      } else if (userlg.email === "azarite@venturehub.se") {
        let { estados } = estadosVenture;
        setEstadosNames(
          estados.map(({ sigla, nome }) => ({ value: sigla, label: nome }))
        );
        setUserType("venturehub");
      } else if (userlg.email === "renato.junqueira@emccamp.com.br") {
        let { estados } = estadosEmccamp;
        setEstadosNames(
          estados.map(({ sigla, nome }) => ({ value: sigla, label: nome }))
        );
        setUserType("emccamp");
      } else if (userlg.email === "guillermograndini@wbgi.com.br") {
        let { estados } = estadosWBGI;
        setEstadosNames(
          estados.map(({ sigla, nome }) => ({ value: sigla, label: nome }))
        );
        setUserType("wbgi");
      } else if (
        userlg.email === "bhaddad@tenda.com" ||
        userlg.email === "mwerneck@tenda.com"
      ) {
        let { estados } = estadosWBGI;
        setEstadosNames(
          estados.map(({ sigla, nome }) => ({ value: sigla, label: nome }))
        );
        setUserType("tenda");
      } else if (userlg.email === "gerardobraq@hotmail.com") {
        let { estados } = estadosGB;
        setEstadosNames(
          estados.map(({ sigla, nome }) => ({ value: sigla, label: nome }))
        );
        setUserType("gb");
      } else if (userlg.email === "kelen.barbosa@rivaincorporadora.com.br") {
        let { estados } = estadosRiva;
        setEstadosNames(
          estados.map(({ sigla, nome }) => ({ value: sigla, label: nome }))
        );
        setUserType("riva");
      } else if (userlg.email === "beatriz.siqueira@tenda.com") {
        let { estados } = estadosTenda2;
        setEstadosNames(
          estados.map(({ sigla, nome }) => ({ value: sigla, label: nome }))
        );
        setUserType("tenda2");
      } else if (userlg.email === "wladimir@aureoconsultoria.com.br") {
        let { estados } = estadosAureo;
        setEstadosNames(
          estados.map(({ sigla, nome }) => ({ value: sigla, label: nome }))
        );
        setUserType("aureo");
      } else if (userlg.email === "lara.gomes@accenture.com") {
        let { estados } = estadosAccenture3;
        setEstadosNames(
          estados.map(({ sigla, nome }) => ({ value: sigla, label: nome }))
        );
        setUserType("acc3");
      }
    }
    setLoading(false);
  }, []);

  const handleSelectChange = (selectedEstado) => {
    setSelectedMun(null);
    setSelectedEstado(selectedEstado);
    estadoChange(selectedEstado);

    if (userType === "TheJoy") {
      const estado = estadosmunicipiosTheJoy.estados.find(
        (estado) => estado.sigla === selectedEstado.value
      );
      const cidades = estado ? estado.cidades : [];
      const muns = [];
      cidades.map((item) => {
        muns.push({
          value: item,
          label: item,
        });
      });
      setMunicipios(muns);
    } else if (userType === "All") {
      const estado = estadosmunicipios.estados.find(
        (estado) => estado.sigla === selectedEstado.value
      );
      const cidades = estado ? estado.cidades : [];
      const muns = [];
      cidades.map((item) => {
        muns.push({
          value: item,
          label: item,
        });
      });
      setMunicipios(muns);
    } else if (userType === "luka") {
      const estado = estadosmunicipiosluka.estados.find(
        (estado) => estado.sigla === selectedEstado.value
      );
      const cidades = estado ? estado.cidades : [];
      const muns = [];
      cidades.map((item) => {
        muns.push({
          value: item,
          label: item,
        });
      });
      setMunicipios(muns);
    } else if (userType === "thejoyschool") {
      const estado = estadosmunicipiosTheJoySchool.estados.find(
        (estado) => estado.sigla === selectedEstado.value
      );
      const cidades = estado ? estado.cidades : [];
      const muns = [];
      cidades.map((item) => {
        muns.push({
          value: item,
          label: item,
        });
      });
      setMunicipios(muns);
    } else if (userType === "gen") {
      const estado = estadosmunicipiosGenericos.estados.find(
        (estado) => estado.sigla === selectedEstado.value
      );
      const cidades = estado ? estado.cidades : [];
      const muns = [];
      cidades.map((item) => {
        muns.push({
          value: item,
          label: item,
        });
      });
      setMunicipios(muns);
    } else if (userType === "gurgel") {
      const estado = estadosmunicipiosGurgel.estados.find(
        (estado) => estado.sigla === selectedEstado.value
      );
      const cidades = estado ? estado.cidades : [];
      const muns = [];
      cidades.map((item) => {
        muns.push({
          value: item,
          label: item,
        });
      });
      setMunicipios(muns);
    } else if (userType === "edu") {
      const estado = estadosmunicipiosEdu.estados.find(
        (estado) => estado.sigla === selectedEstado.value
      );
      const cidades = estado ? estado.cidades : [];
      const muns = [];
      cidades.map((item) => {
        muns.push({
          value: item,
          label: item,
        });
      });
      setMunicipios(muns);
    } else if (userType === "crca") {
      const estado = estadosCRCA.estados.find(
        (estado) => estado.sigla === selectedEstado.value
      );
      const cidades = estado ? estado.cidades : [];
      const muns = [];
      cidades.map((item) => {
        muns.push({
          value: item,
          label: item,
        });
      });
      setMunicipios(muns);
    } else if (userType === "miada") {
      const estado = estadosMiada.estados.find(
        (estado) => estado.sigla === selectedEstado.value
      );
      const cidades = estado ? estado.cidades : [];
      const muns = [];
      cidades.map((item) => {
        muns.push({
          value: item,
          label: item,
        });
      });
      setMunicipios(muns);
    } else if (userType === "manapoke") {
      const estado = estadosManaPoke.estados.find(
        (estado) => estado.sigla === selectedEstado.value
      );
      const cidades = estado ? estado.cidades : [];
      const muns = [];
      cidades.map((item) => {
        muns.push({
          value: item,
          label: item,
        });
      });
      setMunicipios(muns);
    } else if (userType === "buddahspa") {
      const estado = estadosBuddahSPA.estados.find(
        (estado) => estado.sigla === selectedEstado.value
      );
      const cidades = estado ? estado.cidades : [];
      const muns = [];
      cidades.map((item) => {
        muns.push({
          value: item,
          label: item,
        });
      });
      setMunicipios(muns);
    } else if (userType === "baldoni") {
      const estado = estadosBaldoni.estados.find(
        (estado) => estado.sigla === selectedEstado.value
      );
      const cidades = estado ? estado.cidades : [];
      const muns = [];
      cidades.map((item) => {
        muns.push({
          value: item,
          label: item,
        });
      });
      setMunicipios(muns);
    } else if (userType === "caribu") {
      const estado = estadosCaribu.estados.find(
        (estado) => estado.sigla === selectedEstado.value
      );
      const cidades = estado ? estado.cidades : [];
      const muns = [];
      cidades.map((item) => {
        muns.push({
          value: item,
          label: item,
        });
      });
      setMunicipios(muns);
    } else if (userType === "tony") {
      const estado = estadosTony.estados.find(
        (estado) => estado.sigla === selectedEstado.value
      );
      const cidades = estado ? estado.cidades : [];
      const muns = [];
      cidades.map((item) => {
        muns.push({
          value: item,
          label: item,
        });
      });
      setMunicipios(muns);
    } else if (userType === "accenture") {
      const estado = estadosAccenture.estados.find(
        (estado) => estado.sigla === selectedEstado.value
      );
      const cidades = estado ? estado.cidades : [];
      const muns = [];
      cidades.map((item) => {
        muns.push({
          value: item,
          label: item,
        });
      });
      setMunicipios(muns);
    } else if (userType === "rsg") {
      const estado = estadosRSG.estados.find(
        (estado) => estado.sigla === selectedEstado.value
      );
      const cidades = estado ? estado.cidades : [];
      const muns = [];
      cidades.map((item) => {
        muns.push({
          value: item,
          label: item,
        });
      });
      setMunicipios(muns);
    } else if (userType === "peconi") {
      const estado = estadosPeconi.estados.find(
        (estado) => estado.sigla === selectedEstado.value
      );
      const cidades = estado ? estado.cidades : [];
      const muns = [];
      cidades.map((item) => {
        muns.push({
          value: item,
          label: item,
        });
      });
      setMunicipios(muns);
    } else if (userType === "pesquisaunicamp") {
      const estado = estadosUnicamp.estados.find(
        (estado) => estado.sigla === selectedEstado.value
      );
      const cidades = estado ? estado.cidades : [];
      const muns = [];
      cidades.map((item) => {
        muns.push({
          value: item,
          label: item,
        });
      });
      setMunicipios(muns);
    } else if (userType === "boibrabo") {
      const estado = estadosBoibrabo.estados.find(
        (estado) => estado.sigla === selectedEstado.value
      );
      const cidades = estado ? estado.cidades : [];
      const muns = [];
      cidades.map((item) => {
        muns.push({
          value: item,
          label: item,
        });
      });
      setMunicipios(muns);
    } else if (userType === "didio") {
      const estado = estadosDidio.estados.find(
        (estado) => estado.sigla === selectedEstado.value
      );
      const cidades = estado ? estado.cidades : [];
      const muns = [];
      cidades.map((item) => {
        muns.push({
          value: item,
          label: item,
        });
      });
      setMunicipios(muns);
    } else if (userType === "acc2") {
      const estado = estadosAccenture2.estados.find(
        (estado) => estado.sigla === selectedEstado.value
      );
      const cidades = estado ? estado.cidades : [];
      const muns = [];
      cidades.map((item) => {
        muns.push({
          value: item,
          label: item,
        });
      });
      setMunicipios(muns);
    } else if (userType === "sucao") {
      const estado = estadosSucao.estados.find(
        (estado) => estado.sigla === selectedEstado.value
      );
      const cidades = estado ? estado.cidades : [];
      const muns = [];
      cidades.map((item) => {
        muns.push({
          value: item,
          label: item,
        });
      });
      setMunicipios(muns);
    } else if (userType === "brz") {
      const estado = estadosBRZ.estados.find(
        (estado) => estado.sigla === selectedEstado.value
      );
      const cidades = estado ? estado.cidades : [];
      const muns = [];
      cidades.map((item) => {
        muns.push({
          value: item,
          label: item,
        });
      });
      setMunicipios(muns);
    } else if (userType === "circulagem") {
      const estado = estadosCirculagem.estados.find(
        (estado) => estado.sigla === selectedEstado.value
      );
      const cidades = estado ? estado.cidades : [];
      const muns = [];
      cidades.map((item) => {
        muns.push({
          value: item,
          label: item,
        });
      });
      setMunicipios(muns);
    } else if (userType === "mrv") {
      const estado = estadosMRV.estados.find(
        (estado) => estado.sigla === selectedEstado.value
      );
      const cidades = estado ? estado.cidades : [];
      const muns = [];
      cidades.map((item) => {
        muns.push({
          value: item,
          label: item,
        });
      });
      setMunicipios(muns);
    } else if (userType === "investcorp") {
      const estado = estadosIC.estados.find(
        (estado) => estado.sigla === selectedEstado.value
      );
      const cidades = estado ? estado.cidades : [];
      const muns = [];
      cidades.map((item) => {
        muns.push({
          value: item,
          label: item,
        });
      });
      setMunicipios(muns);
    } else if (userType === "melten") {
      const estado = estadosMelten.estados.find(
        (estado) => estado.sigla === selectedEstado.value
      );
      const cidades = estado ? estado.cidades : [];
      const muns = [];
      cidades.map((item) => {
        muns.push({
          value: item,
          label: item,
        });
      });
      setMunicipios(muns);
    } else if (userType === "venturehub") {
      const estado = estadosVenture.estados.find(
        (estado) => estado.sigla === selectedEstado.value
      );
      const cidades = estado ? estado.cidades : [];
      const muns = [];
      cidades.map((item) => {
        muns.push({
          value: item,
          label: item,
        });
      });
      setMunicipios(muns);
    } else if (userType === "emccamp") {
      const estado = estadosEmccamp.estados.find(
        (estado) => estado.sigla === selectedEstado.value
      );
      const cidades = estado ? estado.cidades : [];
      const muns = [];
      cidades.map((item) => {
        muns.push({
          value: item,
          label: item,
        });
      });
      setMunicipios(muns);
    } else if (userType === "wbgi") {
      const estado = estadosWBGI.estados.find(
        (estado) => estado.sigla === selectedEstado.value
      );
      const cidades = estado ? estado.cidades : [];
      const muns = [];
      cidades.map((item) => {
        muns.push({
          value: item,
          label: item,
        });
      });
      setMunicipios(muns);
    } else if (userType === "tenda") {
      const estado = estadosTenda.estados.find(
        (estado) => estado.sigla === selectedEstado.value
      );
      const cidades = estado ? estado.cidades : [];
      const muns = [];
      cidades.map((item) => {
        muns.push({
          value: item,
          label: item,
        });
      });
      setMunicipios(muns);
    } else if (userType === "gb") {
      const estado = estadosGB.estados.find(
        (estado) => estado.sigla === selectedEstado.value
      );
      const cidades = estado ? estado.cidades : [];
      const muns = [];
      cidades.map((item) => {
        muns.push({
          value: item,
          label: item,
        });
      });
      setMunicipios(muns);
    } else if (userType === "riva") {
      const estado = estadosRiva.estados.find(
        (estado) => estado.sigla === selectedEstado.value
      );
      const cidades = estado ? estado.cidades : [];
      const muns = [];
      cidades.map((item) => {
        muns.push({
          value: item,
          label: item,
        });
      });
      setMunicipios(muns);
    } else if (userType === "tenda2") {
      const estado = estadosTenda2.estados.find(
        (estado) => estado.sigla === selectedEstado.value
      );
      const cidades = estado ? estado.cidades : [];
      const muns = [];
      cidades.map((item) => {
        muns.push({
          value: item,
          label: item,
        });
      });
      setMunicipios(muns);
    } else if (userType === "aureo") {
      const estado = estadosAureo.estados.find(
        (estado) => estado.sigla === selectedEstado.value
      );
      const cidades = estado ? estado.cidades : [];
      const muns = [];
      cidades.map((item) => {
        muns.push({
          value: item,
          label: item,
        });
      });
      setMunicipios(muns);
    } else if (userType === "acc3") {
      const estado = estadosAccenture3.estados.find(
        (estado) => estado.sigla === selectedEstado.value
      );
      const cidades = estado ? estado.cidades : [];
      const muns = [];
      cidades.map((item) => {
        muns.push({
          value: item,
          label: item,
        });
      });
      setMunicipios(muns);
    }
  };

  const handleSelectChangeMun = (selectedMun) => {
    if (!selectedMun) {
      setSelectedMun(null);
      return null; // Retorna null para não exibir nada
    }

    setSelectedMun(selectedMun);
    municipioChange(selectedMun);

    setCheckboxes({
      limite: false,
      zoneamento: false,
      topografia: false,
      hidrografia: false,
      limitesa: false,
      permissao: false,
      dados: false,
      fluxo: false,
      terrenos: false,

      thej: false,
      competidorestj: false,
      areastj: false,
      condominiostj: false,
      influenciatj: false,
      pontosfluxotj: false,
      imoveis: false,

      c1: false,
      c2: false,
      c3: false,
      c4: false,
      c5: false,
      c6: false,
      c7: false,
      c8: false,
      c9: false,
      c10: false,
      c11: false,
      c12: false,
      c13: false,
      c14: false,
      c15: false,
      c16: false,

      setoreslk: false,
      raio1000lk: false,
      raio500lk: false,
      viaslk: false,
      competidoreslk: false,
      hospitaislk: false,
      pracaslk: false,
      mercadoslk: false,
      restauranteslk: false,
      condominioslk: false,
      farmacialk: false,
      imoveis200: false,

      areaverdegl: false,
      municipiogl: false,
      condominiogooglegl: false,
      condominiograpgl: false,
      rendagl: false,

      //alea campinas
      servidao: false,
      crescimento: false,

      //tj paulinia
      areasinfluenciatjp: false,
      areasindicadastjp: false,
      compdiretostjp: false,
      compindiretostjp: false,
      condominiostjp: false,
      imoveistjp: false,
      limitetjp: false,
      pontosfluxotjp: false,
      escolastjp: false,

      //tj americana

      areasinfluenciatja: false,
      areasindicadasinfantiltja: false,
      areasindicadasfundamentaltja: false,
      compdiretosinfantiltja: false,
      compdiretosfundamentaltja: false,
      compindiretosinfantiltja: false,
      compindiretosfundamentaltja: false,
      concentracao0_5tja: false,
      concentracao6_10tja: false,
      escolatja: false,
      limitetja: false,
      pontosfluxotja: false,
      rendatja: false,
      rendaareainfantiltja: false,
      rendaareafundatja: false,

      //hids
      ashidsgu: false,
      aspidsgu: false,
      apidsgu: false,
      bempresasgu: false,
      campusdagu: false,
      campusgu: false,
      fzdarggu: false,
      ferroviasgu: false,
      ictsgu: false,
      parquesgu: false,
      riosgu: false,
      viasgu: false,

      //tjg
      areasindicadasinfantiltjg: false,
      areasindicadasfundamentaltjg: false,
      compdiretosinfantiltjg: false,
      compdiretosfundamentaltjg: false,
      compindiretosinfantiltjg: false,
      compindiretosfundamentaltjg: false,
      concentracao0_5tjg: false,
      concentracao6_10tjg: false,
      familiastjg: false,
      rendatjg: false,
      imovelpretendidotjg: false,
      limitetjg: false,
      pontosfluxotjg: false,
      raioinfluenciatjg: false,

      // edu
      edumap: false,

      //crca
      bairroscrca: false,
      dadossociosfamiliascampinascrca: false,
      dadossociosrendacampinascrca: false,
      zoneamentocampinascrca: false,
      limitecampinascrca: false,
      dadosporbairro: false,

      //sjbv
      sjbv: false,
      sjbv1zoneamento: false,
      sjbv2permissao: false,
      sjbv3terrenos: false,
      sjbv5topografia: false,
      sjbv6limitesa: false,
      sjbv7hidrografia: false,
      sjbv8servidao: false,
      sjbv9terrenosp: false,
      sjbv10crescimento: false,
      sjbv11dadossocios: false,
      sjbv12distviario: false,
      sjbv13malha: false,
      sjbv14pontos: false,

      //miada
      rp: false,
      rp1zoneamento: false,
      rp3terrenos: false,
      rp5topografia: false,
      rp6limitesambientais: false,
      rp7hidrografia: false,
      rp8servidao: false,
      rp10crescimento: false,
      rp11dadossocioeconomicosrenda: false,
      rp12dadossocioeconomicosfamilias: false,

      //crca2
      abrangenciacrascrca2: false,
      assistenciateccrca2: false,
      bairroscrca2: false,
      campinasdadossociosrendacrca2: false,
      campinasdadossociosfamiliascrca2: false,
      campinaslimitecrca2: false,
      campinaszoneamentocrca2: false,
      comercioalimentoscrca2: false,
      dadosporbairrocrca2: false,
      impressao3dcrca2: false,
      lojaderoupascrca2: false,
      lojasartesanatocrca2: false,
      oficinasmecanicascrca2: false,
      orgcivilcrca2: false,
      padariasconfeitariascrca2: false,
      petshopscrca2: false,
      regioesvulcrca2: false,
      salaobelezacrc2: false,

      //edu
      educomp: false,
      edufamilia: false,
      edurenda: false,
      eduimoveis: false,

      // tjd
      areasindicadasinfantiltjd: false,
      areasindicadasfundamentaltjd: false,
      compdiretosinfantiltjd: false,
      compdiretosfundamentaltjd: false,
      compindiretosinfantiltjd: false,
      compindiretosfundamentaltjd: false,
      concentracaocriancasinfantiltgd: false,
      concentracaocriancasfundtgd: false,
      familiastjd: false,
      rendatjd: false,
      limitetjd: false,
      pontosfluxotjd: false,
      raioinfluenciatjd: false,

      //lavras
      legalizacaolavras: false,

      //manapoke
      academiasmanapoke: false,
      centrosempresariasmanapoke: false,
      centrosesportivosmanapoke: false,
      comerciomanapoke: false,
      compcomidajaponesamanapoke: false,
      compcomidasaudavelmanapoke: false,
      imoveiscomerciaismanapoke: false,
      pontosculturaismanapoke: false,
      pontoseducacaomanapoke: false,
      rendamanapoke: false,
      areaspotenciaismanapoke: false,

      //lavras
      zoneamentolavras: false,
      permissaolavras: false,
      terrenoslavras: false,
      topografialavras: false,
      applavras: false,
      hidrografialavras: false,
      servidaolavras: false,
      terrenosinteresselavras: false,
      crescimentolavras: false,
      dadossocioslavras: false,
      distviariolavras: false,
      disturbanalavras: false,
      pontoslavras: false,

      //buddahspa
      clubesbs: false,
      familiasbs: false,
      lojasdepartamentobs: false,
      parquesbs: false,
      pontosbuddhaspabs: false,
      rendabs: false,
      restaurantesbs: false,
      shoppingsbs: false,
      supermercadosbs: false,
      estacionamentosbs: false,
      imoveismenos18bs: false,
      imoveismais18bs: false,
      zonasusobs: false,

      areainflucompbs: false,
      areapotcomrecorte: false,
      areapotsemrecorte: false,
      competidoresbs: false,
      popadultabs: false,

      //baldoni
      areaspotbd: false,
      baldonibd: false,
      baldoni2030bd: false,
      lojaspescabd: false,
      pesquesbd: false,
      petshopsbd: false,
      popadultabd: false,
      rendabd: false,
      restaurantesbd: false,
      vetsbd: false,

      //caribu
      academiascb: false,
      areaspotcb: false,
      cafescb: false,
      comerciocb: false,
      culturalcb: false,
      escolascb: false,
      esportescb: false,
      faculdadescb: false,
      imoveiscb: false,
      lanchonetescb: false,
      rendasetorescb: false,
      comidasaudavelcb: false,

      //rioverde
      Rio_Verde_1_zoneamento: false,
      Rio_Verde_2_permissao: false,
      Rio_Verde_3_terrenos: false,
      Rio_Verde_4_imoveis_disponiveis: false,
      Rio_Verde_5_topografia: false,
      Rio_Verde_6_Limites_Ambientais: false,
      Rio_Verde_7_Hidrografia: false,
      Rio_Verde_8_Servidao: false,
      Rio_Verde_9_terrenos_potenciais: false,
      Rio_Verde_10_crescimento: false,
      Rio_Verde_11_dados_socioeconomicos: false,
      Rio_verde_12_dist_viario: false,
      Rio_Verde_13_Malha_Urbana: false,
      Rio_Verde_14_Pontos_de_Interesse: false,
      Rio_Verde: false,

      //tony
      Cruzeiro_1_Zoneamento: false,
      Guaxupe_1_zoneamento: false,
      Lavras_zoneamento_TESTE: false,
      SSParaiso_1_Zoneamento: false,

      //accenture
      angradosreisacc: false,
      br101km414acc: false,
      br101km447acc: false,
      br101km538acc: false,
      itaguaiacc: false,
      mangaratibaacc: false,
      paratyacc: false,
      trechobr101acc: false,

      rendaacc: false,
      areasconmataatlaacc: false,
      appacc: false,
      vegetacaonativaacc: false,
      uniconsfederaisacc: false,
      reservalegalacc: false,
      areasprotacc: false,

      //caribu
      acaiteriacb: false,
      sorveteriacb: false,

      //accenture
      moteisac: false,
      pedagiosac: false,
      postosac: false,
      restaurantesac: false,
      hoteisac: false,
      pousadasac: false,

      //rsg
      areaspotpucrsg: false,
      areaspotunicampport2rsg: false,
      areaspotunicampprincrsg: false,
      portariasrsg: false,
      raio200rsg: false,
      terrenosdisprsg: false,

      //caribu
      fluxokernelcb: false,
      perimetrourbanocb: false,
      concentrafluxocb: false,
      raiofluxocb: false,
      fluxoconcocb: false,

      //peconi
      zoneamentopec: false,
      pontosintpec: false,
      permissaopec: false,
      topografiapec: false,
      crescimentoanopec: false,
      graprohab: false,
      densidadepec: false,
      familiaspec: false,
      rendapec: false,
      corpospec: false,
      cursospec: false,
      nascentespec: false,
      apppec: false,
      reservapec: false,
      vegpec: false,
      terrenos2k4kpec: false,
      terrenos5kmaispec: false,
      terrenosnaopec: false,
      terrenospot5kmaispec: false,
      terrenospot150kmaispec: false,
      mapaconcentracaopec: false,

      //voto map
      limitevmap: false,
      popfemvmap: false,
      popmasvmap: false,
      poptotvmap: false,
      prefeitovmap: false,
      vereadorvmap: false,
      rendavmap: false,
      prefeitovmap2: false,
      vereadorvmap2: false,

      // unicamp
      demandaespacopu: false,
      demandaqualidadepu: false,
      faixafaturamentopu: false,
      leispu: false,
      mapageralpu: false,
      modelopu: false,
      preferenpu: false,
      necessidadepu: false,
      relacaopu: false,
      setorpu: false,

      //peconi
      divisaoregpec: false,
      vaziospec: false,
      usosolopec: false,
      concenfluxospec: false,
      topografiapec2: false,

      faixaac: false,

      areaintbb: false,
      boibrabobb: false,
      classesbb: false,
      compsbb: false,
      conccompsbb: false,
      concpfbb: false,
      familiasbb: false,
      fluxocompsbb: false,
      imoveisbb: false,
      pontosfluxobb: false,
      rendabb: false,
      metrobb: false,
      onibusbb: false,
      trembb: false,
      areapotbb: false,

      areapotdp: false,
      compsdp: false,
      conccompsdp: false,
      concpontofluxodp: false,
      familiasdp: false,
      gastodp: false,
      pontofluxodp: false,
      pop20a69dp: false,
      rendadp: false,

      armazensacc2: false,
      balsasacc2: false,
      concarmazensacc2: false,
      formosoacc2: false,
      lagoaacc2: false,
      miranorteacc2: false,
      portoacc2: false,
      silvanopolisacc2: false,
      tassoacc2: false,
      usosoloacc2: false,
      hidroviasgeralacc2: false,
      viasgeralacc2: false,
      portsaoluisacc2: false,
      portnacionalacc2: false,
      intpalemeiranteacc2: false,
      rodoviasacc2: false,
      instalacoesportacc2: false,
      hidroviasofcacc2: false,
      ferroviasacc2: false,
      usosolomiranorteacc2: false,
      usosolosilvanopolisacc2: false,
      usosololagoaacc2: false,
      usosoloformosoacc2: false,
      usosoloportoacc2: false,
      usosolobalsasacc2: false,
      usosolotassoacc2: false,

      lmsaltosc: false,
      compsaltosc: false,
      r1saltosc: false,
      r2saltosc: false,
      pfsaltosc: false,
      scsaltosc: false,
      lmmarasc: false,
      compmarasc: false,
      buffermarasc: false,
      pfmarasc: false,
      scmarasc: false,
      lmjacaresc: false,
      compjacaresc: false,
      r1jacaresc: false,
      pfjacaresc: false,
      scjacaresc: false,

      terrenospec: false,
      terrenospecpau: false,

      amoarmazensacc2: false,
      prestaacc2: false,
      habacc2: false,
      capacc2: false,
      tipoacc2: false,
      porteacc2: false,
      formacc2: false,
      distferroacc2: false,
      distermacc2: false,
      estosoacc2: false,
      precosoacc2: false,
      popacc2: false,
      rendaacc2: false,
      aeroacc2: false,
      declacc2: false,

      dadossociosaltossc: false,
      fluxo1marajasc: false,
      kernelfluxo1marajasc: false,
      familiasmarajassc: false,
      rendamarajassc: false,
      dadossociojacaresc: false,

      crescimentobrz: false,
      domiciliosaluguelbrz: false,
      domicioliosbrz: false,
      empreendimentosbrz: false,
      lavrasbrz: false,
      popbrz: false,
      raio15brz: false,
      rendabrz: false,
      vetorbrz: false,

      prop1toacc2: false,
      prop2toacc2: false,
      prop1maacc2: false,
      prop2maacc2: false,

      pontointbrz: false,

      crescimento1brz: false,
      deficitbrz: false,
      taxahabbrz: false,

      gtdcamacircu: false,
      distcamacircu: false,
      arearedcamacircu: false,
      areaspotcamacircu: false,
      assocamacircu: false,
      camacircu: false,
      empscamacircu: false,
      concempcamacircu: false,
      areaspotvargcircu: false,
      assovargcircu: false,
      vargcircu: false,
      empsvargcircu: false,
      gtdcampcircu: false,
      distcampcircu: false,
      distecocampcircu: false,
      arearedcampcircu: false,
      areaspotcampcircu: false,
      assocampcircu: false,
      campcircu: false,
      empscampcircu: false,
      densempscampcircu: false,
      ecoscampcircu: false,
      gtdjundcircu: false,
      distjundcircu: false,
      distecojundcircu: false,
      arearedjundcircu: false,
      areaspotjundcircu: false,
      assojundcircu: false,
      jundcircu: false,
      empsjundcircu: false,
      densempsjundcircu: false,
      ecosjundcircu: false,
      gtdsorocircu: false,
      distsorocircu: false,
      distecosorocircu: false,
      arearedsorocircu: false,
      areaspotsorocircu: false,
      assosorocircu: false,
      sorocircu: false,
      empssorocircu: false,
      densempssorocircu: false,
      ecossorocircu: false,

      terrenoslivre: false,

      areapotafasdp: false,
      imoveisdp: false,

      jundiaimrv: false,
      appjundiaimrv: false,
      vegjundiaimrv: false,
      resjundiaimrv: false,
      areaprojundiaimrv: false,
      nascjundiaimrv: false,
      cursjundiaimrv: false,
      corposjundiaimrv: false,
      topojundiaimrv: false,
      urbjundiaimrv: false,
      areapoujundiaimrv: false,
      servjundiaimrv: false,
      usorestjundiaimrv: false,
      areaconsjundiaimrv: false,
      areacontjundiaimrv: false,
      dutojundiaimrv: false,
      linhaojundiaimrv: false,
      zoneajundiaimrv: false,
      legavertjundiaimrv: false,
      legahorjundiaimrv: false,
      rendapopjundiaimrv: false,
      rendajundiaimrv: false,
      popjundiaimrv: false,
      domicjundiaimrv: false,
      domicalujundiaimrv: false,
      expurbjundiaimrv: false,
      taxahabjundiaimrv: false,
      graprohabjundiaimrv: false,
      areaurbjundiaimrv: false,
      arearujundiaimrv: false,
      vaziosjundiaimrv: false,
      consumoaguajundiaimrv: false,
      dispaguajundiaimrv: false,
      custojundiaimrv: false,
      terrenosjundiaimrv: false,
      tljundiaimrv: false,
      pontoajundiaimrv: false,
      pontodjundiaimrv: false,

      limeiramrv: false,
      applimeiramrv: false,
      veglimeiramrv: false,
      reslimeiramrv: false,
      areaprolimeiramrv: false,
      nasclimeiramrv: false,
      curslimeiramrv: false,
      corposlimeiramrv: false,
      topolimeiramrv: false,
      urblimeiramrv: false,
      areapoulimeiramrv: false,
      servlimeiramrv: false,
      usorestlimeiramrv: false,
      areaconslimeiramrv: false,
      areacontlimeiramrv: false,
      dutolimeiramrv: false,
      linhaolimeiramrv: false,
      zonealimeiramrv: false,
      legavertlimeiramrv: false,
      legahorlimeiramrv: false,
      rendapoplimeiramrv: false,
      rendalimeiramrv: false,
      poplimeiramrv: false,
      domiclimeiramrv: false,
      domicalulimeiramrv: false,
      expurblimeiramrv: false,
      taxahablimeiramrv: false,
      graprohablimeiramrv: false,
      areaurblimeiramrv: false,
      arearulimeiramrv: false,
      vazioslimeiramrv: false,
      consumoagualimeiramrv: false,
      dispagualimeiramrv: false,
      custolimeiramrv: false,
      terrenoslimeiramrv: false,
      tllimeiramrv: false,
      pontoalimeiramrv: false,
      pontodlimeiramrv: false,

      veloprecojundiaimrv: false,
      veloprecolimeiramrv: false,

      //gurg
      bdgu: false,

      boticampcircu: false,
      botijundcircu: false,
      botisorocircu: false,
      botigrandespcircu: false,
      grandespcircu: false,

      imoveisc1ic: false,
      raioc1ic: false,
      areaestudoc2ic: false,
      rendac2ic: false,
      recortec2ic: false,
      pontosfluxc2ic: false,
      concfluxoc2ic: false,
      oticasc2ic: false,
      concfluxooticasc2ic: false,
      imoveisc2ic: false,
      shoppingsc2ic: false,

      kernelsaltosc: false,
      kerneljacaresc: false,
      ponibusmarasc: false,
      lonibusmarasc: false,

      redesdrenalimeiramrv: false,
      recargahidricalimeiramrv: false,
      ferroviaslimeiramrv: false,
      viaslimeiramrv: false,
      viasligalimeiramrv: false,
      rankingterlimeiramrv: false,
      redesdrenajundiaimrv: false,
      recargahidricajundiaimrv: false,
      viasjundiaimrv: false,
      viasligajundiaimrv: false,
      ferroviasjundiaimrv: false,
      equipamentosjundiaimrv: false,
      rankingterjundiaimrv: false,
      poluicaosonorajundiaimrv: false,

      acadmelten: false,
      areasafastmelten: false,
      areasproxmelten: false,
      comerciosmelten: false,
      concconcmelten: false,
      concmelten: false,
      crescurbanomelten: false,
      domiciliosmelten: false,
      equipublimelten: false,
      escolasprivadasmelten: false,
      hidroviasmelten: false,
      hospitaismelten: false,
      imoveismelten: false,
      infraviariamelten: false,
      melten: false,
      palmasmelten: false,
      popmelten: false,
      rendamelten: false,
      restaurantesmelten: false,
      transportemelten: false,
      viasmelten: false,

      gtdipocircu: false,
      arearedipocircu: false,
      areaspotipocircu: false,
      coopreciipocircu: false,
      ipocircu: false,
      empsipocircu: false,
      densempsipocircu: false,
      ecosipocircu: false,

      correcondcamacircu: false,
      correcondvargcircu: false,
      correcondcampcircu: false,
      correcondjundcircu: false,
      correcondsorocircu: false,

      regionalizacaomelten: false,

      crescpecpau: false,
      dadossociospecpau: false,
      hidropecpau: false,
      limitesambpecpau: false,
      pontosfluxopecpau: false,
      raios500pecpau: false,
      servidaopecpau: false,
      terrenospotpecpau: false,
      terrenosranqpecpau: false,
      topopecpau: false,
      vaziospecpau: false,

      playersagrovh: false,
      campinasvh: false,
      parquesteccampvh: false,

      demandagu: false,
      ativpesquisagu: false,
      interessehubgu: false,
      modelotrabalhogu: false,
      capitalpropriogu: false,
      fundestaduaisgu: false,
      sebraegu: false,
      bndesgu: false,
      investidoranjogu: false,
      fundosinvestgu: false,
      aceleradorasgu: false,
      semrespostagu: false,

      ictsvh: false,
      ictsagrovh: false,

      areaspreservavespemcp: false,
      vegnativavespemcp: false,
      reservalegalvespemcp: false,
      nascentesvespemcp: false,
      cursosaguavespemcp: false,
      topografiavespemcp: false,
      limitevespemcp: false,
      zoneamentovespemcp: false,
      usovertvespemcp: false,
      permissaovespemcp: false,
      locterrenovespemcp: false,
      servidaovespemcp: false,
      areaconsovespemcp: false,
      areascontavespemcp: false,
      dutoviasvespemcp: false,
      linhaovespemcp: false,
      novoscondvespemcp: false,
      rendavespemcp: false,
      popvespemcp: false,
      domicivespemcp: false,
      domicialuvespemcp: false,
      expurbanavespemcp: false,
      veloeprecovespemcp: false,
      taxahabvespemcp: false,
      concorrenciavespemcp: false,
      areaurbavespemcp: false,
      areasruraisvespemcp: false,
      consuaguavespemcp: false,
      dispaguavespemcp: false,
      custoprojetovespemcp: false,
      terrenosanunvespemcp: false,
      imoveisanunvespemcp: false,
      pontosatravespemcp: false,
      pontosdetravespemcp: false,

      tjchpdtjd: false,
      compdiretoschpdtjd: false,
      criancas05chpdtjd: false,
      escolasidiomaschpdtjd: false,
      escolasinfantischpdtjd: false,
      escolaschpdtjd: false,
      imovelindicadochpdtjd: false,
      pontosfluxochpdtjd: false,
      principaisviaschpdtjd: false,
      raio1kmcompchpdtjd: false,
      raio2kmcompchpdtjd: false,
      rendafamiliarchpdtjd: false,

      areainflurctjd: false,
      areasindirctjd: false,
      competidoresrctjd: false,
      condominiosrctjd: false,
      imoveisrctjd: false,
      pontosfluxirctjd: false,

      compdiretosvptjd: false,
      imoveissapopembavptjd: false,
      imoveisvpvptjd: false,
      pontosfluxovptjd: false,
      rendafamiliarvptjd: false,
      tjschoolvptjd: false,

      appwbgi: false,
      areacontwbgi: false,
      curvasnivelwbgi: false,
      declividadewbgi: false,
      distcorposaguawbgi: false,
      distcorposhidricoswbgi: false,
      hidrografiawbgi: false,
      limeirawbgi: false,
      reservalegalwbgi: false,
      vegnativawbgi: false,
      viveiristaswbgi: false,

      limiteosascotnd: false,
      areapreserosascotnd: false,
      vegnaivaosascotnd: false,
      reservaosascotnd: false,
      nascentesosascotnd: false,
      cursososascotnd: false,
      corpososascotnd: false,
      topografiaosascotnd: false,
      zoneamentoosascotnd: false,
      usovertosascotnd: false,
      implantacaohishmposascotnd: false,
      servadmosascotnd: false,
      areaconsoosascotnd: false,
      areascontaosascotnd: false,
      dutoviasosascotnd: false,
      linhaoosascotnd: false,
      pontosatratosascotnd: false,
      pontosdetratosascotnd: false,
      rendaosascotnd: false,
      domiciliososascotnd: false,
      domiciliosaluguelosascotnd: false,
      expcidadesosascotnd: false,
      precomedioosascotnd: false,
      taxahabosascotnd: false,
      concoosascotnd: false,
      areaurbanizadaosascotnd: false,
      areasruraisosascotnd: false,
      vaziosurbanososascotnd: false,
      grapohabosascotnd: false,
      terrenososascotnd: false,
      imoveisosascotnd: false,
      tendenciassetorosascotnd: false,
      sinaismudancaosascotnd: false,
      oportunidadeosascotnd: false,
      areausorestosascotnd: false,

      areapotlimeiramrv: false,

      areapreserslgb: false,
      vegnativaslgb: false,
      reservaslgb: false,
      nascentesslgb: false,
      hidrografiaslgb: false,
      topografiaslgb: false,
      zoneamentoslgb: false,
      usovertslgb: false,
      usohorslgb: false,
      viasslgb: false,
      dutoviasslgb: false,
      linhaoslgb: false,
      pontosatratosslgb: false,
      pontosdetratslgb: false,
      rendaslgb: false,
      domiciliosslgb: false,
      concoslgb: false,
      areasruraisslgb: false,
      terrenosslgb: false,
      imoveisslgb: false,

      raiopesquisaslgb: false,
      terrenosanaslgb: false,

      limitestatnd: false,
      areapreserstatnd: false,
      vegnaivastatnd: false,
      reservastatnd: false,
      nascentesstatnd: false,
      hidrocarstatnd: false,
      hidrocompstatnd: false,
      topografiastatnd: false,
      zoneamentostatnd: false,
      usovertstatnd: false,
      implantacaohishmpstatnd: false,
      servadmstatnd: false,
      areausoreststatnd: false,
      areaconsostatnd: false,
      areascontastatnd: false,
      dutoviasstatnd: false,
      linhaostatnd: false,
      rededrenagemstatnd: false,
      pontosatratstatnd: false,
      pontosdetratstatnd: false,
      domiciliosstatnd: false,
      rendastatnd: false,
      domiciliosaluguelstatnd: false,
      expcidadesstatnd: false,
      expverticalstatnd: false,
      precomediostatnd: false,
      taxahabstatnd: false,
      areaurbanizadastatnd: false,
      areasruraisstatnd: false,
      vaziosurbanosstatnd: false,
      grapohabstatnd: false,
      terrenosstatnd: false,
      imoveisstatnd: false,

      limiteriva: false,
      recorteriva: false,
      areapreserriva: false,
      conservacaoriva: false,
      cursosriva: false,
      nascentesriva: false,
      areasriscoriva: false,
      areasriscocontriva: false,
      topografiariva: false,
      zoneamentoriva: false,
      usovertriva: false,
      aeroportosriva: false,
      dutoviasriva: false,
      linhaoriva: false,
      protculturalriva: false,
      bemcultriva: false,
      bemcultimovelriva: false,
      bemcultnaturalriva: false,
      diretrizriva: false,
      tombamentoriva: false,
      pontosatratriva: false,
      pontosdetratriva: false,
      ocupacaoriva: false,
      rendariva: false,
      pmedioriva: false,
      taxahabriva: false,
      concoriva: false,
      vaziosriva: false,
      imoveisanunriva: false,

      limitemacapaic: false,
      recortemacapaic: false,
      varejomacapaic: false,
      concvarejomacapaic: false,
      concfluxomacapaic: false,
      shoppingsmacapaic: false,
      escolasmacapaic: false,
      hospitaismacapaic: false,
      estacionamentosmacapaic: false,
      concorrentesmacapaic: false,
      universidadesmacapaic: false,
      assaimacapaic: false,
      rendamacapaic: false,
      domiciliosmacapaic: false,
      popmacapaic: false,
      imoveismacapaic: false,

      catimoveisriva: false,

      domiciliosaureo: false,
      empresasaureo: false,
      locinteresseaureo: false,
      marcasaureo: false,
      pontosaureo: false,
      raio1kmaureo: false,
      raio2kmaureo: false,
      rendaaureo: false,
      viasaureo: false,
      zoneamentoaureo: false,

      piscininha: false,
      piscinao: false,

      imoveisvendidosemcp: false,
      outrossetoresemcp: false,
      setorescomimoveisemcp: false,
      vendasemcp: false,

      comerciosaureopet1: false,
      domiciliosaureopet1: false,
      empreendimentoaureopet1: false,
      limiteaureopet1: false,
      localizacaoaureopet1: false,
      marcasvareaureopet1: false,
      r1kmaureopet1: false,
      r2kmaureopet1: false,
      rendaaureopet1: false,
      viasacessoaureopet1: false,
      comerciosaureopet2: false,
      domiciliosaureopet2: false,
      empreendimentoaureopet2: false,
      localizacaoaureopet2: false,
      r1kmaureopet2: false,
      r2kmaureopet2: false,
      rendaaureopet2: false,
      viasacessoaureopet2: false,
      zoneamentoaureopet2: false,

      hidrologiaaureopet1: false,
topografiaaureopet1: false,
hidrologiaaureopet2: false,
topografiaaureopet2: false,

topografiaacc3: false,
zoneamentoacc3: false,
areapousoacc3: false,
rotaaviaoacc3: false,
areascontacc3: false,
dutoviasacc3: false,
estabelecimentosacc3: false,
veiculosacc3: false,
fluxoveicacc3: false,
movimentacaoacc3: false,
rendaacc3: false,
populacaoacc3: false,
domiciliosacc3: false,
domialuguelacc3: false,
areaurbaacc3: false,

spacc3: false,
guarulhosacc3: false,
trechoacc3: false,

aeror2soroaureo: false,
shoppingr2soroaureo: false,
condor2soroaureo: false,
domicilior3soroaureo: false,
popr3soroaureo: false,
varejor3soroaureo: false,
industriar3soroaureo: false,
domicilior4soroaureo: false,
popr4soroaureo: false,
varejor4soroaureo: false,
industriar4soroaureo: false,
domicilior5soroaureo: false,
popr5soroaureo: false,
varejor5soroaureo: false,
industriar5soroaureo: false,
sud1petrop1aureo: false,
sud2petrop1aureo: false,
sud1petrop2aureo: false,
sud2petrop2aureo: false,
    });
  };

  const [checkboxes, setCheckboxes] = useState({
    limite: false,
    zoneamento: false,
    topografia: false,
    hidrografia: false,
    limitesa: false,
    permissao: false,
    dados: false,
    fluxo: false,
    terrenos: false,
    thej: false,
    competidorestj: false,
    areastj: false,
    condominiostj: false,
    influenciatj: false,
    pontosfluxotj: false,
    imoveis: false,

    c1: false,
    c2: false,
    c3: false,
    c4: false,
    c5: false,
    c6: false,
    c7: false,
    c8: false,
    c9: false,
    c10: false,
    c11: false,
    c12: false,
    c13: false,
    c14: false,
    c15: false,
    c16: false,

    setoreslk: false,
    raio1000lk: false,
    raio500lk: false,
    viaslk: false,
    competidoreslk: false,
    hospitaislk: false,
    pracaslk: false,
    mercadoslk: false,
    restauranteslk: false,
    condominioslk: false,
    farmacialk: false,
    imoveis200: false,

    areaverdegl: false,
    municipiogl: false,
    condominiogooglegl: false,
    condominiograpgl: false,
    rendagl: false,

    //alea
    servidao: false,
    crescimento: false,

    //tj paulinia
    areasinfluenciatjp: false,
    areasindicadastjp: false,
    compdiretostjp: false,
    compindiretostjp: false,
    condominiostjp: false,
    imoveistjp: false,
    limitetjp: false,
    pontosfluxotjp: false,
    escolastjp: false,

    //tj americana
    areasinfluenciatja: false,
    areasindicadasinfantiltja: false,
    areasindicadasfundamentaltja: false,
    compdiretosinfantiltja: false,
    compdiretosfundamentaltja: false,
    compindiretosinfantiltja: false,
    compindiretosfundamentaltja: false,
    concentracao0_5tja: false,
    concentracao6_10tja: false,
    escolatja: false,
    limitetja: false,
    pontosfluxotja: false,
    rendatja: false,
    rendaareainfantiltja: false,
    rendaareafundatja: false,

    //hids
    ashidsgu: false,
    aspidsgu: false,
    apidsgu: false,
    bempresasgu: false,
    campusdagu: false,
    campusgu: false,
    fzdarggu: false,
    ferroviasgu: false,
    ictsgu: false,
    parquesgu: false,
    riosgu: false,
    viasgu: false,

    //tjg
    areasindicadasinfantiltjg: false,
    areasindicadasfundamentaltjg: false,
    compdiretosinfantiltjg: false,
    compdiretosfundamentaltjg: false,
    compindiretosinfantiltjg: false,
    compindiretosfundamentaltjg: false,
    concentracao0_5tjg: false,
    concentracao6_10tjg: false,
    familiastjg: false,
    rendatjg: false,
    imovelpretendidotjg: false,
    limitetjg: false,
    pontosfluxotjg: false,
    raioinfluenciatjg: false,

    //edu

    edumap: false,

    //crca

    bairroscrca: false,
    dadossociosfamiliascampinascrca: false,
    dadossociosrendacampinascrca: false,
    zoneamentocampinascrca: false,
    limitecampinascrca: false,
    dadosporbairro: false,

    //sjbv
    sjbv: false,
    sjbv1zoneamento: false,
    sjbv2permissao: false,
    sjbv3terrenos: false,
    sjbv5topografia: false,
    sjbv6limitesa: false,
    sjbv7hidrografia: false,
    sjbv8servidao: false,
    sjbv9terrenosp: false,
    sjbv10crescimento: false,
    sjbv11dadossocios: false,
    sjbv12distviario: false,
    sjbv13malha: false,
    sjbv14pontos: false,

    //miada
    rp: false,
    rp1zoneamento: false,
    rp3terrenos: false,
    rp5topografia: false,
    rp6limitesambientais: false,
    rp7hidrografia: false,
    rp8servidao: false,
    rp10crescimento: false,
    rp11dadossocioeconomicosrenda: false,
    rp12dadossocioeconomicosfamilias: false,

    //crca2
    abrangenciacrascrca2: false,
    assistenciateccrca2: false,
    bairroscrca2: false,
    campinasdadossociosrendacrca2: false,
    campinasdadossociosfamiliascrca2: false,
    campinaslimitecrca2: false,
    campinaszoneamentocrca2: false,
    comercioalimentoscrca2: false,
    dadosporbairrocrca2: false,
    impressao3dcrca2: false,
    lojaderoupascrca2: false,
    lojasartesanatocrca2: false,
    oficinasmecanicascrca2: false,
    orgcivilcrca2: false,
    padariasconfeitariascrca2: false,
    petshopscrca2: false,
    regioesvulcrca2: false,
    salaobelezacrc2: false,

    educomp: false,
    edufamilia: false,
    edurenda: false,
    eduimoveis: false,

    //tjd
    areasindicadasinfantiltjd: false,
    areasindicadasfundamentaltjd: false,
    compdiretosinfantiltjd: false,
    compdiretosfundamentaltjd: false,
    compindiretosinfantiltjd: false,
    compindiretosfundamentaltjd: false,
    concentracaocriancasinfantiltgd: false,
    concentracaocriancasfundtgd: false,
    familiastjd: false,
    rendatjd: false,
    limitetjd: false,
    pontosfluxotjd: false,
    raioinfluenciatjd: false,

    //lavras
    legalizacaolavras: false,

    //manapoke
    academiasmanapoke: false,
    centrosempresariasmanapoke: false,
    centrosesportivosmanapoke: false,
    comerciomanapoke: false,
    compcomidajaponesamanapoke: false,
    compcomidasaudavelmanapoke: false,
    imoveiscomerciaismanapoke: false,
    pontosculturaismanapoke: false,
    pontoseducacaomanapoke: false,
    rendamanapoke: false,
    areaspotenciaismanapoke: false,

    //lavras
    zoneamentolavras: false,
    permissaolavras: false,
    terrenoslavras: false,
    topografialavras: false,
    applavras: false,
    hidrografialavras: false,
    servidaolavras: false,
    terrenosinteresselavras: false,
    crescimentolavras: false,
    dadossocioslavras: false,
    distviariolavras: false,
    disturbanalavras: false,
    pontoslavras: false,

    //buddahspa
    clubesbs: false,
    familiasbs: false,
    lojasdepartamentobs: false,
    parquesbs: false,
    pontosbuddhaspabs: false,
    rendabs: false,
    restaurantesbs: false,
    shoppingsbs: false,
    supermercadosbs: false,
    estacionamentosbs: false,
    imoveismenos18bs: false,
    imoveismais18bs: false,
    zonasusobs: false,

    areainflucompbs: false,
    areapotcomrecorte: false,
    areapotsemrecorte: false,
    competidoresbs: false,
    popadultabs: false,

    //baldoni
    areaspotbd: false,
    baldonibd: false,
    baldoni2030bd: false,
    lojaspescabd: false,
    pesquesbd: false,
    petshopsbd: false,
    popadultabd: false,
    rendabd: false,
    restaurantesbd: false,
    vetsbd: false,

    //caribu
    academiascb: false,
    areaspotcb: false,
    cafescb: false,
    comerciocb: false,
    culturalcb: false,
    escolascb: false,
    esportescb: false,
    faculdadescb: false,
    imoveiscb: false,
    lanchonetescb: false,
    rendasetorescb: false,
    comidasaudavelcb: false,

    //rioverde
    Rio_Verde_1_zoneamento: false,
    Rio_Verde_2_permissao: false,
    Rio_Verde_3_terrenos: false,
    Rio_Verde_4_imoveis_disponiveis: false,
    Rio_Verde_5_topografia: false,
    Rio_Verde_6_Limites_Ambientais: false,
    Rio_Verde_7_Hidrografia: false,
    Rio_Verde_8_Servidao: false,
    Rio_Verde_9_terrenos_potenciais: false,
    Rio_Verde_10_crescimento: false,
    Rio_Verde_11_dados_socioeconomicos: false,
    Rio_verde_12_dist_viario: false,
    Rio_Verde_13_Malha_Urbana: false,
    Rio_Verde_14_Pontos_de_Interesse: false,
    Rio_Verde: false,

    //tony
    Cruzeiro_1_Zoneamento: false,
    Guaxupe_1_zoneamento: false,
    Lavras_zoneamento_TESTE: false,
    SSParaiso_1_Zoneamento: false,

    //accenture
    angradosreisacc: false,
    br101km414acc: false,
    br101km447acc: false,
    br101km538acc: false,
    itaguaiacc: false,
    mangaratibaacc: false,
    paratyacc: false,
    trechobr101acc: false,

    rendaacc: false,
    areasconmataatlaacc: false,
    appacc: false,
    vegetacaonativaacc: false,
    uniconsfederaisacc: false,
    reservalegalacc: false,
    areasprotacc: false,

    //caribu
    acaiteriacb: false,
    sorveteriacb: false,

    //accenture
    moteisac: false,
    pedagiosac: false,
    postosac: false,
    restaurantesac: false,
    hoteisac: false,
    pousadasac: false,

    //rsg
    areaspotpucrsg: false,
    areaspotunicampport2rsg: false,
    areaspotunicampprincrsg: false,
    portariasrsg: false,
    raio200rsg: false,
    terrenosdisprsg: false,

    //caribu
    fluxokernelcb: false,
    perimetrourbanocb: false,

    concentrafluxocb: false,
    raiofluxocb: false,
    fluxoconcocb: false,

    //peconi
    zoneamentopec: false,
    pontosintpec: false,
    permissaopec: false,
    topografiapec: false,
    crescimentoanopec: false,
    graprohab: false,
    densidadepec: false,
    familiaspec: false,
    rendapec: false,
    corpospec: false,
    cursospec: false,
    nascentespec: false,
    apppec: false,
    reservapec: false,
    vegpec: false,
    terrenos2k4kpec: false,
    terrenos5kmaispec: false,
    terrenosnaopec: false,
    terrenospot5kmaispec: false,
    terrenospot150kmaispec: false,
    mapaconcentracaopec: false,

    //voto map
    limitevmap: false,
    popfemvmap: false,
    popmasvmap: false,
    poptotvmap: false,
    prefeitovmap: false,
    vereadorvmap: false,
    rendavmap: false,
    prefeitovmap2: false,
    vereadorvmap2: false,

    // unicamp
    demandaespacopu: false,
    demandaqualidadepu: false,
    faixafaturamentopu: false,
    leispu: false,
    mapageralpu: false,
    modelopu: false,
    preferenpu: false,
    necessidadepu: false,
    relacaopu: false,
    setorpu: false,

    //peconi
    divisaoregpec: false,
    vaziospec: false,
    usosolopec: false,
    concenfluxospec: false,
    topografiapec2: false,

    faixaac: false,

    areaintbb: false,
    boibrabobb: false,
    classesbb: false,
    compsbb: false,
    conccompsbb: false,
    concpfbb: false,
    familiasbb: false,
    fluxocompsbb: false,
    imoveisbb: false,
    pontosfluxobb: false,
    rendabb: false,
    metrobb: false,
    onibusbb: false,
    trembb: false,
    areapotbb: false,

    areapotdp: false,
    compsdp: false,
    conccompsdp: false,
    concpontofluxodp: false,
    familiasdp: false,
    gastodp: false,
    pontofluxodp: false,
    pop20a69dp: false,
    rendadp: false,

    armazensacc2: false,
    balsasacc2: false,
    concarmazensacc2: false,
    formosoacc2: false,
    lagoaacc2: false,
    miranorteacc2: false,
    portoacc2: false,
    silvanopolisacc2: false,
    tassoacc2: false,
    usosoloacc2: false,
    hidroviasgeralacc2: false,
    viasgeralacc2: false,
    portsaoluisacc2: false,
    portnacionalacc2: false,
    intpalemeiranteacc2: false,
    rodoviasacc2: false,
    instalacoesportacc2: false,
    hidroviasofcacc2: false,
    ferroviasacc2: false,
    usosolomiranorteacc2: false,
    usosolosilvanopolisacc2: false,
    usosololagoaacc2: false,
    usosoloformosoacc2: false,
    usosoloportoacc2: false,
    usosolobalsasacc2: false,
    usosolotassoacc2: false,

    lmsaltosc: false,
    compsaltosc: false,
    r1saltosc: false,
    r2saltosc: false,
    pfsaltosc: false,
    scsaltosc: false,
    lmmarasc: false,
    compmarasc: false,
    buffermarasc: false,
    pfmarasc: false,
    scmarasc: false,
    lmjacaresc: false,
    compjacaresc: false,
    r1jacaresc: false,
    pfjacaresc: false,
    scjacaresc: false,

    //peconi sum e pau
    terrenospec: false,
    terrenospecpau: false,

    amoarmazensacc2: false,
    prestaacc2: false,
    habacc2: false,
    capacc2: false,
    tipoacc2: false,
    porteacc2: false,
    formacc2: false,
    distferroacc2: false,
    distermacc2: false,
    estosoacc2: false,
    precosoacc2: false,
    popacc2: false,
    rendaacc2: false,
    aeroacc2: false,
    declacc2: false,

    dadossociosaltossc: false,
    fluxo1marajasc: false,
    kernelfluxo1marajasc: false,
    familiasmarajassc: false,
    rendamarajassc: false,
    dadossociojacaresc: false,

    crescimentobrz: false,
    domiciliosaluguelbrz: false,
    domicioliosbrz: false,
    empreendimentosbrz: false,
    lavrasbrz: false,
    popbrz: false,
    raio15brz: false,
    rendabrz: false,
    vetorbrz: false,

    prop1toacc2: false,
    prop2toacc2: false,
    prop1maacc2: false,
    prop2maacc2: false,

    pontointbrz: false,
    crescimento1brz: false,
    deficitbrz: false,
    taxahabbrz: false,

    gtdcamacircu: false,
    distcamacircu: false,
    arearedcamacircu: false,
    areaspotcamacircu: false,
    assocamacircu: false,
    camacircu: false,
    empscamacircu: false,
    concempcamacircu: false,
    areaspotvargcircu: false,
    assovargcircu: false,
    vargcircu: false,
    empsvargcircu: false,
    gtdcampcircu: false,
    distcampcircu: false,
    distecocampcircu: false,
    arearedcampcircu: false,
    areaspotcampcircu: false,
    assocampcircu: false,
    campcircu: false,
    empscampcircu: false,
    densempscampcircu: false,
    ecoscampcircu: false,
    gtdjundcircu: false,
    distjundcircu: false,
    distecojundcircu: false,
    arearedjundcircu: false,
    areaspotjundcircu: false,
    assojundcircu: false,
    jundcircu: false,
    empsjundcircu: false,
    densempsjundcircu: false,
    ecosjundcircu: false,
    gtdsorocircu: false,
    distsorocircu: false,
    distecosorocircu: false,
    arearedsorocircu: false,
    areaspotsorocircu: false,
    assosorocircu: false,
    sorocircu: false,
    empssorocircu: false,
    densempssorocircu: false,
    ecossorocircu: false,

    terrenoslivre: false,

    areapotafasdp: false,
    imoveisdp: false,

    jundiaimrv: false,
    appjundiaimrv: false,
    vegjundiaimrv: false,
    resjundiaimrv: false,
    areaprojundiaimrv: false,
    nascjundiaimrv: false,
    cursjundiaimrv: false,
    corposjundiaimrv: false,
    topojundiaimrv: false,
    urbjundiaimrv: false,
    areapoujundiaimrv: false,
    servjundiaimrv: false,
    usorestjundiaimrv: false,
    areaconsjundiaimrv: false,
    areacontjundiaimrv: false,
    dutojundiaimrv: false,
    linhaojundiaimrv: false,
    zoneajundiaimrv: false,
    legavertjundiaimrv: false,
    legahorjundiaimrv: false,
    rendapopjundiaimrv: false,
    rendajundiaimrv: false,
    popjundiaimrv: false,
    domicjundiaimrv: false,
    domicalujundiaimrv: false,
    expurbjundiaimrv: false,
    taxahabjundiaimrv: false,
    graprohabjundiaimrv: false,
    areaurbjundiaimrv: false,
    arearujundiaimrv: false,
    vaziosjundiaimrv: false,
    consumoaguajundiaimrv: false,
    dispaguajundiaimrv: false,
    custojundiaimrv: false,
    terrenosjundiaimrv: false,
    tljundiaimrv: false,
    pontoajundiaimrv: false,
    pontodjundiaimrv: false,

    limeiramrv: false,
    applimeiramrv: false,
    veglimeiramrv: false,
    reslimeiramrv: false,
    areaprolimeiramrv: false,
    nasclimeiramrv: false,
    curslimeiramrv: false,
    corposlimeiramrv: false,
    topolimeiramrv: false,
    urblimeiramrv: false,
    areapoulimeiramrv: false,
    servlimeiramrv: false,
    usorestlimeiramrv: false,
    areaconslimeiramrv: false,
    areacontlimeiramrv: false,
    dutolimeiramrv: false,
    linhaolimeiramrv: false,
    zonealimeiramrv: false,
    legavertlimeiramrv: false,
    legahorlimeiramrv: false,
    rendapoplimeiramrv: false,
    rendalimeiramrv: false,
    poplimeiramrv: false,
    domiclimeiramrv: false,
    domicalulimeiramrv: false,
    expurblimeiramrv: false,
    taxahablimeiramrv: false,
    graprohablimeiramrv: false,
    areaurblimeiramrv: false,
    arearulimeiramrv: false,
    vazioslimeiramrv: false,
    consumoagualimeiramrv: false,
    dispagualimeiramrv: false,
    custolimeiramrv: false,
    terrenoslimeiramrv: false,
    tllimeiramrv: false,
    pontoalimeiramrv: false,
    pontodlimeiramrv: false,

    veloprecojundiaimrv: false,
    veloprecolimeiramrv: false,
    //gurg
    bdgu: false,

    boticampcircu: false,
    botijundcircu: false,
    botisorocircu: false,
    botigrandespcircu: false,
    grandespcircu: false,

    imoveisc1ic: false,
    raioc1ic: false,
    areaestudoc2ic: false,
    rendac2ic: false,
    recortec2ic: false,
    pontosfluxc2ic: false,
    concfluxoc2ic: false,
    oticasc2ic: false,
    concfluxooticasc2ic: false,
    imoveisc2ic: false,
    shoppingsc2ic: false,

    kernelsaltosc: false,
    kerneljacaresc: false,
    ponibusmarasc: false,
    lonibusmarasc: false,

    redesdrenalimeiramrv: false,
    recargahidricalimeiramrv: false,
    ferroviaslimeiramrv: false,
    viaslimeiramrv: false,
    viasligalimeiramrv: false,
    rankingterlimeiramrv: false,
    redesdrenajundiaimrv: false,
    recargahidricajundiaimrv: false,
    viasjundiaimrv: false,
    viasligajundiaimrv: false,
    ferroviasjundiaimrv: false,
    equipamentosjundiaimrv: false,
    rankingterjundiaimrv: false,
    poluicaosonorajundiaimrv: false,

    acadmelten: false,
    areasafastmelten: false,
    areasproxmelten: false,
    comerciosmelten: false,
    concconcmelten: false,
    concmelten: false,
    crescurbanomelten: false,
    domiciliosmelten: false,
    equipublimelten: false,
    escolasprivadasmelten: false,
    hidroviasmelten: false,
    hospitaismelten: false,
    imoveismelten: false,
    infraviariamelten: false,
    melten: false,
    palmasmelten: false,
    popmelten: false,
    rendamelten: false,
    restaurantesmelten: false,
    transportemelten: false,
    viasmelten: false,

    gtdipocircu: false,
    arearedipocircu: false,
    areaspotipocircu: false,
    coopreciipocircu: false,
    ipocircu: false,
    empsipocircu: false,
    densempsipocircu: false,
    ecosipocircu: false,

    correcondcamacircu: false,
    correcondvargcircu: false,
    correcondcampcircu: false,
    correcondjundcircu: false,
    correcondsorocircu: false,

    regionalizacaomelten: false,

    crescpecpau: false,
    dadossociospecpau: false,
    hidropecpau: false,
    limitesambpecpau: false,
    pontosfluxopecpau: false,
    raios500pecpau: false,
    servidaopecpau: false,
    terrenospotpecpau: false,
    terrenosranqpecpau: false,
    topopecpau: false,
    vaziospecpau: false,

    playersagrovh: false,
    campinasvh: false,
    parquesteccampvh: false,

    demandagu: false,
    ativpesquisagu: false,
    interessehubgu: false,
    modelotrabalhogu: false,
    capitalpropriogu: false,
    fundestaduaisgu: false,
    sebraegu: false,
    bndesgu: false,
    investidoranjogu: false,
    fundosinvestgu: false,
    aceleradorasgu: false,
    semrespostagu: false,

    ictsvh: false,
    ictsagrovh: false,

    areaspreservavespemcp: false,
    vegnativavespemcp: false,
    reservalegalvespemcp: false,
    nascentesvespemcp: false,
    cursosaguavespemcp: false,
    topografiavespemcp: false,
    limitevespemcp: false,
    zoneamentovespemcp: false,
    usovertvespemcp: false,
    permissaovespemcp: false,
    locterrenovespemcp: false,
    servidaovespemcp: false,
    areaconsovespemcp: false,
    areascontavespemcp: false,
    dutoviasvespemcp: false,
    linhaovespemcp: false,
    novoscondvespemcp: false,
    rendavespemcp: false,
    popvespemcp: false,
    domicivespemcp: false,
    domicialuvespemcp: false,
    expurbanavespemcp: false,
    veloeprecovespemcp: false,
    taxahabvespemcp: false,
    concorrenciavespemcp: false,
    areaurbavespemcp: false,
    areasruraisvespemcp: false,
    consuaguavespemcp: false,
    dispaguavespemcp: false,
    custoprojetovespemcp: false,
    terrenosanunvespemcp: false,
    imoveisanunvespemcp: false,
    pontosatravespemcp: false,
    pontosdetravespemcp: false,

    tjchpdtjd: false,
    compdiretoschpdtjd: false,
    criancas05chpdtjd: false,
    escolasidiomaschpdtjd: false,
    escolasinfantischpdtjd: false,
    escolaschpdtjd: false,
    imovelindicadochpdtjd: false,
    pontosfluxochpdtjd: false,
    principaisviaschpdtjd: false,
    raio1kmcompchpdtjd: false,
    raio2kmcompchpdtjd: false,
    rendafamiliarchpdtjd: false,

    areainflurctjd: false,
    areasindirctjd: false,
    competidoresrctjd: false,
    condominiosrctjd: false,
    imoveisrctjd: false,
    pontosfluxirctjd: false,

    compdiretosvptjd: false,
    imoveissapopembavptjd: false,
    imoveisvpvptjd: false,
    pontosfluxovptjd: false,
    rendafamiliarvptjd: false,
    tjschoolvptjd: false,

    appwbgi: false,
    areacontwbgi: false,
    curvasnivelwbgi: false,
    declividadewbgi: false,
    distcorposaguawbgi: false,
    distcorposhidricoswbgi: false,
    hidrografiawbgi: false,
    limeirawbgi: false,
    reservalegalwbgi: false,
    vegnativawbgi: false,
    viveiristaswbgi: false,

    limiteosascotnd: false,
    areapreserosascotnd: false,
    vegnaivaosascotnd: false,
    reservaosascotnd: false,
    nascentesosascotnd: false,
    cursososascotnd: false,
    corpososascotnd: false,
    topografiaosascotnd: false,
    zoneamentoosascotnd: false,
    usovertosascotnd: false,
    implantacaohishmposascotnd: false,
    servadmosascotnd: false,
    areaconsoosascotnd: false,
    areascontaosascotnd: false,
    dutoviasosascotnd: false,
    linhaoosascotnd: false,
    pontosatratosascotnd: false,
    pontosdetratosascotnd: false,
    rendaosascotnd: false,
    domiciliososascotnd: false,
    domiciliosaluguelosascotnd: false,
    expcidadesosascotnd: false,
    precomedioosascotnd: false,
    taxahabosascotnd: false,
    concoosascotnd: false,
    areaurbanizadaosascotnd: false,
    areasruraisosascotnd: false,
    vaziosurbanososascotnd: false,
    grapohabosascotnd: false,
    terrenososascotnd: false,
    imoveisosascotnd: false,
    tendenciassetorosascotnd: false,
    sinaismudancaosascotnd: false,
    oportunidadeosascotnd: false,
    areausorestosascotnd: false,

    areapotlimeiramrv: false,

    areapreserslgb: false,
    vegnativaslgb: false,
    reservaslgb: false,
    nascentesslgb: false,
    hidrografiaslgb: false,
    topografiaslgb: false,
    zoneamentoslgb: false,
    usovertslgb: false,
    usohorslgb: false,
    viasslgb: false,
    dutoviasslgb: false,
    linhaoslgb: false,
    pontosatratosslgb: false,
    pontosdetratslgb: false,
    rendaslgb: false,
    domiciliosslgb: false,
    concoslgb: false,
    areasruraisslgb: false,
    terrenosslgb: false,
    imoveisslgb: false,

    raiopesquisaslgb: false,
    terrenosanaslgb: false,

    limitestatnd: false,
    areapreserstatnd: false,
    vegnaivastatnd: false,
    reservastatnd: false,
    nascentesstatnd: false,
    hidrocarstatnd: false,
    hidrocompstatnd: false,
    topografiastatnd: false,
    zoneamentostatnd: false,
    usovertstatnd: false,
    implantacaohishmpstatnd: false,
    servadmstatnd: false,
    areausoreststatnd: false,
    areaconsostatnd: false,
    areascontastatnd: false,
    dutoviasstatnd: false,
    linhaostatnd: false,
    rededrenagemstatnd: false,
    pontosatratstatnd: false,
    pontosdetratstatnd: false,
    domiciliosstatnd: false,
    rendastatnd: false,
    domiciliosaluguelstatnd: false,
    expcidadesstatnd: false,
    expverticalstatnd: false,
    precomediostatnd: false,
    taxahabstatnd: false,
    areaurbanizadastatnd: false,
    areasruraisstatnd: false,
    vaziosurbanosstatnd: false,
    grapohabstatnd: false,
    terrenosstatnd: false,
    imoveisstatnd: false,

    limiteriva: false,
    recorteriva: false,
    areapreserriva: false,
    conservacaoriva: false,
    cursosriva: false,
    nascentesriva: false,
    areasriscoriva: false,
    areasriscocontriva: false,
    topografiariva: false,
    zoneamentoriva: false,
    usovertriva: false,
    aeroportosriva: false,
    dutoviasriva: false,
    linhaoriva: false,
    protculturalriva: false,
    bemcultriva: false,
    bemcultimovelriva: false,
    bemcultnaturalriva: false,
    diretrizriva: false,
    tombamentoriva: false,
    pontosatratriva: false,
    pontosdetratriva: false,
    ocupacaoriva: false,
    rendariva: false,
    pmedioriva: false,
    taxahabriva: false,
    concoriva: false,
    vaziosriva: false,
    imoveisanunriva: false,

    limitemacapaic: false,
    recortemacapaic: false,
    varejomacapaic: false,
    concvarejomacapaic: false,
    concfluxomacapaic: false,
    shoppingsmacapaic: false,
    escolasmacapaic: false,
    hospitaismacapaic: false,
    estacionamentosmacapaic: false,
    concorrentesmacapaic: false,
    universidadesmacapaic: false,
    assaimacapaic: false,
    rendamacapaic: false,
    domiciliosmacapaic: false,
    popmacapaic: false,
    imoveismacapaic: false,

    catimoveisriva: false,

    domiciliosaureo: false,
    empresasaureo: false,
    locinteresseaureo: false,
    marcasaureo: false,
    pontosaureo: false,
    raio1kmaureo: false,
    raio2kmaureo: false,
    rendaaureo: false,
    viasaureo: false,
    zoneamentoaureo: false,

    piscininha: false,
    piscinao: false,

    imoveisvendidosemcp: false,
    outrossetoresemcp: false,
    setorescomimoveisemcp: false,
    vendasemcp: false,

    comerciosaureopet1: false,
    domiciliosaureopet1: false,
    empreendimentoaureopet1: false,
    limiteaureopet1: false,
    localizacaoaureopet1: false,
    marcasvareaureopet1: false,
    r1kmaureopet1: false,
    r2kmaureopet1: false,
    rendaaureopet1: false,
    viasacessoaureopet1: false,
    comerciosaureopet2: false,
    domiciliosaureopet2: false,
    empreendimentoaureopet2: false,
    localizacaoaureopet2: false,
    r1kmaureopet2: false,
    r2kmaureopet2: false,
    rendaaureopet2: false,
    viasacessoaureopet2: false,
    zoneamentoaureopet2: false,

    hidrologiaaureopet1: false,
topografiaaureopet1: false,
hidrologiaaureopet2: false,
topografiaaureopet2: false,

topografiaacc3: false,
zoneamentoacc3: false,
areapousoacc3: false,
rotaaviaoacc3: false,
areascontacc3: false,
dutoviasacc3: false,
estabelecimentosacc3: false,
veiculosacc3: false,
fluxoveicacc3: false,
movimentacaoacc3: false,
rendaacc3: false,
populacaoacc3: false,
domiciliosacc3: false,
domialuguelacc3: false,
areaurbaacc3: false,
spacc3: false,
guarulhosacc3: false,
trechoacc3: false,

aeror2soroaureo: false,
shoppingr2soroaureo: false,
condor2soroaureo: false,
domicilior3soroaureo: false,
popr3soroaureo: false,
varejor3soroaureo: false,
industriar3soroaureo: false,
soroaureo: false,
domicilior4soroaureo: false,
popr4soroaureo: false,
varejor4soroaureo: false,
industriar4soroaureo: false,
domicilior5soroaureo: false,
popr5soroaureo: false,
varejor5soroaureo: false,
industriar5soroaureo: false,
sud1petrop1aureo: false,
sud2petrop1aureo: false,
sud1petrop2aureo: false,
sud2petrop2aureo: false,
  });

  const handleCheckboxChange = (event) => {
    if (selectedMun) {
      const { name, checked } = event.target;
      const updatedCheckboxes = {
        ...checkboxes,
        [name]: checked,
      };
      setCheckboxes(updatedCheckboxes);
      camadasChange(updatedCheckboxes);
    } else {
      toast.error("🦄 Wow so easy!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleLabelboxChange = (event) => {
    setLabelsVisible(false);
    labelsVisibleChange(false);
  };

  const handleImprimir = (event) => {
    imprimir();
  };

  function deslogar() {
    signOut();
    navigate("/");
  }

  return (
    !loading && (
      <div className="sidebar">
        <div
          className="trackage-title"
          onClick={() => {
            history("/");
          }}
        >
          <img src={logoneg}></img>
        </div>
        <div className="logoutdiv">
          <FaSignOutAlt
            color="orange"
            size={20}
            onClick={deslogar}
            style={{ cursor: "pointer" }}
          ></FaSignOutAlt>
        </div>
        <div className="container-items mb-4">
          <div className="icon-title">
            <BiMapAlt color="#0E83E4" size={20}></BiMapAlt>
            <p className="cityselect">Estado</p>
          </div>
          <div className="select-wrapper">
            <Select
              value={selectedEstado}
              onChange={handleSelectChange}
              options={estadosNames}
              isSearchable={true} // Habilita a funcionalidade de busca
            />
          </div>
        </div>

        <div className="container-items mb-4">
          <div className="icon-title">
            <FaCity color="#0E83E4" size={20}></FaCity>
            <p className="cityselect">Município</p>
          </div>

          <div className="select-wrapper">
            <Select
              value={selectedMun || ""}
              onChange={handleSelectChangeMun}
              options={municipios}
              isSearchable={true} // Habilita a funcionalidade de busca
            />
          </div>
        </div>

        <div className="container-items-check mb-4">
          <div className="w-full flex justify-between">
            <div className="flex gap-1">
              <BsFillLayersFill
                className="flex-none"
                color="#0E83E4"
                size={20}
              ></BsFillLayersFill>
              <p className="cityselect">Camadas</p>
            </div>
            <div className="flex gap-1 items-center">
              <button className="ocultar h-6 " onClick={handleLabelboxChange}>
                <p className="text-xs">Ocultar Rótulos</p>
              </button>
              <BsCloudDownloadFill
                color="#0E83E4"
                size={20}
                className="ri-download flex-none"
                onClick={() => handleImprimir()}
              ></BsCloudDownloadFill>
            </div>
          </div>

          {selectedMun && userType === "All" && (
            <label>
              <input
                type="checkbox"
                name="limite"
                checked={checkboxes.limite}
                onChange={handleCheckboxChange}
              />
              Limite Município
            </label>
          )}

          {selectedMun && userType === "All" && (
            <label>
              <input
                type="checkbox"
                name="zoneamento"
                checked={checkboxes.zoneamento}
                onChange={handleCheckboxChange}
              />
              Zoneamento
            </label>
          )}
        </div>

        {!user && (
          <div className="container-items-check">
            <div className="locked">
              <RiLock2Fill color="orange" size={18}></RiLock2Fill>
              <label>Topografia</label>
            </div>

            <div className="locked">
              <RiLock2Fill color="orange" size={18}></RiLock2Fill>
              <label>Hidrografia</label>
            </div>

            <div className="locked">
              <RiLock2Fill color="orange" size={18}></RiLock2Fill>
              <label>Limites Ambientais</label>
            </div>

            <div className="locked">
              <RiLock2Fill color="orange" size={18}></RiLock2Fill>
              <label>Permissão</label>
            </div>

            <div className="locked">
              <RiLock2Fill color="orange" size={18}></RiLock2Fill>
              <label>Dados Socioeconomicos</label>
            </div>

            <div className="locked">
              <RiLock2Fill color="orange" size={18}></RiLock2Fill>
              <label>Pontos de Fluxo</label>
            </div>
          </div>
        )}

        {user && (
          <div id="defaultc" className="container-items-check">
            {selectedMun && userType === "All" && (
              <label>
                <input
                  type="checkbox"
                  name="topografia"
                  checked={checkboxes.topografia}
                  onChange={handleCheckboxChange}
                />
                Topografia
              </label>
            )}

            {selectedMun && userType === "All" && (
              <label>
                <input
                  type="checkbox"
                  name="hidrografia"
                  checked={checkboxes.hidrografia}
                  onChange={handleCheckboxChange}
                />
                Hidrografia
              </label>
            )}

            {selectedMun && userType === "All" && (
              <label>
                <input
                  type="checkbox"
                  name="limitesa"
                  checked={checkboxes.limitesa}
                  onChange={handleCheckboxChange}
                />
                Limites Ambientais
              </label>
            )}

            {/* ((selectedMun && userType === 'All')) &&
<label>
  <input
    type="checkbox"
    name="permissao"
    checked={checkboxes.permissao}
    onChange={handleCheckboxChange}
  />
  Permissão
</label>*/}

            {/*((selectedMun && userType === 'All')) &&
<label>
  <input
    type="checkbox"
    name="dados"
    checked={checkboxes.dados}
    onChange={handleCheckboxChange}
  />
  Dados Socioeconomicos
</label>*/}

            {selectedMun && userType === "All" && (
              <label>
                <input
                  type="checkbox"
                  name="fluxo"
                  checked={checkboxes.fluxo}
                  onChange={handleCheckboxChange}
                />
                Pontos de Fluxo
              </label>
            )}

            {selectedMun && userType === "All" && (
              <label>
                <input
                  type="checkbox"
                  name="servidao"
                  checked={checkboxes.servidao}
                  onChange={handleCheckboxChange}
                />
                Servidão
              </label>
            )}

            {/* ((selectedMun && userType === 'All')) &&
<label>
  <input
    type="checkbox"
    name="crescimento"
    checked={checkboxes.crescimento}
    onChange={handleCheckboxChange}
  />
  Crescimento
</label>*/}
          </div>
        )}

        {selectedMun && userType === "luka" && (
          <div className="container-items-check">
            <label>
              <input
                type="checkbox"
                name="imoveis200"
                checked={checkboxes.imoveis200}
                onChange={handleCheckboxChange}
              />
              Imóveis Comerciais 200m²
            </label>

            <label>
              <input
                type="checkbox"
                name="setoreslk"
                checked={checkboxes.setoreslk}
                onChange={handleCheckboxChange}
              />
              Público-Alvo
            </label>

            <label>
              <input
                type="checkbox"
                name="raio1000lk"
                checked={checkboxes.raio1000lk}
                onChange={handleCheckboxChange}
              />
              Raio 1000m
            </label>

            <label>
              <input
                type="checkbox"
                name="raio500lk"
                checked={checkboxes.raio500lk}
                onChange={handleCheckboxChange}
              />
              Raio 500m
            </label>

            <label>
              <input
                type="checkbox"
                name="viaslk"
                checked={checkboxes.viaslk}
                onChange={handleCheckboxChange}
              />
              Vias
            </label>

            <label>
              <input
                type="checkbox"
                name="competidoreslk"
                checked={checkboxes.competidoreslk}
                onChange={handleCheckboxChange}
              />
              Farmácias Competidores
            </label>

            <label>
              <input
                type="checkbox"
                name="hospitaislk"
                checked={checkboxes.hospitaislk}
                onChange={handleCheckboxChange}
              />
              Hospitais
            </label>

            <label>
              <input
                type="checkbox"
                name="pracaslk"
                checked={checkboxes.pracaslk}
                onChange={handleCheckboxChange}
              />
              Praças / Parques
            </label>

            <label>
              <input
                type="checkbox"
                name="mercadoslk"
                checked={checkboxes.mercadoslk}
                onChange={handleCheckboxChange}
              />
              Mercados
            </label>

            <label>
              <input
                type="checkbox"
                name="restauranteslk"
                checked={checkboxes.restauranteslk}
                onChange={handleCheckboxChange}
              />
              Restaurantes
            </label>

            <label>
              <input
                type="checkbox"
                name="condominioslk"
                checked={checkboxes.condominioslk}
                onChange={handleCheckboxChange}
              />
              Condomínios
            </label>

            <label>
              <input
                type="checkbox"
                name="farmacialk"
                checked={checkboxes.farmacialk}
                onChange={handleCheckboxChange}
              />
              Farmácias
            </label>
          </div>
        )}

        {selectedMun && userType === "manapoke" && (
          <div className="container-items-check">
            <label>
              <input
                type="checkbox"
                name="academiasmanapoke"
                checked={checkboxes.academiasmanapoke}
                onChange={handleCheckboxChange}
              />
              Academias
            </label>

            <label>
              <input
                type="checkbox"
                name="areaspotenciaismanapoke"
                checked={checkboxes.areaspotenciaismanapoke}
                onChange={handleCheckboxChange}
              />
              Áreas Potenciais
            </label>

            <label>
              <input
                type="checkbox"
                name="centrosempresariasmanapoke"
                checked={checkboxes.centrosempresariasmanapoke}
                onChange={handleCheckboxChange}
              />
              Centros Empresariais
            </label>

            <label>
              <input
                type="checkbox"
                name="centrosesportivosmanapoke"
                checked={checkboxes.centrosesportivosmanapoke}
                onChange={handleCheckboxChange}
              />
              Centros Esportivos
            </label>

            <label>
              <input
                type="checkbox"
                name="comerciomanapoke"
                checked={checkboxes.comerciomanapoke}
                onChange={handleCheckboxChange}
              />
              Comércio
            </label>

            <label>
              <input
                type="checkbox"
                name="compcomidajaponesamanapoke"
                checked={checkboxes.compcomidajaponesamanapoke}
                onChange={handleCheckboxChange}
              />
              Competidores Comida Japonesa
            </label>

            <label>
              <input
                type="checkbox"
                name="compcomidasaudavelmanapoke"
                checked={checkboxes.compcomidasaudavelmanapoke}
                onChange={handleCheckboxChange}
              />
              Competidores Comida Saudável
            </label>

            <label>
              <input
                type="checkbox"
                name="imoveiscomerciaismanapoke"
                checked={checkboxes.imoveiscomerciaismanapoke}
                onChange={handleCheckboxChange}
              />
              Imóveis Comerciais
            </label>

            <label>
              <input
                type="checkbox"
                name="pontosculturaismanapoke"
                checked={checkboxes.pontosculturaismanapoke}
                onChange={handleCheckboxChange}
              />
              Pontos Culturais
            </label>

            <label>
              <input
                type="checkbox"
                name="pontoseducacaomanapoke"
                checked={checkboxes.pontoseducacaomanapoke}
                onChange={handleCheckboxChange}
              />
              Pontos Educação
            </label>

            <label>
              <input
                type="checkbox"
                name="rendamanapoke"
                checked={checkboxes.rendamanapoke}
                onChange={handleCheckboxChange}
              />
              Renda Balneário Camboriú
            </label>
          </div>
        )}

        {selectedMun && userType === "tony" && (
          <div className="container-items-check">
            <label>
              <input
                type="checkbox"
                name="Cruzeiro_1_Zoneamento"
                checked={checkboxes.Cruzeiro_1_Zoneamento}
                onChange={handleCheckboxChange}
              />
              Cruzeiro Zoneamento
            </label>

            <label>
              <input
                type="checkbox"
                name="Guaxupe_1_zoneamento"
                checked={checkboxes.Guaxupe_1_zoneamento}
                onChange={handleCheckboxChange}
              />
              Guaxupé Zoneamento
            </label>

            <label>
              <input
                type="checkbox"
                name="Lavras_zoneamento_TESTE"
                checked={checkboxes.Lavras_zoneamento_TESTE}
                onChange={handleCheckboxChange}
              />
              Lavras Zoneamento
            </label>

            <label>
              <input
                type="checkbox"
                name="SSParaiso_1_Zoneamento"
                checked={checkboxes.SSParaiso_1_Zoneamento}
                onChange={handleCheckboxChange}
              />
              São Sebastião do Paraíso
            </label>
          </div>
        )}

        {selectedMun &&
          userType === "peconi" &&
          selectedMun.value === "Sumaré" && (
            <div className="container-items-check">
              <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="zoneamentopec"
                  checked={checkboxes.zoneamentopec}
                  onChange={handleCheckboxChange}
                />
                Zoneamento
              </label>

              <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="terrenospec"
                  checked={checkboxes.terrenospec}
                  onChange={handleCheckboxChange}
                />
                Terrenos Indicação Linkages
              </label>

              <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="pontosintpec"
                  checked={checkboxes.pontosintpec}
                  onChange={handleCheckboxChange}
                />
                Pontos de Interesse
              </label>

              <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="permissaopec"
                  checked={checkboxes.permissaopec}
                  onChange={handleCheckboxChange}
                />
                Permissão Residencial Vertical
              </label>

              <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="mapaconcentracaopec"
                  checked={checkboxes.mapaconcentracaopec}
                  onChange={handleCheckboxChange}
                />
                Mapa de Concentração
              </label>

              <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="divisaoregpec"
                  checked={checkboxes.divisaoregpec}
                  onChange={handleCheckboxChange}
                />
                Divisão Regional
              </label>

              <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="vaziospec"
                  checked={checkboxes.vaziospec}
                  onChange={handleCheckboxChange}
                />
                Vazios Urbanos Prefeitura
              </label>

              <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="usosolopec"
                  checked={checkboxes.usosolopec}
                  onChange={handleCheckboxChange}
                />
                Uso do solo não urbano
              </label>

              <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="concenfluxospec"
                  checked={checkboxes.concenfluxospec}
                  onChange={handleCheckboxChange}
                />
                Concentração de Fluxo
              </label>

              <Accordion alwaysOpen className="w-full mb-4">
                <Accordion.Panel>
                  <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                    Crescimento
                  </Accordion.Title>
                  <Accordion.Content className="px-0 py-4">
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="crescimentoanopec"
                        checked={checkboxes.crescimentoanopec}
                        onChange={handleCheckboxChange}
                      />
                      Vetor de crescimento por ano
                    </label>
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="graprohab"
                        checked={checkboxes.graprohab}
                        onChange={handleCheckboxChange}
                      />
                      Graprohab
                    </label>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>

              <Accordion alwaysOpen className="w-full mb-4">
                <Accordion.Panel>
                  <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                    Dados Socioeconômicos
                  </Accordion.Title>
                  <Accordion.Content className="px-0 py-4">
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="densidadepec"
                        checked={checkboxes.densidadepec}
                        onChange={handleCheckboxChange}
                      />
                      Densidade demográfica (hab/km²)
                    </label>

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="familiaspec"
                        checked={checkboxes.familiaspec}
                        onChange={handleCheckboxChange}
                      />
                      Famílias
                    </label>
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="rendapec"
                        checked={checkboxes.rendapec}
                        onChange={handleCheckboxChange}
                      />
                      Renda
                    </label>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>

              <Accordion alwaysOpen className="w-full mb-4">
                <Accordion.Panel>
                  <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                    Topografia
                  </Accordion.Title>
                  <Accordion.Content className="px-0 py-4">
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="topografiapec"
                        checked={checkboxes.topografiapec}
                        onChange={handleCheckboxChange}
                      />
                      Declividade (%)
                    </label>
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="topografiapec2"
                        checked={checkboxes.topografiapec2}
                        onChange={handleCheckboxChange}
                      />
                      Curvas de nível
                    </label>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>

              <Accordion alwaysOpen className="w-full mb-4">
                <Accordion.Panel>
                  <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                    Hidrografia
                  </Accordion.Title>
                  <Accordion.Content className="px-0 py-4">
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="corpospec"
                        checked={checkboxes.corpospec}
                        onChange={handleCheckboxChange}
                      />
                      Corpos D'água
                    </label>

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="cursospec"
                        checked={checkboxes.cursospec}
                        onChange={handleCheckboxChange}
                      />
                      Cursos D'água
                    </label>
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="nascentespec"
                        checked={checkboxes.nascentespec}
                        onChange={handleCheckboxChange}
                      />
                      Nascentes
                    </label>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>

              <Accordion alwaysOpen className="w-full mb-4">
                <Accordion.Panel>
                  <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                    Limites Ambientais
                  </Accordion.Title>
                  <Accordion.Content className="px-0 py-4">
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="apppec"
                        checked={checkboxes.apppec}
                        onChange={handleCheckboxChange}
                      />
                      APP - Áreas de Proteção Permanente
                    </label>

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="reservapec"
                        checked={checkboxes.reservapec}
                        onChange={handleCheckboxChange}
                      />
                      Reserva Legal
                    </label>
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="vegpec"
                        checked={checkboxes.vegpec}
                        onChange={handleCheckboxChange}
                      />
                      Vegetação Nativa
                    </label>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>

              <Accordion alwaysOpen className="w-full mb-4">
                <Accordion.Panel>
                  <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                    Terrenos Disponíveis
                  </Accordion.Title>
                  <Accordion.Content className="px-0 py-4">
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="terrenos2k4kpec"
                        checked={checkboxes.terrenos2k4kpec}
                        onChange={handleCheckboxChange}
                      />
                      Terrenos à venda (2000m² a 4000m²)
                    </label>

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="terrenos5kmaispec"
                        checked={checkboxes.terrenos5kmaispec}
                        onChange={handleCheckboxChange}
                      />
                      Terrenos à venda (5000m² a 150000m²)
                    </label>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>

              <Accordion alwaysOpen className="w-full mb-4">
                <Accordion.Panel>
                  <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                    Terrenos Potenciais
                  </Accordion.Title>
                  <Accordion.Content className="px-0 py-4">
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="terrenospot5kmaispec"
                        checked={checkboxes.terrenospot5kmaispec}
                        onChange={handleCheckboxChange}
                      />
                      Terrenos potenciais (5000m² a 150000m²)
                    </label>

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="terrenospot150kmaispec"
                        checked={checkboxes.terrenospot150kmaispec}
                        onChange={handleCheckboxChange}
                      />
                      Terrenos potenciais + de 150.000m²
                    </label>

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="terrenosnaopec"
                        checked={checkboxes.terrenosnaopec}
                        onChange={handleCheckboxChange}
                      />
                      Terrenos que não permitem construção
                    </label>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>
            </div>
          )}

        {selectedMun &&
          userType === "peconi" &&
          selectedMun.value === "Paulínia" && (
            <div className="container-items-check">
              <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="crescpecpau"
                  checked={checkboxes.crescpecpau}
                  onChange={handleCheckboxChange}
                />
                Crescimento
              </label>

              <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="dadossociospecpau"
                  checked={checkboxes.dadossociospecpau}
                  onChange={handleCheckboxChange}
                />
                Dados Socioeconômicos
              </label>

              <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="hidropecpau"
                  checked={checkboxes.hidropecpau}
                  onChange={handleCheckboxChange}
                />
                Hidrografia
              </label>

              <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="limitesambpecpau"
                  checked={checkboxes.limitesambpecpau}
                  onChange={handleCheckboxChange}
                />
                Limites Ambientais
              </label>

              <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="pontosfluxopecpau"
                  checked={checkboxes.pontosfluxopecpau}
                  onChange={handleCheckboxChange}
                />
                Pontos de Fluxo
              </label>

              <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="raios500pecpau"
                  checked={checkboxes.raios500pecpau}
                  onChange={handleCheckboxChange}
                />
                Raios de 500m
              </label>

              <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="servidaopecpau"
                  checked={checkboxes.servidaopecpau}
                  onChange={handleCheckboxChange}
                />
                Servidão
              </label>

              <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="terrenospecpau"
                  checked={checkboxes.terrenospecpau}
                  onChange={handleCheckboxChange}
                />
                Terrenos Indicação Linkages
              </label>

              <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="terrenospotpecpau"
                  checked={checkboxes.terrenospotpecpau}
                  onChange={handleCheckboxChange}
                />
                Terrenos Potenciais
              </label>

              <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="terrenosranqpecpau"
                  checked={checkboxes.terrenosranqpecpau}
                  onChange={handleCheckboxChange}
                />
                Terrenos Ranqueados
              </label>

              <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="topopecpau"
                  checked={checkboxes.topopecpau}
                  onChange={handleCheckboxChange}
                />
                Topografia
              </label>

              <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="vaziospecpau"
                  checked={checkboxes.vaziospecpau}
                  onChange={handleCheckboxChange}
                />
                Vazios Urbanos
              </label>
            </div>
          )}

        {selectedMun &&
          userType === "TheJoy" &&
          selectedMun.value === "Rio Claro" && (
            <div className="container-items-check">
              <label>
                <input
                  type="checkbox"
                  name="competidorestj"
                  checked={checkboxes.competidorestj}
                  onChange={handleCheckboxChange}
                />
                Competidores
              </label>

              <label>
                <input
                  type="checkbox"
                  name="areastj"
                  checked={checkboxes.areastj}
                  onChange={handleCheckboxChange}
                />
                Áreas Indicadas
              </label>

              <label>
                <input
                  type="checkbox"
                  name="condominiostj"
                  checked={checkboxes.condominiostj}
                  onChange={handleCheckboxChange}
                />
                Condominios
              </label>

              <label>
                <input
                  type="checkbox"
                  name="influenciatj"
                  checked={checkboxes.influenciatj}
                  onChange={handleCheckboxChange}
                />
                Área Influência
              </label>

              <label>
                <input
                  type="checkbox"
                  name="pontosfluxotj"
                  checked={checkboxes.pontosfluxotj}
                  onChange={handleCheckboxChange}
                />
                Pontos de Fluxo
              </label>

              <label>
                <input
                  type="checkbox"
                  name="imoveis"
                  checked={checkboxes.imoveis}
                  onChange={handleCheckboxChange}
                />
                Imóveis
              </label>
            </div>
          )}

        {selectedMun &&
          userType === "thejoyschool" &&
          selectedMun.value === "Campinas" && (
            <div className="container-items-check">
              <label>
                <input
                  type="checkbox"
                  name="c1"
                  checked={checkboxes.c1}
                  onChange={handleCheckboxChange}
                />
                Competidores Diretos
              </label>

              <label>
                <input
                  type="checkbox"
                  name="c2"
                  checked={checkboxes.c2}
                  onChange={handleCheckboxChange}
                />
                Comp. Escolas de Idioma
              </label>

              <label>
                <input
                  type="checkbox"
                  name="c3"
                  checked={checkboxes.c3}
                  onChange={handleCheckboxChange}
                />
                Comp. Escolas Infantis
              </label>

              <label>
                <input
                  type="checkbox"
                  name="c4"
                  checked={checkboxes.c4}
                  onChange={handleCheckboxChange}
                />
                Escolas
              </label>

              <label>
                <input
                  type="checkbox"
                  name="c5"
                  checked={checkboxes.c5}
                  onChange={handleCheckboxChange}
                />
                Hospitais
              </label>

              <label>
                <input
                  type="checkbox"
                  name="c6"
                  checked={checkboxes.c6}
                  onChange={handleCheckboxChange}
                />
                Imóveis Indicados
              </label>

              <label>
                <input
                  type="checkbox"
                  name="c7"
                  checked={checkboxes.c7}
                  onChange={handleCheckboxChange}
                />
                Mercados
              </label>

              <label>
                <input
                  type="checkbox"
                  name="c8"
                  checked={checkboxes.c8}
                  onChange={handleCheckboxChange}
                />
                Praças e Parques
              </label>

              <label>
                <input
                  type="checkbox"
                  name="c9"
                  checked={checkboxes.c9}
                  onChange={handleCheckboxChange}
                />
                Principais Vias
              </label>

              <label>
                <input
                  type="checkbox"
                  name="c10"
                  checked={checkboxes.c10}
                  onChange={handleCheckboxChange}
                />
                Raio 1km Competidores
              </label>

              <label>
                <input
                  type="checkbox"
                  name="c11"
                  checked={checkboxes.c11}
                  onChange={handleCheckboxChange}
                />
                Raio 2km Competidores
              </label>

              <label>
                <input
                  type="checkbox"
                  name="c12"
                  checked={checkboxes.c12}
                  onChange={handleCheckboxChange}
                />
                Restaurantes
              </label>

              <label>
                <input
                  type="checkbox"
                  name="c13"
                  checked={checkboxes.c13}
                  onChange={handleCheckboxChange}
                />
                Setores Crianças 0-5
              </label>

              <label>
                <input
                  type="checkbox"
                  name="c14"
                  checked={checkboxes.c14}
                  onChange={handleCheckboxChange}
                />
                Setores Renda Familiar
              </label>

              <label>
                <input
                  type="checkbox"
                  name="c15"
                  checked={checkboxes.c15}
                  onChange={handleCheckboxChange}
                />
                Teatros
              </label>

              <label>
                <input
                  type="checkbox"
                  name="c16"
                  checked={checkboxes.c16}
                  onChange={handleCheckboxChange}
                />
                Clubes e Academias
              </label>
            </div>
          )}

        {selectedMun &&
          userType === "thejoyschool" &&
          selectedMun.value === "Paulinia" && (
            <div className="container-items-check">
              <label>
                <input
                  type="checkbox"
                  name="areasinfluenciatjp"
                  checked={checkboxes.areasinfluenciatjp}
                  onChange={handleCheckboxChange}
                />
                Áreas de Influência
              </label>

              <label>
                <input
                  type="checkbox"
                  name="areasindicadastjp"
                  checked={checkboxes.areasindicadastjp}
                  onChange={handleCheckboxChange}
                />
                Áreas Indicadas
              </label>

              <label>
                <input
                  type="checkbox"
                  name="compdiretostjp"
                  checked={checkboxes.compdiretostjp}
                  onChange={handleCheckboxChange}
                />
                Competidores Diretos
              </label>

              <label>
                <input
                  type="checkbox"
                  name="compindiretostjp"
                  checked={checkboxes.compindiretostjp}
                  onChange={handleCheckboxChange}
                />
                Competidores Indiretos
              </label>

              <label>
                <input
                  type="checkbox"
                  name="condominiostjp"
                  checked={checkboxes.condominiostjp}
                  onChange={handleCheckboxChange}
                />
                Condomínios
              </label>

              <label>
                <input
                  type="checkbox"
                  name="imoveistjp"
                  checked={checkboxes.imoveistjp}
                  onChange={handleCheckboxChange}
                />
                Imóveis
              </label>

              <label>
                <input
                  type="checkbox"
                  name="limitetjp"
                  checked={checkboxes.limitetjp}
                  onChange={handleCheckboxChange}
                />
                Limite Município
              </label>

              <label>
                <input
                  type="checkbox"
                  name="pontosfluxotjp"
                  checked={checkboxes.pontosfluxotjp}
                  onChange={handleCheckboxChange}
                />
                Pontos de Fluxo
              </label>

              <label>
                <input
                  type="checkbox"
                  name="escolastjp"
                  checked={checkboxes.escolastjp}
                  onChange={handleCheckboxChange}
                />
                Escolas The Joy
              </label>
            </div>
          )}

        {selectedMun &&
          userType === "gen" &&
          selectedMun.value === "São João da Boa Vista" && (
            <div className="container-items-check">
              <label>
                <input
                  type="checkbox"
                  name="sjbv"
                  checked={checkboxes.sjbv}
                  onChange={handleCheckboxChange}
                />
                São João da Boa Vista
              </label>

              <label>
                <input
                  type="checkbox"
                  name="sjbv1zoneamento"
                  checked={checkboxes.sjbv1zoneamento}
                  onChange={handleCheckboxChange}
                />
                Zoneamento
              </label>

              <label>
                <input
                  type="checkbox"
                  name="sjbv2permissao"
                  checked={checkboxes.sjbv2permissao}
                  onChange={handleCheckboxChange}
                />
                Permissão
              </label>

              <label>
                <input
                  type="checkbox"
                  name="sjbv3terrenos"
                  checked={checkboxes.sjbv3terrenos}
                  onChange={handleCheckboxChange}
                />
                Terrenos
              </label>

              <label>
                <input
                  type="checkbox"
                  name="sjbv5topografia"
                  checked={checkboxes.sjbv5topografia}
                  onChange={handleCheckboxChange}
                />
                Topografia
              </label>

              <label>
                <input
                  type="checkbox"
                  name="sjbv6limitesa"
                  checked={checkboxes.sjbv6limitesa}
                  onChange={handleCheckboxChange}
                />
                Limites Ambientais
              </label>

              <label>
                <input
                  type="checkbox"
                  name="sjbv7hidrografia"
                  checked={checkboxes.sjbv7hidrografia}
                  onChange={handleCheckboxChange}
                />
                Hidrografia
              </label>

              <label>
                <input
                  type="checkbox"
                  name="sjbv8servidao"
                  checked={checkboxes.sjbv8servidao}
                  onChange={handleCheckboxChange}
                />
                Servidão
              </label>

              <label>
                <input
                  type="checkbox"
                  name="sjbv9terrenosp"
                  checked={checkboxes.sjbv9terrenosp}
                  onChange={handleCheckboxChange}
                />
                Terrenos Potenciais
              </label>

              <label>
                <input
                  type="checkbox"
                  name="sjbv10crescimento"
                  checked={checkboxes.sjbv10crescimento}
                  onChange={handleCheckboxChange}
                />
                Crescimento
              </label>

              <label>
                <input
                  type="checkbox"
                  name="sjbv11dadossocios"
                  checked={checkboxes.sjbv11dadossocios}
                  onChange={handleCheckboxChange}
                />
                Dados Socioeconomicos
              </label>

              <label>
                <input
                  type="checkbox"
                  name="sjbv12distviario"
                  checked={checkboxes.sjbv12distviario}
                  onChange={handleCheckboxChange}
                />
                Dist. Viário
              </label>

              <label>
                <input
                  type="checkbox"
                  name="sjbv13malha"
                  checked={checkboxes.sjbv13malha}
                  onChange={handleCheckboxChange}
                />
                Malha Territorial
              </label>

              <label>
                <input
                  type="checkbox"
                  name="sjbv14pontos"
                  checked={checkboxes.sjbv14pontos}
                  onChange={handleCheckboxChange}
                />
                Pontos de Interesse
              </label>

              {/* <label>
              <input
                type="checkbox"
                name="areaverdegl"
                checked={checkboxes.areaverdegl}
                onChange={handleCheckboxChange}
              />
              Área Verde
            </label>

            <label>
              <input
                type="checkbox"
                name="municipiogl"
                checked={checkboxes.municipiogl}
                onChange={handleCheckboxChange}
              />
              Município
            </label>

            <label>
              <input
                type="checkbox"
                name="condominiogooglegl"
                checked={checkboxes.condominiogooglegl}
                onChange={handleCheckboxChange}
              />
              Condomínios (Google)
            </label>

            <label>
              <input
                type="checkbox"
                name="condominiograpgl"
                checked={checkboxes.condominiograpgl}
                onChange={handleCheckboxChange}
              />
              Condomínios (Graprohab)
            </label>

            <label>
              <input
                type="checkbox"
                name="rendagl"
                checked={checkboxes.rendagl}
                onChange={handleCheckboxChange}
              />
              Renda
            </label> */}
            </div>
          )}

        {selectedMun &&
          userType === "gen" &&
          selectedMun.value === "Engenheiro Coelho" && (
            <div className="container-items-check">
              <label>
                <input
                  type="checkbox"
                  name="limitevmap"
                  checked={checkboxes.limitevmap}
                  onChange={handleCheckboxChange}
                  className="mr-2"
                />
                Engenheiro Coelho
              </label>

              <label>
                <input
                  type="checkbox"
                  name="popfemvmap"
                  checked={checkboxes.popfemvmap}
                  onChange={handleCheckboxChange}
                  className="mr-2"
                />
                População Feminina
              </label>

              <label>
                <input
                  type="checkbox"
                  name="popmasvmap"
                  checked={checkboxes.popmasvmap}
                  onChange={handleCheckboxChange}
                  className="mr-2"
                />
                População Masculina
              </label>

              <label>
                <input
                  type="checkbox"
                  name="poptotvmap"
                  checked={checkboxes.poptotvmap}
                  onChange={handleCheckboxChange}
                  className="mr-2"
                />
                População Total
              </label>

              <label>
                <input
                  type="checkbox"
                  name="prefeitovmap"
                  checked={checkboxes.prefeitovmap}
                  onChange={handleCheckboxChange}
                  className="mr-2"
                />
                Prefeito
              </label>

              <label>
                <input
                  type="checkbox"
                  name="vereadorvmap"
                  checked={checkboxes.vereadorvmap}
                  onChange={handleCheckboxChange}
                  className="mr-2"
                />
                Vereador
              </label>

              <label>
                <input
                  type="checkbox"
                  name="rendavmap"
                  checked={checkboxes.rendavmap}
                  onChange={handleCheckboxChange}
                  className="mr-2"
                />
                Renda
              </label>

              <label>
                <input
                  type="checkbox"
                  name="prefeitovmap2"
                  checked={checkboxes.prefeitovmap2}
                  onChange={handleCheckboxChange}
                  className="mr-2"
                />
                Prefeito Engenheiro Coelho
              </label>

              <label>
                <input
                  type="checkbox"
                  name="vereadorvmap2"
                  checked={checkboxes.vereadorvmap2}
                  onChange={handleCheckboxChange}
                  className="mr-2"
                />
                Vereador Engenheiro Coelho
              </label>

              {/* <label>
              <input
                type="checkbox"
                name="areaverdegl"
                checked={checkboxes.areaverdegl}
                onChange={handleCheckboxChange}
              />
              Área Verde
            </label>

            <label>
              <input
                type="checkbox"
                name="municipiogl"
                checked={checkboxes.municipiogl}
                onChange={handleCheckboxChange}
              />
              Município
            </label>

            <label>
              <input
                type="checkbox"
                name="condominiogooglegl"
                checked={checkboxes.condominiogooglegl}
                onChange={handleCheckboxChange}
              />
              Condomínios (Google)
            </label>

            <label>
              <input
                type="checkbox"
                name="condominiograpgl"
                checked={checkboxes.condominiograpgl}
                onChange={handleCheckboxChange}
              />
              Condomínios (Graprohab)
            </label>

            <label>
              <input
                type="checkbox"
                name="rendagl"
                checked={checkboxes.rendagl}
                onChange={handleCheckboxChange}
              />
              Renda
            </label> */}
            </div>
          )}

        {selectedMun &&
          userType === "gen" &&
          selectedMun.value === "Lavras" && (
            <div className="container-items-check">
              <label>
                <input
                  type="checkbox"
                  name="legalizacaolavras"
                  checked={checkboxes.legalizacaolavras}
                  onChange={handleCheckboxChange}
                />
                Legalização
              </label>

              <label>
                <input
                  type="checkbox"
                  name="zoneamentolavras"
                  checked={checkboxes.zoneamentolavras}
                  onChange={handleCheckboxChange}
                />
                Zoneamento
              </label>

              <label>
                <input
                  type="checkbox"
                  name="permissaolavras"
                  checked={checkboxes.permissaolavras}
                  onChange={handleCheckboxChange}
                />
                Permissão
              </label>

              <label>
                <input
                  type="checkbox"
                  name="terrenoslavras"
                  checked={checkboxes.terrenoslavras}
                  onChange={handleCheckboxChange}
                />
                Terrenos
              </label>
              <label>
                <input
                  type="checkbox"
                  name="topografialavras"
                  checked={checkboxes.topografialavras}
                  onChange={handleCheckboxChange}
                />
                Topografia
              </label>

              <label>
                <input
                  type="checkbox"
                  name="applavras"
                  checked={checkboxes.applavras}
                  onChange={handleCheckboxChange}
                />
                APP
              </label>

              <label>
                <input
                  type="checkbox"
                  name="hidrografialavras"
                  checked={checkboxes.hidrografialavras}
                  onChange={handleCheckboxChange}
                />
                Hidrografia
              </label>

              <label>
                <input
                  type="checkbox"
                  name="servidaolavras"
                  checked={checkboxes.servidaolavras}
                  onChange={handleCheckboxChange}
                />
                Servidão
              </label>

              <label>
                <input
                  type="checkbox"
                  name="terrenosinteresselavras"
                  checked={checkboxes.terrenosinteresselavras}
                  onChange={handleCheckboxChange}
                />
                Terrenos de Interesse
              </label>

              <label>
                <input
                  type="checkbox"
                  name="crescimentolavras"
                  checked={checkboxes.crescimentolavras}
                  onChange={handleCheckboxChange}
                />
                Crescimento
              </label>

              <label>
                <input
                  type="checkbox"
                  name="dadossocioslavras"
                  checked={checkboxes.dadossocioslavras}
                  onChange={handleCheckboxChange}
                />
                Dados Socioeconômicos
              </label>

              <label>
                <input
                  type="checkbox"
                  name="distviariolavras"
                  checked={checkboxes.distviariolavras}
                  onChange={handleCheckboxChange}
                />
                Dist. Viário
              </label>

              <label>
                <input
                  type="checkbox"
                  name="disturbanalavras"
                  checked={checkboxes.disturbanalavras}
                  onChange={handleCheckboxChange}
                />
                Dist. Urbana
              </label>

              <label>
                <input
                  type="checkbox"
                  name="pontoslavras"
                  checked={checkboxes.pontoslavras}
                  onChange={handleCheckboxChange}
                />
                Pontos de Interesse
              </label>
            </div>
          )}

        {selectedMun &&
          userType === "gen" &&
          selectedMun.value === "Rio Verde" && (
            <div className="container-items-check">
              <label>
                <input
                  type="checkbox"
                  name="Rio_Verde_1_zoneamento"
                  checked={checkboxes.Rio_Verde_1_zoneamento}
                  onChange={handleCheckboxChange}
                />
                Zoneamento
              </label>

              <label>
                <input
                  type="checkbox"
                  name="Rio_Verde_2_permissao"
                  checked={checkboxes.Rio_Verde_2_permissao}
                  onChange={handleCheckboxChange}
                />
                Permissão
              </label>

              <label>
                <input
                  type="checkbox"
                  name="Rio_Verde_3_terrenos"
                  checked={checkboxes.Rio_Verde_3_terrenos}
                  onChange={handleCheckboxChange}
                />
                Terrenos
              </label>

              <label>
                <input
                  type="checkbox"
                  name="Rio_Verde_4_imoveis_disponiveis"
                  checked={checkboxes.Rio_Verde_4_imoveis_disponiveis}
                  onChange={handleCheckboxChange}
                />
                Imóveis Disponíveis
              </label>
              <label>
                <input
                  type="checkbox"
                  name="Rio_Verde_5_topografia"
                  checked={checkboxes.Rio_Verde_5_topografia}
                  onChange={handleCheckboxChange}
                />
                Topografia
              </label>

              {/* <label>
              <input
                type="checkbox"
                name="Rio_Verde_6_Limites_Ambientais"
                checked={checkboxes.Rio_Verde_6_Limites_Ambientais}
                onChange={handleCheckboxChange}
              />
              Limites Ambientais
            </label> */}

              <label>
                <input
                  type="checkbox"
                  name="Rio_Verde_7_Hidrografia"
                  checked={checkboxes.Rio_Verde_7_Hidrografia}
                  onChange={handleCheckboxChange}
                />
                Hidrografia
              </label>

              <label>
                <input
                  type="checkbox"
                  name="Rio_Verde_8_Servidao"
                  checked={checkboxes.Rio_Verde_8_Servidao}
                  onChange={handleCheckboxChange}
                />
                Servidão
              </label>

              <label>
                <input
                  type="checkbox"
                  name="Rio_Verde_9_terrenos_potenciais"
                  checked={checkboxes.Rio_Verde_9_terrenos_potenciais}
                  onChange={handleCheckboxChange}
                />
                Terrenos Potenciais
              </label>

              <label>
                <input
                  type="checkbox"
                  name="Rio_Verde_10_crescimento"
                  checked={checkboxes.Rio_Verde_10_crescimento}
                  onChange={handleCheckboxChange}
                />
                Crescimento
              </label>

              <label>
                <input
                  type="checkbox"
                  name="Rio_Verde_11_dados_socioeconomicos"
                  checked={checkboxes.Rio_Verde_11_dados_socioeconomicos}
                  onChange={handleCheckboxChange}
                />
                Dados Socioeconômicos
              </label>

              <label>
                <input
                  type="checkbox"
                  name="Rio_verde_12_dist_viario"
                  checked={checkboxes.Rio_verde_12_dist_viario}
                  onChange={handleCheckboxChange}
                />
                Dist. Viário
              </label>

              <label>
                <input
                  type="checkbox"
                  name="Rio_Verde_13_Malha_Urbana"
                  checked={checkboxes.Rio_Verde_13_Malha_Urbana}
                  onChange={handleCheckboxChange}
                />
                Malha Urbana
              </label>

              <label>
                <input
                  type="checkbox"
                  name="Rio_Verde_14_Pontos_de_Interesse"
                  checked={checkboxes.Rio_Verde_14_Pontos_de_Interesse}
                  onChange={handleCheckboxChange}
                />
                Pontos de Interesse
              </label>

              <label>
                <input
                  type="checkbox"
                  name="Rio_Verde"
                  checked={checkboxes.Rio_Verde}
                  onChange={handleCheckboxChange}
                />
                Limite Município
              </label>
            </div>
          )}

        {selectedMun && userType === "miada" && (
          <div className="container-items-check">
            <label>
              <input
                type="checkbox"
                name="rp"
                checked={checkboxes.rp}
                onChange={handleCheckboxChange}
              />
              Ribeirão Preto
            </label>

            <label>
              <input
                type="checkbox"
                name="rp1zoneamento"
                checked={checkboxes.rp1zoneamento}
                onChange={handleCheckboxChange}
              />
              Zoneamento
            </label>

            <label>
              <input
                type="checkbox"
                name="rp3terrenos"
                checked={checkboxes.rp3terrenos}
                onChange={handleCheckboxChange}
              />
              Terrenos
            </label>

            <label>
              <input
                type="checkbox"
                name="rp5topografia"
                checked={checkboxes.rp5topografia}
                onChange={handleCheckboxChange}
              />
              Topografia
            </label>

            <label>
              <input
                type="checkbox"
                name="rp6limitesambientais"
                checked={checkboxes.rp6limitesambientais}
                onChange={handleCheckboxChange}
              />
              Limites Ambientais
            </label>

            <label>
              <input
                type="checkbox"
                name="rp7hidrografia"
                checked={checkboxes.rp7hidrografia}
                onChange={handleCheckboxChange}
              />
              Hidrografia
            </label>

            <label>
              <input
                type="checkbox"
                name="rp8servidao"
                checked={checkboxes.rp8servidao}
                onChange={handleCheckboxChange}
              />
              Servidão
            </label>

            <label>
              <input
                type="checkbox"
                name="rp10crescimento"
                checked={checkboxes.rp10crescimento}
                onChange={handleCheckboxChange}
              />
              Crescimento
            </label>

            <label>
              <input
                type="checkbox"
                name="rp11dadossocioeconomicosrenda"
                checked={checkboxes.rp11dadossocioeconomicosrenda}
                onChange={handleCheckboxChange}
              />
              Dados Socioeconômicos Renda
            </label>
            <label>
              <input
                type="checkbox"
                name="rp12dadossocioeconomicosfamilias"
                checked={checkboxes.rp12dadossocioeconomicosfamilias}
                onChange={handleCheckboxChange}
              />
              Dados Socioeconômicos Famílias
            </label>

            {/* <label>
              <input
                type="checkbox"
                name="areaverdegl"
                checked={checkboxes.areaverdegl}
                onChange={handleCheckboxChange}
              />
              Área Verde
            </label>

            <label>
              <input
                type="checkbox"
                name="municipiogl"
                checked={checkboxes.municipiogl}
                onChange={handleCheckboxChange}
              />
              Município
            </label>

            <label>
              <input
                type="checkbox"
                name="condominiogooglegl"
                checked={checkboxes.condominiogooglegl}
                onChange={handleCheckboxChange}
              />
              Condomínios (Google)
            </label>

            <label>
              <input
                type="checkbox"
                name="condominiograpgl"
                checked={checkboxes.condominiograpgl}
                onChange={handleCheckboxChange}
              />
              Condomínios (Graprohab)
            </label>

            <label>
              <input
                type="checkbox"
                name="rendagl"
                checked={checkboxes.rendagl}
                onChange={handleCheckboxChange}
              />
              Renda
            </label> */}
          </div>
        )}

        {selectedMun && userType === "boibrabo" && (
          <div className="container-items-check">
            <label>
              <input
                type="checkbox"
                name="areaintbb"
                checked={checkboxes.areaintbb}
                className="mr-2"
                onChange={handleCheckboxChange}
              />
              Área de Interesse
            </label>

            <label>
              <input
                type="checkbox"
                name="areapotbb"
                checked={checkboxes.areapotbb}
                className="mr-2"
                onChange={handleCheckboxChange}
              />
              Áreas Potenciais
            </label>

            <label>
              <input
                type="checkbox"
                name="boibrabobb"
                checked={checkboxes.boibrabobb}
                className="mr-2"
                onChange={handleCheckboxChange}
              />
              Boi Brabo - Santo Amaro
            </label>

            <label>
              <input
                type="checkbox"
                name="classesbb"
                checked={checkboxes.classesbb}
                className="mr-2"
                onChange={handleCheckboxChange}
              />
              Classes C e D
            </label>

            <label>
              <input
                type="checkbox"
                name="compsbb"
                checked={checkboxes.compsbb}
                className="mr-2"
                onChange={handleCheckboxChange}
              />
              Competidores
            </label>

            <label>
              <input
                type="checkbox"
                name="conccompsbb"
                checked={checkboxes.conccompsbb}
                className="mr-2"
                onChange={handleCheckboxChange}
              />
              Concentração de Competidores
            </label>

            <label>
              <input
                type="checkbox"
                name="concpfbb"
                checked={checkboxes.concpfbb}
                className="mr-2"
                onChange={handleCheckboxChange}
              />
              Concentração de pontos de fluxo
            </label>

            <label>
              <input
                type="checkbox"
                name="familiasbb"
                checked={checkboxes.familiasbb}
                className="mr-2"
                onChange={handleCheckboxChange}
              />
              Famílias
            </label>

            <label>
              <input
                type="checkbox"
                name="fluxocompsbb"
                checked={checkboxes.fluxocompsbb}
                className="mr-2"
                onChange={handleCheckboxChange}
              />
              Fluxo dos Competidores
            </label>

            <label>
              <input
                type="checkbox"
                name="imoveisbb"
                checked={checkboxes.imoveisbb}
                className="mr-2"
                onChange={handleCheckboxChange}
              />
              Imóveis
            </label>

            <label>
              <input
                type="checkbox"
                name="pontosfluxobb"
                className="mr-2"
                checked={checkboxes.pontosfluxobb}
                onChange={handleCheckboxChange}
              />
              Pontos de Fluxo
            </label>

            <label>
              <input
                type="checkbox"
                name="rendabb"
                className="mr-2"
                checked={checkboxes.rendabb}
                onChange={handleCheckboxChange}
              />
              Renda
            </label>

            <label>
              <input
                type="checkbox"
                name="metrobb"
                checked={checkboxes.metrobb}
                className="mr-2"
                onChange={handleCheckboxChange}
              />
              Transporte público - Metrô
            </label>

            <label>
              <input
                type="checkbox"
                name="onibusbb"
                checked={checkboxes.onibusbb}
                className="mr-2"
                onChange={handleCheckboxChange}
              />
              Transporte público - Ônibus
            </label>

            <label>
              <input
                type="checkbox"
                name="trembb"
                checked={checkboxes.trembb}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              Transporte público - Trem
            </label>
          </div>
        )}

        {selectedMun && userType === "didio" && (
          <div className="container-items-check">
            <label>
              <input
                type="checkbox"
                name="areapotdp"
                checked={checkboxes.areapotdp}
                className="mr-2"
                onChange={handleCheckboxChange}
              />
              Áreas Potenciais Próximas
            </label>

            <label>
              <input
                type="checkbox"
                name="areapotafasdp"
                checked={checkboxes.areapotafasdp}
                className="mr-2"
                onChange={handleCheckboxChange}
              />
              Áreas Potenciais Afastadas
            </label>

            <label>
              <input
                type="checkbox"
                name="imoveisdp"
                checked={checkboxes.imoveisdp}
                className="mr-2"
                onChange={handleCheckboxChange}
              />
              Imóveis Disponíveis
            </label>

            <label>
              <input
                type="checkbox"
                name="compsdp"
                checked={checkboxes.compsdp}
                className="mr-2"
                onChange={handleCheckboxChange}
              />
              Competidores
            </label>

            <label>
              <input
                type="checkbox"
                name="conccompsdp"
                checked={checkboxes.conccompsdp}
                className="mr-2"
                onChange={handleCheckboxChange}
              />
              Concentração de competidores
            </label>

            <label>
              <input
                type="checkbox"
                name="concpontofluxodp"
                checked={checkboxes.concpontofluxodp}
                className="mr-2"
                onChange={handleCheckboxChange}
              />
              Concentração de pontos de fluxo
            </label>

            <label>
              <input
                type="checkbox"
                name="familiasdp"
                checked={checkboxes.familiasdp}
                className="mr-2"
                onChange={handleCheckboxChange}
              />
              Famílias
            </label>

            <label>
              <input
                type="checkbox"
                name="gastodp"
                checked={checkboxes.gastodp}
                className="mr-2"
                onChange={handleCheckboxChange}
              />
              Gastos com alimentação
            </label>

            <label>
              <input
                type="checkbox"
                name="pontofluxodp"
                checked={checkboxes.pontofluxodp}
                className="mr-2"
                onChange={handleCheckboxChange}
              />
              Pontos de fluxo
            </label>

            <label>
              <input
                type="checkbox"
                name="pop20a69dp"
                checked={checkboxes.pop20a69dp}
                className="mr-2"
                onChange={handleCheckboxChange}
              />
              População entre 20 a 69 anos
            </label>

            <label>
              <input
                type="checkbox"
                name="rendadp"
                checked={checkboxes.rendadp}
                className="mr-2"
                onChange={handleCheckboxChange}
              />
              Renda
            </label>
          </div>
        )}

        {selectedMun && userType === "venturehub" && (
          <div className="container-items-check">
            <label>
              <input
                type="checkbox"
                name="campinasvh"
                checked={checkboxes.campinasvh}
                className="mr-2"
                onChange={handleCheckboxChange}
              />
              Campinas
            </label>

            <label>
              <input
                type="checkbox"
                name="ictsvh"
                checked={checkboxes.ictsvh}
                className="mr-2"
                onChange={handleCheckboxChange}
              />
              ICTs
            </label>

            <label>
              <input
                type="checkbox"
                name="ictsagrovh"
                checked={checkboxes.ictsagrovh}
                className="mr-2"
                onChange={handleCheckboxChange}
              />
              ICTs Agro
            </label>

            <label>
              <input
                type="checkbox"
                name="parquesteccampvh"
                checked={checkboxes.parquesteccampvh}
                className="mr-2"
                onChange={handleCheckboxChange}
              />
              Parques Tecnológicos
            </label>

            <label>
              <input
                type="checkbox"
                name="playersagrovh"
                checked={checkboxes.playersagrovh}
                className="mr-2"
                onChange={handleCheckboxChange}
              />
              Players Agro
            </label>
          </div>
        )}

        {selectedMun && userType === "wbgi" && (
          <div className="container-items-check">
            <label>
              <input
                type="checkbox"
                name="appwbgi"
                checked={checkboxes.appwbgi}
                className="mr-2"
                onChange={handleCheckboxChange}
              />
              APP - Área de Preservação Permanente
            </label>

            <label>
              <input
                type="checkbox"
                name="areacontwbgi"
                checked={checkboxes.areacontwbgi}
                className="mr-2"
                onChange={handleCheckboxChange}
              />
              Áreas contaminadas
            </label>

            <label>
              <input
                type="checkbox"
                name="curvasnivelwbgi"
                checked={checkboxes.curvasnivelwbgi}
                className="mr-2"
                onChange={handleCheckboxChange}
              />
              Curvas de nível
            </label>

            <label>
              <input
                type="checkbox"
                name="declividadewbgi"
                checked={checkboxes.declividadewbgi}
                className="mr-2"
                onChange={handleCheckboxChange}
              />
              Declividade
            </label>

            <label>
              <input
                type="checkbox"
                name="distcorposaguawbgi"
                checked={checkboxes.distcorposaguawbgi}
                className="mr-2"
                onChange={handleCheckboxChange}
              />
              Distância de corpos d'água
            </label>

            {/* <label>
              <input
                type="checkbox"
                name="distcorposhidricoswbgi"
                checked={checkboxes.distcorposhidricoswbgi}
                className="mr-2"
                onChange={handleCheckboxChange}
              />
              Distância dos corpos hídricos
            </label> */}

            <label>
              <input
                type="checkbox"
                name="hidrografiawbgi"
                checked={checkboxes.hidrografiawbgi}
                className="mr-2"
                onChange={handleCheckboxChange}
              />
              Hidrografia
            </label>

            <label>
              <input
                type="checkbox"
                name="limeirawbgi"
                checked={checkboxes.limeirawbgi}
                className="mr-2"
                onChange={handleCheckboxChange}
              />
              Limeira
            </label>

            <label>
              <input
                type="checkbox"
                name="reservalegalwbgi"
                checked={checkboxes.reservalegalwbgi}
                className="mr-2"
                onChange={handleCheckboxChange}
              />
              Reserva Legal
            </label>

            <label>
              <input
                type="checkbox"
                name="vegnativawbgi"
                checked={checkboxes.vegnativawbgi}
                className="mr-2"
                onChange={handleCheckboxChange}
              />
              Vegetação Nativa
            </label>

            <label>
              <input
                type="checkbox"
                name="viveiristaswbgi"
                checked={checkboxes.viveiristaswbgi}
                className="mr-2"
                onChange={handleCheckboxChange}
              />
              Viveiristas
            </label>
          </div>
        )}

        {selectedMun &&
          userType === "mrv" &&
          selectedMun.value === "Jundiaí" && (
            <div className="container-items-check">
              <Accordion alwaysOpen className="w-full mb-4">
                <Accordion.Panel>
                  <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                    TERRITÓRIO MUNICIPAL
                  </Accordion.Title>
                  <Accordion.Content className="px-0 py-4 text-sm">
                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="jundiaimrv"
                        checked={checkboxes.jundiaimrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Jundiaí
                    </label>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>

              <Accordion alwaysOpen className="w-full mb-4">
                <Accordion.Panel>
                  <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                    ASPECTOS FÍSICOS
                  </Accordion.Title>
                  <Accordion.Content className="px-0 py-4 text-sm">
                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="appjundiaimrv"
                        checked={checkboxes.appjundiaimrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Áreas de Preservação
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="vegjundiaimrv"
                        checked={checkboxes.vegjundiaimrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Vegetação Nativa
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="resjundiaimrv"
                        checked={checkboxes.resjundiaimrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Reserva Legal
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="areaprojundiaimrv"
                        checked={checkboxes.areaprojundiaimrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Áreas Protegidas de Uso Sustentável
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="nascjundiaimrv"
                        checked={checkboxes.nascjundiaimrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      <p className="">Nascentes</p>
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="cursjundiaimrv"
                        checked={checkboxes.cursjundiaimrv}
                        className="w-3 h-3 "
                        onChange={handleCheckboxChange}
                      />
                      <p className="">Cursos d’ água</p>
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="corposjundiaimrv"
                        checked={checkboxes.corposjundiaimrv}
                        className="w-3 h-3 "
                        onChange={handleCheckboxChange}
                      />
                      <p className="">Corpos d’ água</p>
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="redesdrenajundiaimrv"
                        checked={checkboxes.redesdrenajundiaimrv}
                        className="w-3 h-3 "
                        onChange={handleCheckboxChange}
                      />
                      <p className="">Redes de Drenagem</p>
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="recargahidricajundiaimrv"
                        checked={checkboxes.recargahidricajundiaimrv}
                        className="w-3 h-3 "
                        onChange={handleCheckboxChange}
                      />
                      <p className="">Recarga Hídrica</p>
                    </label>

                    {/* <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="topojundiaimrv"
                        checked={checkboxes.topojundiaimrv}
                        className="w-3 h-3 text-red-500"
                        onChange={handleCheckboxChange}
                      />
                     <p className="text-red-500">Topografia</p> 
                    </label> */}
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>

              <Accordion alwaysOpen className="w-full mb-4">
                <Accordion.Panel>
                  <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                    INFRAESTRUTURA
                  </Accordion.Title>
                  <Accordion.Content className="px-0 py-4 text-sm">
                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="areapoujundiaimrv"
                        checked={checkboxes.areapoujundiaimrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Área Pousio
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="servjundiaimrv"
                        checked={checkboxes.servjundiaimrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Servidão Administrativa
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="usorestjundiaimrv"
                        checked={checkboxes.usorestjundiaimrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Uso restrito
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="areaconsjundiaimrv"
                        checked={checkboxes.areaconsjundiaimrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Área Consolidada
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="areacontjundiaimrv"
                        checked={checkboxes.areacontjundiaimrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Áreas Contaminadas
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="dutojundiaimrv"
                        checked={checkboxes.dutojundiaimrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Dutovias
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="linhaojundiaimrv"
                        checked={checkboxes.linhaojundiaimrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Linhão
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="viasjundiaimrv"
                        checked={checkboxes.viasjundiaimrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Vias
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="viasligajundiaimrv"
                        checked={checkboxes.viasligajundiaimrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Vias de Ligação
                    </label>

                    {/* <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="ferroviasjundiaimrv"
                        checked={checkboxes.ferroviasjundiaimrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Ferrovias
                    </label> */}

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="equipamentosjundiaimrv"
                        checked={checkboxes.equipamentosjundiaimrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Equipamentos de Educação, Esporte, Saúde e Assistência
                      Social e Abairramento
                    </label>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>

              <Accordion alwaysOpen className="w-full mb-4">
                <Accordion.Panel>
                  <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                    ASPECTOS LEGAIS
                  </Accordion.Title>
                  <Accordion.Content className="px-0 py-4 text-sm">
                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="zoneajundiaimrv"
                        checked={checkboxes.zoneajundiaimrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Zoneamento municipal
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="legavertjundiaimrv"
                        checked={checkboxes.legavertjundiaimrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Legalização (Uso Vertical)
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="legahorjundiaimrv"
                        checked={checkboxes.legahorjundiaimrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Legalização (Uso Horizontal)
                    </label>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>

              <Accordion alwaysOpen className="w-full mb-4">
                <Accordion.Panel>
                  <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                    ASPECTOS SOCIOECONÔMICOS
                  </Accordion.Title>
                  <Accordion.Content className="px-0 py-4 text-sm">
                    {/* <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="rendapopjundiaimrv"
                        checked={checkboxes.rendapopjundiaimrv}
                        className="w-3 h-3 text-red-500"
                        onChange={handleCheckboxChange}
                      />
                      <p className="text-red-500">Renda vs População</p>
                    </label> */}

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="rendajundiaimrv"
                        checked={checkboxes.rendajundiaimrv}
                        className="w-3 h-3 "
                        onChange={handleCheckboxChange}
                      />
                      <p className="">Renda</p>
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="popjundiaimrv"
                        checked={checkboxes.popjundiaimrv}
                        className="w-3 h-3 "
                        onChange={handleCheckboxChange}
                      />
                      <p className="">População</p>
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="domicjundiaimrv"
                        checked={checkboxes.domicjundiaimrv}
                        className="w-3 h-3 "
                        onChange={handleCheckboxChange}
                      />
                      <p className="">Domicílios</p>
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="domicalujundiaimrv"
                        checked={checkboxes.domicalujundiaimrv}
                        className="w-3 h-3 "
                        onChange={handleCheckboxChange}
                      />
                      <p className="">Domicílios de aluguel</p>
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="expurbjundiaimrv"
                        checked={checkboxes.expurbjundiaimrv}
                        className="w-3 h-3 "
                        onChange={handleCheckboxChange}
                      />
                      <p className="">Expansão Urbana</p>
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="veloprecojundiaimrv"
                        checked={checkboxes.veloprecojundiaimrv}
                        className="w-3 h-3 "
                        onChange={handleCheckboxChange}
                      />
                      <p className="">Velocidade e Preço Médio</p>
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="taxahabjundiaimrv"
                        checked={checkboxes.taxahabjundiaimrv}
                        className="w-3 h-3 "
                        onChange={handleCheckboxChange}
                      />
                      <p className="">Taxa Habitacional</p>
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="graprohabjundiaimrv"
                        checked={checkboxes.graprohabjundiaimrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Graprohab
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="areaurbjundiaimrv"
                        checked={checkboxes.areaurbjundiaimrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Área urbanizada
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="arearujundiaimrv"
                        checked={checkboxes.arearujundiaimrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Áreas Rurais Cadastradas
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="vaziosjundiaimrv"
                        checked={checkboxes.vaziosjundiaimrv}
                        className="w-3 h-3 "
                        onChange={handleCheckboxChange}
                      />
                      <p className="">Vazios Urbanos</p>
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="consumoaguajundiaimrv"
                        checked={checkboxes.consumoaguajundiaimrv}
                        className="w-3 h-3 "
                        onChange={handleCheckboxChange}
                      />
                      <p className="">Consumo de Água por Bairro</p>
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="dispaguajundiaimrv"
                        checked={checkboxes.dispaguajundiaimrv}
                        className="w-3 h-3 "
                        onChange={handleCheckboxChange}
                      />
                      <p className="">Dispêndio de Água por Bairro</p>
                    </label>

                    {/* <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="custojundiaimrv"
                        checked={checkboxes.custojundiaimrv}
                        className="w-3 h-3 text-red-500"
                        onChange={handleCheckboxChange}
                      />
                     <p className="text-red-500">Custo de projeto (m²) e padrão de acabamento por setor do
                      Plano Diretor</p> 
                    </label> */}
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>

              <Accordion alwaysOpen className="w-full mb-4">
                <Accordion.Panel>
                  <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                    TERRENOS
                  </Accordion.Title>
                  <Accordion.Content className="px-0 py-4 text-sm">
                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="terrenosjundiaimrv"
                        checked={checkboxes.terrenosjundiaimrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      <p className="">Terrenos Anunciados</p>
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="terrenoslivre"
                        checked={checkboxes.terrenoslivre}
                        className="w-3 h-3 text-[#fad414]"
                        onChange={handleCheckboxChange}
                      />
                      Parceiro Terreno Livre
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="rankingterjundiaimrv"
                        checked={checkboxes.rankingterjundiaimrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      <p className="">Ranking Terrenos</p>
                    </label>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>

              <Accordion alwaysOpen className="w-full mb-4">
                <Accordion.Panel>
                  <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                    PONTOS DE INTERESSE
                  </Accordion.Title>
                  <Accordion.Content className="px-0 py-4 text-sm">
                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="pontoajundiaimrv"
                        checked={checkboxes.pontoajundiaimrv}
                        className="w-3 h-3 "
                        onChange={handleCheckboxChange}
                      />
                      <p className="">Pontos Atratores</p>
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="pontodjundiaimrv"
                        checked={checkboxes.pontodjundiaimrv}
                        className="w-3 h-3 "
                        onChange={handleCheckboxChange}
                      />
                      <p className="">Pontos Detratores</p>
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="poluicaosonorajundiaimrv"
                        checked={checkboxes.poluicaosonorajundiaimrv}
                        className="w-3 h-3 "
                        onChange={handleCheckboxChange}
                      />
                      <p className="">Poluição Sonora</p>
                    </label>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>
            </div>
          )}

        {selectedMun &&
          userType === "mrv" &&
          selectedMun.value === "Limeira" && (
            <div className="container-items-check">
              <Accordion alwaysOpen className="w-full mb-4">
                <Accordion.Panel>
                  <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                    TERRITÓRIO MUNICIPAL
                  </Accordion.Title>
                  <Accordion.Content className="px-0 py-4 text-sm">
                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="limeiramrv"
                        checked={checkboxes.limeiramrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Limeira
                    </label>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>

              <Accordion alwaysOpen className="w-full mb-4">
                <Accordion.Panel>
                  <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                    ASPECTOS FÍSICOS
                  </Accordion.Title>
                  <Accordion.Content className="px-0 py-4 text-sm">
                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="applimeiramrv"
                        checked={checkboxes.applimeiramrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Áreas de Preservação
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="veglimeiramrv"
                        checked={checkboxes.veglimeiramrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Vegetação Nativa
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="reslimeiramrv"
                        checked={checkboxes.reslimeiramrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Reserva Legal
                    </label>

                    {/* <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="areaprolimeiramrv"
                        checked={checkboxes.areaprolimeiramrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Áreas Protegidas de Uso Sustentável
                    </label> */}

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="nasclimeiramrv"
                        checked={checkboxes.nasclimeiramrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Nascentes
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="curslimeiramrv"
                        checked={checkboxes.curslimeiramrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Cursos d’ água
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="corposlimeiramrv"
                        checked={checkboxes.corposlimeiramrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Corpos d’ água
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="topolimeiramrv"
                        checked={checkboxes.topolimeiramrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Topografia
                    </label>

                    {/* <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="redesdrenalimeiramrv"
                        checked={checkboxes.redesdrenalimeiramrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Redes de Drenagem
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="recargahidricalimeiramrv"
                        checked={checkboxes.recargahidricalimeiramrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Recarga Hídrica
                    </label> */}
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>

              <Accordion alwaysOpen className="w-full mb-4">
                <Accordion.Panel>
                  <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                    INFRAESTRUTURA
                  </Accordion.Title>
                  <Accordion.Content className="px-0 py-4 text-sm">
                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="areapoulimeiramrv"
                        checked={checkboxes.areapoulimeiramrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Área Pousio
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="servlimeiramrv"
                        checked={checkboxes.servlimeiramrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Servidão Administrativa
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="usorestlimeiramrv"
                        checked={checkboxes.usorestlimeiramrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Uso restrito
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="areaconslimeiramrv"
                        checked={checkboxes.areaconslimeiramrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Área Consolidada
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="areacontlimeiramrv"
                        checked={checkboxes.areacontlimeiramrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Áreas Contaminadas
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="dutolimeiramrv"
                        checked={checkboxes.dutolimeiramrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Dutovias
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="linhaolimeiramrv"
                        checked={checkboxes.linhaolimeiramrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Linhão
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="viaslimeiramrv"
                        checked={checkboxes.viaslimeiramrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Vias
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="viasligalimeiramrv"
                        checked={checkboxes.viasligalimeiramrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Vias de Ligação
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="ferroviaslimeiramrv"
                        checked={checkboxes.ferroviaslimeiramrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Ferrovias
                    </label>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>

              <Accordion alwaysOpen className="w-full mb-4">
                <Accordion.Panel>
                  <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                    ASPECTOS LEGAIS
                  </Accordion.Title>
                  <Accordion.Content className="px-0 py-4 text-sm">
                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="zonealimeiramrv"
                        checked={checkboxes.zonealimeiramrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Zoneamento municipal
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="legavertlimeiramrv"
                        checked={checkboxes.legavertlimeiramrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Legalização (Uso Vertical)
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="legahorlimeiramrv"
                        checked={checkboxes.legahorlimeiramrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Legalização (Uso Horizontal)
                    </label>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>

              <Accordion alwaysOpen className="w-full mb-4">
                <Accordion.Panel>
                  <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                    ASPECTOS SOCIOECONÔMICOS
                  </Accordion.Title>
                  <Accordion.Content className="px-0 py-4 text-sm">
                    {/* <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="rendapoplimeiramrv"
                        checked={checkboxes.rendapoplimeiramrv}
                        className="w-3 h-3 text-red-500"
                        onChange={handleCheckboxChange}
                      />
                      <p className="text-red-500">Renda vs População</p>
                    </label> */}

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="areapotlimeiramrv"
                        checked={checkboxes.areapotlimeiramrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Áreas Potenciais
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="rendalimeiramrv"
                        checked={checkboxes.rendalimeiramrv}
                        className="w-3 h-3 "
                        onChange={handleCheckboxChange}
                      />
                      <p className="">Renda</p>
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="poplimeiramrv"
                        checked={checkboxes.poplimeiramrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      População
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="domiclimeiramrv"
                        checked={checkboxes.domiclimeiramrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Domicílios
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="domicalulimeiramrv"
                        checked={checkboxes.domicalulimeiramrv}
                        className="w-3 h-3 "
                        onChange={handleCheckboxChange}
                      />
                      <p className="">Domicílios de aluguel</p>
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="expurblimeiramrv"
                        checked={checkboxes.expurblimeiramrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Expansão Urbana
                    </label>

                    {/* <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="veloprecolimeiramrv"
                        checked={checkboxes.veloprecolimeiramrv}
                        className="w-3 h-3 text-red-500"
                        onChange={handleCheckboxChange}
                      />
                     <p className="text-red-500">Velocidade e Preço Médio</p> 
                    </label> */}

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="taxahablimeiramrv"
                        checked={checkboxes.taxahablimeiramrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Taxa Habitacional
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="graprohablimeiramrv"
                        checked={checkboxes.graprohablimeiramrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Graprohab
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="areaurblimeiramrv"
                        checked={checkboxes.areaurblimeiramrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Área urbanizada
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="arearulimeiramrv"
                        checked={checkboxes.arearulimeiramrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Áreas Rurais Cadastradas
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="vazioslimeiramrv"
                        checked={checkboxes.vazioslimeiramrv}
                        className="w-3 h-3 "
                        onChange={handleCheckboxChange}
                      />
                      <p className="">Vazios Urbanos</p>
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="consumoagualimeiramrv"
                        checked={checkboxes.consumoagualimeiramrv}
                        className="w-3 h-3 "
                        onChange={handleCheckboxChange}
                      />
                      <p className="">Consumo de Água por Bairro</p>
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="dispagualimeiramrv"
                        checked={checkboxes.dispagualimeiramrv}
                        className="w-3 h-3 "
                        onChange={handleCheckboxChange}
                      />
                      <p className="">Dispêndio de Água por Bairro</p>
                    </label>

                    {/* <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="custolimeiramrv"
                        checked={checkboxes.custolimeiramrv}
                        className="w-3 h-3 text-red-500"
                        onChange={handleCheckboxChange}
                      />
                      <p className="text-red-500">Custo de projeto (m²) e padrão de acabamento por setor do
                      Plano Diretor</p>
                    </label> */}
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>

              <Accordion alwaysOpen className="w-full mb-4">
                <Accordion.Panel>
                  <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                    TERRENOS
                  </Accordion.Title>
                  <Accordion.Content className="px-0 py-4 text-sm">
                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="terrenoslimeiramrv"
                        checked={checkboxes.terrenoslimeiramrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Terrenos Anunciados
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="terrenoslivre"
                        checked={checkboxes.terrenoslivre}
                        className="w-3 h-3  text-[#fad414] focus:outline-[#fad414]"
                        onChange={handleCheckboxChange}
                      />
                      Parceiro Terreno Livre
                    </label>

                    {/* <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="rankingterlimeiramrv"
                        checked={checkboxes.rankingterlimeiramrv}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Ranking de Terrenos
                    </label> */}
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>

              <Accordion alwaysOpen className="w-full mb-4">
                <Accordion.Panel>
                  <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                    PONTOS DE INTERESSE
                  </Accordion.Title>
                  <Accordion.Content className="px-0 py-4 text-sm">
                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="pontoalimeiramrv"
                        checked={checkboxes.pontoalimeiramrv}
                        className="w-3 h-3 "
                        onChange={handleCheckboxChange}
                      />
                      <p className="">Pontos Atratores</p>
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="pontodlimeiramrv"
                        checked={checkboxes.pontodlimeiramrv}
                        className="w-3 h-3 "
                        onChange={handleCheckboxChange}
                      />
                      <p className="">Pontos Detratores</p>
                    </label>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>
            </div>
          )}

        {selectedMun &&
          userType === "circulagem" &&
          selectedMun.value === "Camaçari" && (
            <div className="container-items-check">
              <Accordion alwaysOpen className="w-full mb-4">
                <Accordion.Panel>
                  <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                    Galpões
                  </Accordion.Title>
                  <Accordion.Content className="px-0 py-4 text-sm">
                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="gtdcamacircu"
                        checked={checkboxes.gtdcamacircu}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Galpões e Terrenos Disponíveis
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="distcamacircu"
                        checked={checkboxes.distcamacircu}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Distância das Associações e Cooper...
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="arearedcamacircu"
                        checked={checkboxes.arearedcamacircu}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Área Residencial
                    </label>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>
              <div className="px-0 py-0 text-sm pl-4">
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="areaspotcamacircu"
                    checked={checkboxes.areaspotcamacircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Áreas Potenciais
                </label>

                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="assocamacircu"
                    checked={checkboxes.assocamacircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Associações e Cooperativas
                </label>

                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="camacircu"
                    checked={checkboxes.camacircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Camaçari
                </label>

                <label className="flex items-center gap-2 pl-4">
                  <input
                    type="checkbox"
                    name="correcondcamacircu"
                    checked={checkboxes.correcondcamacircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Corretoras e Condomínios
                </label>

                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="empscamacircu"
                    checked={checkboxes.empscamacircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Empresas geradoras de resíduos
                </label>
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="concempcamacircu"
                    checked={checkboxes.concempcamacircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Concen. Empresas geradoras de resíduos
                </label>
              </div>
            </div>
          )}

        {selectedMun &&
          userType === "circulagem" &&
          selectedMun.value === "Varginha" && (
            <div className="container-items-check">
              <div className="px-0 py-0 text-sm pl-4">
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="areaspotvargcircu"
                    checked={checkboxes.areaspotvargcircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Áreas Potenciais
                </label>

                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="assovargcircu"
                    checked={checkboxes.assovargcircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Associações e Cooperativas
                </label>

                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="correcondvargcircu"
                    checked={checkboxes.correcondvargcircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Corretoras e Condomínios
                </label>

                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="vargcircu"
                    checked={checkboxes.vargcircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Varginha
                </label>

                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="empsvargcircu"
                    checked={checkboxes.empsvargcircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Empresas geradoras de resíduos
                </label>
              </div>
            </div>
          )}

        {selectedMun &&
          userType === "circulagem" &&
          selectedMun.value === "Campinas" && (
            <div className="container-items-check">
              <Accordion alwaysOpen className="w-full mb-4">
                <Accordion.Panel>
                  <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                    Galpões
                  </Accordion.Title>
                  <Accordion.Content className="px-0 py-4 text-sm">
                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="gtdcampcircu"
                        checked={checkboxes.gtdcampcircu}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Galpões e Terrenos Disponíveis
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="distcampcircu"
                        checked={checkboxes.distcampcircu}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Distância das Associações e Cooper...
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="distecocampcircu"
                        checked={checkboxes.distecocampcircu}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Distância dos Ecopontos
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="arearedcampcircu"
                        checked={checkboxes.arearedcampcircu}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Área Residencial
                    </label>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>
              <div className="px-0 py-0 text-sm pl-4">
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="areaspotcampcircu"
                    checked={checkboxes.areaspotcampcircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Áreas Potenciais
                </label>

                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="assocampcircu"
                    checked={checkboxes.assocampcircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Associações e Cooperativas
                </label>
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="boticampcircu"
                    checked={checkboxes.boticampcircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  O Boticário
                </label>
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="campcircu"
                    checked={checkboxes.campcircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Campinas
                </label>

                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="correcondcampcircu"
                    checked={checkboxes.correcondcampcircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Corretoras e Condomínios
                </label>
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="empscampcircu"
                    checked={checkboxes.empscampcircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Empresas geradoras de resíduos
                </label>

                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="densempscampcircu"
                    checked={checkboxes.densempscampcircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Densid. Empresas geradoras de resíduos
                </label>
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="ecoscampcircu"
                    checked={checkboxes.ecoscampcircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Ecopontos
                </label>
              </div>
            </div>
          )}
        {selectedMun &&
          userType === "circulagem" &&
          selectedMun.value === "Jundiaí" && (
            <div className="container-items-check">
              <Accordion alwaysOpen className="w-full mb-4">
                <Accordion.Panel>
                  <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                    Galpões
                  </Accordion.Title>
                  <Accordion.Content className="px-0 py-4 text-sm">
                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="gtdjundcircu"
                        checked={checkboxes.gtdjundcircu}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Galpões e Terrenos Disponíveis
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="distjundcircu"
                        checked={checkboxes.distjundcircu}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Distância das Associações e Cooper...
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="distecojundcircu"
                        checked={checkboxes.distecojundcircu}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Distância dos Ecopontos
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="arearedjundcircu"
                        checked={checkboxes.arearedjundcircu}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Área Residencial
                    </label>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>
              <div className="px-0 py-0 text-sm pl-4">
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="areaspotjundcircu"
                    checked={checkboxes.areaspotjundcircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Áreas Potenciais
                </label>

                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="assojundcircu"
                    checked={checkboxes.assojundcircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Associações e Cooperativas
                </label>

                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="botijundcircu"
                    checked={checkboxes.botijundcircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  O Boticário
                </label>

                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="correcondjundcircu"
                    checked={checkboxes.correcondjundcircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Corretoras e Condomínios
                </label>

                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="jundcircu"
                    checked={checkboxes.jundcircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Jundiaí
                </label>

                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="empsjundcircu"
                    checked={checkboxes.empsjundcircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Empresas geradoras de resíduos
                </label>

                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="densempsjundcircu"
                    checked={checkboxes.densempsjundcircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Densid. Empresas geradoras de resíduos
                </label>
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="ecosjundcircu"
                    checked={checkboxes.ecosjundcircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Ecopontos
                </label>
              </div>
            </div>
          )}

        {selectedMun &&
          userType === "circulagem" &&
          selectedMun.value === "Sorocaba" && (
            <div className="container-items-check">
              <Accordion alwaysOpen className="w-full mb-4">
                <Accordion.Panel>
                  <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                    Galpões
                  </Accordion.Title>
                  <Accordion.Content className="px-0 py-4 text-sm">
                    <label className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        name="correcondsorocircu"
                        checked={checkboxes.correcondsorocircu}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Corretoras e Condomínios
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="gtdsorocircu"
                        checked={checkboxes.gtdsorocircu}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Galpões e Terrenos Disponíveis
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="distsorocircu"
                        checked={checkboxes.distsorocircu}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Distância das Associações e Cooper...
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="distecosorocircu"
                        checked={checkboxes.distecosorocircu}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Distância dos Ecopontos
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="arearedsorocircu"
                        checked={checkboxes.arearedsorocircu}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Área Residencial
                    </label>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>
              <div className="px-0 py-0 text-sm pl-4">
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="areaspotsorocircu"
                    checked={checkboxes.areaspotsorocircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Áreas Potenciais
                </label>

                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="assosorocircu"
                    checked={checkboxes.assosorocircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Associações e Cooperativas
                </label>

                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="botisorocircu"
                    checked={checkboxes.botisorocircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  O Boticário
                </label>

                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="sorocircu"
                    checked={checkboxes.sorocircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Sorocaba
                </label>

                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="empssorocircu"
                    checked={checkboxes.empssorocircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Empresas geradoras de resíduos
                </label>

                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="densempssorocircu"
                    checked={checkboxes.densempssorocircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Densid. Empresas geradoras de resíduos
                </label>
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="ecossorocircu"
                    checked={checkboxes.ecossorocircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Ecopontos
                </label>
              </div>
            </div>
          )}

        {selectedMun &&
          userType === "circulagem" &&
          selectedMun.value === "Grande São Paulo" && (
            <div className="container-items-check">
              <div className="px-0 py-0 text-sm pl-4">
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="grandespcircu"
                    checked={checkboxes.grandespcircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Grande São Paulo
                </label>
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="botigrandespcircu"
                    checked={checkboxes.botigrandespcircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  O Boticário
                </label>
              </div>
            </div>
          )}

        {selectedMun &&
          userType === "circulagem" &&
          selectedMun.value === "Ipojuca" && (
            <div className="container-items-check">
              <Accordion alwaysOpen className="w-full mb-4">
                <Accordion.Panel>
                  <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                    Galpões
                  </Accordion.Title>
                  <Accordion.Content className="px-0 py-4 text-sm">
                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="gtdipocircu"
                        checked={checkboxes.gtdipocircu}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Galpões Disponíveis
                    </label>

                    <label className="flex items-center gap-2 pl-4">
                      <input
                        type="checkbox"
                        name="arearedipocircu"
                        checked={checkboxes.arearedipocircu}
                        className="w-3 h-3"
                        onChange={handleCheckboxChange}
                      />
                      Área Residencial
                    </label>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>
              <div className="px-0 py-0 text-sm pl-4">
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="areaspotipocircu"
                    checked={checkboxes.areaspotipocircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Áreas Potenciais
                </label>
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="correcondipocircu"
                    checked={checkboxes.correcondipocircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Corretoras e Condomínios
                </label>

                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="coopreciipocircu"
                    checked={checkboxes.coopreciipocircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Cooperativas de Reciclagem
                </label>

                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="ipocircu"
                    checked={checkboxes.ipocircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Ipojuca
                </label>

                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="empsipocircu"
                    checked={checkboxes.empsipocircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Empresas geradoras de resíduos
                </label>

                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="densempsipocircu"
                    checked={checkboxes.densempsipocircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Densid. Empresas geradoras de resíduos
                </label>
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="ecosipocircu"
                    checked={checkboxes.ecosipocircu}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Ecopontos
                </label>
              </div>
            </div>
          )}

        {selectedMun &&
          userType === "investcorp" &&
          selectedMun.value === "Case 1" && (
            <div className="container-items-check">
              <div className="px-0 py-0 text-sm pl-4">
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="imoveisc1ic"
                    checked={checkboxes.imoveisc1ic}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Imóveis
                </label>

                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="raioc1ic"
                    checked={checkboxes.raioc1ic}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Raio de pesquisa (1,5km do ponto de referência)
                </label>
              </div>
            </div>
          )}

        {selectedMun &&
          userType === "investcorp" &&
          selectedMun.value === "Case 2" && (
            <div className="container-items-check">
              <div className="px-0 py-0 text-sm pl-4">
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="areaestudoc2ic"
                    checked={checkboxes.areaestudoc2ic}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Área de estudo
                </label>

                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="rendac2ic"
                    checked={checkboxes.rendac2ic}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Renda
                </label>

                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="recortec2ic"
                    checked={checkboxes.recortec2ic}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Recorte de classe A e B
                </label>

                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="pontosfluxc2ic"
                    checked={checkboxes.pontosfluxc2ic}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Pontos de Fluxo
                </label>

                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="concfluxoc2ic"
                    checked={checkboxes.concfluxoc2ic}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Concentração de Fluxo
                </label>

                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="oticasc2ic"
                    checked={checkboxes.oticasc2ic}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Óticas
                </label>

                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="concfluxooticasc2ic"
                    checked={checkboxes.concfluxooticasc2ic}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Concentração de Fluxo em Óticas
                </label>

                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="imoveisc2ic"
                    checked={checkboxes.imoveisc2ic}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Imóveis
                </label>

                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="shoppingsc2ic"
                    checked={checkboxes.shoppingsc2ic}
                    className="w-3 h-3"
                    onChange={handleCheckboxChange}
                  />
                  Shoppings e Prédios Comerciais
                </label>
              </div>
            </div>
          )}

        {selectedMun &&
          userType === "investcorp" &&
          selectedMun.value === "Macapá" && (
            <div className="container-items-check">
              <Accordion alwaysOpen className="w-full mb-4">
                <Accordion.Panel>
                  <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                    Aspectos Físicos
                  </Accordion.Title>
                  <Accordion.Content className="px-0 py-4">
                    {/* <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="limitemacapaic"
                      checked={checkboxes.limitemacapaic}
                      onChange={handleCheckboxChange}
                    />
                    Limite Municipal
                  </label> */}

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="recortemacapaic"
                        checked={checkboxes.recortemacapaic}
                        onChange={handleCheckboxChange}
                      />
                      Recorte da área de estudo
                    </label>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>

              <Accordion alwaysOpen className="w-full mb-4">
                <Accordion.Panel>
                  <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                    Pontos de Interesse
                  </Accordion.Title>
                  <Accordion.Content className="px-0 py-4">
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="varejomacapaic"
                        checked={checkboxes.varejomacapaic}
                        onChange={handleCheckboxChange}
                      />
                      Varejo (Geral)
                    </label>

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="concvarejomacapaic"
                        checked={checkboxes.concvarejomacapaic}
                        onChange={handleCheckboxChange}
                      />
                      Concentração do varejo
                    </label>

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="concfluxomacapaic"
                        checked={checkboxes.concfluxomacapaic}
                        onChange={handleCheckboxChange}
                      />
                      Concentração de fluxo no varejo
                    </label>

                    {/* <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="shoppingsmacapaic"
                      checked={checkboxes.shoppingsmacapaic}
                      onChange={handleCheckboxChange}
                    />
                    Shoppings
                  </label> */}

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="escolasmacapaic"
                        checked={checkboxes.escolasmacapaic}
                        onChange={handleCheckboxChange}
                      />
                      Escolas
                    </label>

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="hospitaismacapaic"
                        checked={checkboxes.hospitaismacapaic}
                        onChange={handleCheckboxChange}
                      />
                      Hospitais
                    </label>

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="estacionamentosmacapaic"
                        checked={checkboxes.estacionamentosmacapaic}
                        onChange={handleCheckboxChange}
                      />
                      Estacionamentos
                    </label>

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="concorrentesmacapaic"
                        checked={checkboxes.concorrentesmacapaic}
                        onChange={handleCheckboxChange}
                      />
                      Concorrentes (bancos)
                    </label>

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="universidadesmacapaic"
                        checked={checkboxes.universidadesmacapaic}
                        onChange={handleCheckboxChange}
                      />
                      Universidades
                    </label>

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="assaimacapaic"
                        checked={checkboxes.assaimacapaic}
                        onChange={handleCheckboxChange}
                      />
                      Assaí
                    </label>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>

              <Accordion alwaysOpen className="w-full mb-4">
                <Accordion.Panel>
                  <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                    Aspectos Socioeconômicos
                  </Accordion.Title>
                  <Accordion.Content className="px-0 py-4">
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="rendamacapaic"
                        checked={checkboxes.rendamacapaic}
                        onChange={handleCheckboxChange}
                      />
                      Renda
                    </label>

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="domiciliosmacapaic"
                        checked={checkboxes.domiciliosmacapaic}
                        onChange={handleCheckboxChange}
                      />
                      Domicílios
                    </label>

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="popmacapaic"
                        checked={checkboxes.popmacapaic}
                        onChange={handleCheckboxChange}
                      />
                      População
                    </label>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>

              <Accordion alwaysOpen className="w-full mb-4">
                <Accordion.Panel>
                  <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                    Terrenos
                  </Accordion.Title>
                  <Accordion.Content className="px-0 py-4">
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="imoveismacapaic"
                        checked={checkboxes.imoveismacapaic}
                        onChange={handleCheckboxChange}
                      />
                      Imóveis anunciados
                    </label>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>
            </div>
          )}

        {selectedMun && userType === "brz" && (
          <div className="container-items-check">
            {/* <label className="flex items-center gap-2 px-6 text-sm">
              <input
                type="checkbox"
                name="terrenoslivre"
                checked={checkboxes.terrenoslivre}
                className="w-3 h-3"
                onChange={handleCheckboxChange}
              />
              Terrenos (Terreno Livre)
            </label> */}

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  TERRITÓRIO MUNICIPAL
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4 text-sm">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="lavrasbrz"
                      checked={checkboxes.lavrasbrz}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Lavras
                  </label>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  BRZ
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4 text-sm">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="empreendimentosbrz"
                      checked={checkboxes.empreendimentosbrz}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Empreendimentos BRZ
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="raio15brz"
                      checked={checkboxes.raio15brz}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Raio de 1.5km
                  </label>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  ASPECTOS FÍSICOS
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4 text-sm">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="topografialavras"
                      checked={checkboxes.topografialavras}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Topografia
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="hidrografialavras"
                      checked={checkboxes.hidrografialavras}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Hidrografia
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="applavras"
                      checked={checkboxes.applavras}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    APP
                  </label>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  ASPECTOS LEGAIS
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4 text-sm">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="zoneamentolavras"
                      checked={checkboxes.zoneamentolavras}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Zoneamento
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="permissaolavras"
                      checked={checkboxes.permissaolavras}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Permissão
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="legalizacaolavras"
                      checked={checkboxes.legalizacaolavras}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Legalização
                  </label>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  ASPECTOS SOCIOECONÔMICOS
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4 text-sm">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="rendabrz"
                      checked={checkboxes.rendabrz}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Renda
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="popbrz"
                      checked={checkboxes.popbrz}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    População
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="domicioliosbrz"
                      checked={checkboxes.domicioliosbrz}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Domicílios
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="domiciliosaluguelbrz"
                      checked={checkboxes.domiciliosaluguelbrz}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Domicílios de Aluguel
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="crescimentobrz"
                      checked={checkboxes.crescimentobrz}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Vetor de Crescimento
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="crescimento1brz"
                      checked={checkboxes.crescimento1brz}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Crescimento
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="deficitbrz"
                      checked={checkboxes.deficitbrz}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Déficit Habitacional
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="taxahabbrz"
                      checked={checkboxes.taxahabbrz}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Taxa Habitacional
                  </label>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  TERRENOS
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4 text-sm">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="distviariolavras"
                      checked={checkboxes.distviariolavras}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Dist. Viário
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="disturbanalavras"
                      checked={checkboxes.disturbanalavras}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Dist. Urbana
                  </label>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  PONTOS DE INTERESSE
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4 text-sm">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="pontointbrz"
                      checked={checkboxes.pontointbrz}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Pontos de interesse
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="servidaolavras"
                      checked={checkboxes.servidaolavras}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Servidão
                  </label>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>
          </div>
        )}

        {selectedMun &&
          userType === "sucao" &&
          selectedMun.value === "Salto" && (
            <div className="container-items-check">
              <label>
                <input
                  type="checkbox"
                  name="lmsaltosc"
                  checked={checkboxes.lmsaltosc}
                  className="mr-2"
                  onChange={handleCheckboxChange}
                />
                Limite Municipal
              </label>

              <label>
                <input
                  type="checkbox"
                  name="compsaltosc"
                  checked={checkboxes.compsaltosc}
                  className="mr-2"
                  onChange={handleCheckboxChange}
                />
                Competidores
              </label>

              <label>
                <input
                  type="checkbox"
                  name="dadossociosaltossc"
                  checked={checkboxes.dadossociosaltossc}
                  className="mr-2"
                  onChange={handleCheckboxChange}
                />
                Dados Socioeconômicos
              </label>

              <label>
                <input
                  type="checkbox"
                  name="r1saltosc"
                  checked={checkboxes.r1saltosc}
                  className="mr-2"
                  onChange={handleCheckboxChange}
                />
                Raio de 1km Sucão
              </label>

              <label>
                <input
                  type="checkbox"
                  name="r2saltosc"
                  checked={checkboxes.r2saltosc}
                  className="mr-2"
                  onChange={handleCheckboxChange}
                />
                Raio de 2km Sucão
              </label>

              <label>
                <input
                  type="checkbox"
                  name="pfsaltosc"
                  checked={checkboxes.pfsaltosc}
                  className="mr-2"
                  onChange={handleCheckboxChange}
                />
                Pontos de Fluxo
              </label>
              <label>
                <input
                  type="checkbox"
                  name="kernelsaltosc"
                  checked={checkboxes.kernelsaltosc}
                  className="mr-2"
                  onChange={handleCheckboxChange}
                />
                Kernel
              </label>
              <label>
                <input
                  type="checkbox"
                  name="scsaltosc"
                  checked={checkboxes.scsaltosc}
                  className="mr-2"
                  onChange={handleCheckboxChange}
                />
                Sucão
              </label>
            </div>
          )}

        {selectedMun &&
          userType === "sucao" &&
          selectedMun.value === "Jardim Marajoara" && (
            <div className="container-items-check">
              <label>
                <input
                  type="checkbox"
                  name="lmmarasc"
                  checked={checkboxes.lmmarasc}
                  className="mr-2"
                  onChange={handleCheckboxChange}
                />
                Limite Municipal
              </label>

              <label>
                <input
                  type="checkbox"
                  name="compmarasc"
                  checked={checkboxes.compmarasc}
                  className="mr-2"
                  onChange={handleCheckboxChange}
                />
                Competidores
              </label>

              <label>
                <input
                  type="checkbox"
                  name="buffermarasc"
                  checked={checkboxes.buffermarasc}
                  className="mr-2"
                  onChange={handleCheckboxChange}
                />
                Buffer
              </label>

              <label>
                <input
                  type="checkbox"
                  name="fluxo1marajasc"
                  checked={checkboxes.fluxo1marajasc}
                  className="mr-2"
                  onChange={handleCheckboxChange}
                />
                Fluxo
              </label>

              <label>
                <input
                  type="checkbox"
                  name="kernelfluxo1marajasc"
                  checked={checkboxes.kernelfluxo1marajasc}
                  className="mr-2"
                  onChange={handleCheckboxChange}
                />
                Kernel Fluxo
              </label>

              <label>
                <input
                  type="checkbox"
                  name="familiasmarajassc"
                  checked={checkboxes.familiasmarajassc}
                  className="mr-2"
                  onChange={handleCheckboxChange}
                />
                Famílias
              </label>

              <label>
                <input
                  type="checkbox"
                  name="rendamarajassc"
                  checked={checkboxes.rendamarajassc}
                  className="mr-2"
                  onChange={handleCheckboxChange}
                />
                Renda
              </label>

              <label>
                <input
                  type="checkbox"
                  name="pfmarasc"
                  checked={checkboxes.pfmarasc}
                  className="mr-2"
                  onChange={handleCheckboxChange}
                />
                Pontos de Fluxo
              </label>

              <label>
                <input
                  type="checkbox"
                  name="scmarasc"
                  checked={checkboxes.scmarasc}
                  className="mr-2"
                  onChange={handleCheckboxChange}
                />
                Sucão
              </label>

              <label>
                <input
                  type="checkbox"
                  name="ponibusmarasc"
                  checked={checkboxes.ponibusmarasc}
                  className="mr-2"
                  onChange={handleCheckboxChange}
                />
                Pontos de Ônibus
              </label>

              <label>
                <input
                  type="checkbox"
                  name="lonibusmarasc"
                  checked={checkboxes.lonibusmarasc}
                  className="mr-2"
                  onChange={handleCheckboxChange}
                />
                Linhas de Ônibus
              </label>
            </div>
          )}

        {selectedMun &&
          userType === "sucao" &&
          selectedMun.value === "Jacarepaguá" && (
            <div className="container-items-check">
              <label>
                <input
                  type="checkbox"
                  name="lmjacaresc"
                  checked={checkboxes.lmjacaresc}
                  className="mr-2"
                  onChange={handleCheckboxChange}
                />
                Limite Municipal
              </label>

              <label>
                <input
                  type="checkbox"
                  name="compjacaresc"
                  checked={checkboxes.compjacaresc}
                  className="mr-2"
                  onChange={handleCheckboxChange}
                />
                Competidores
              </label>

              <label>
                <input
                  type="checkbox"
                  name="r1jacaresc"
                  checked={checkboxes.r1jacaresc}
                  className="mr-2"
                  onChange={handleCheckboxChange}
                />
                Raio de 1km Sucão
              </label>

              <label>
                <input
                  type="checkbox"
                  name="dadossociojacaresc"
                  checked={checkboxes.dadossociojacaresc}
                  className="mr-2"
                  onChange={handleCheckboxChange}
                />
                Dados Socioeconômicos
              </label>

              <label>
                <input
                  type="checkbox"
                  name="pfjacaresc"
                  checked={checkboxes.pfjacaresc}
                  className="mr-2"
                  onChange={handleCheckboxChange}
                />
                Pontos de Fluxo
              </label>
              <label>
                <input
                  type="checkbox"
                  name="kerneljacaresc"
                  checked={checkboxes.kerneljacaresc}
                  className="mr-2"
                  onChange={handleCheckboxChange}
                />
                Kernel
              </label>
              <label>
                <input
                  type="checkbox"
                  name="scjacaresc"
                  checked={checkboxes.scjacaresc}
                  className="mr-2"
                  onChange={handleCheckboxChange}
                />
                Sucão
              </label>
            </div>
          )}
        {selectedMun && userType === "acc2" && (
          <div className="container-items-check">
            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  TERRITÓRIO MUNICIPAL
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4 text-sm">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="miranorteacc2"
                      checked={checkboxes.miranorteacc2}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Miranorte
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="silvanopolisacc2"
                      checked={checkboxes.silvanopolisacc2}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Silvanópolis
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="lagoaacc2"
                      checked={checkboxes.lagoaacc2}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Lagoa da Confusão
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="formosoacc2"
                      checked={checkboxes.formosoacc2}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Formoso do Araguaia
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="portoacc2"
                      checked={checkboxes.portoacc2}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Porto Nacional
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="tassoacc2"
                      checked={checkboxes.tassoacc2}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Tasso Fragoso
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="balsasacc2"
                      checked={checkboxes.balsasacc2}
                      onChange={handleCheckboxChange}
                      className="w-3 h-3"
                    />
                    Balsas
                  </label>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  ARMAZÉNS
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4 text-sm">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="armazensacc2"
                      checked={checkboxes.armazensacc2}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Armazéns Geral
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="amoarmazensacc2"
                      checked={checkboxes.amoarmazensacc2}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Amostra - Armazéns contatos
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="concarmazensacc2"
                      checked={checkboxes.concarmazensacc2}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Concentração de Armazéns
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="prestaacc2"
                      checked={checkboxes.prestaacc2}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Presta Serviço
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="habacc2"
                      checked={checkboxes.habacc2}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Habilitação para Armazenar Estoques Públicos
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="capacc2"
                      checked={checkboxes.capacc2}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Capacidade Estática (t)
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="tipoacc2"
                      checked={checkboxes.tipoacc2}
                      onChange={handleCheckboxChange}
                      className="w-3 h-3"
                    />
                    Tipo
                  </label>

                  {/* <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="porteacc2"
                      checked={checkboxes.porteacc2}
                      onChange={handleCheckboxChange}
                      className="w-3 h-3"
                    />
                    Porte add
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="formacc2"
                      checked={checkboxes.formacc2}
                      onChange={handleCheckboxChange}
                      className="w-3 h-3"
                    />
                    Formalização add
                  </label> */}

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="distferroacc2"
                      checked={checkboxes.distferroacc2}
                      onChange={handleCheckboxChange}
                      className="w-3 h-3"
                    />
                    Distância da Ferrovia
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="distermacc2"
                      checked={checkboxes.distermacc2}
                      onChange={handleCheckboxChange}
                      className="w-3 h-3"
                    />
                    Distância dos Terminais
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="estosoacc2"
                      checked={checkboxes.estosoacc2}
                      onChange={handleCheckboxChange}
                      className="w-3 h-3"
                    />
                    Estoque Soja e Milho
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="precosoacc2"
                      checked={checkboxes.precosoacc2}
                      onChange={handleCheckboxChange}
                      className="w-3 h-3"
                    />
                    Preço Soja e Milho
                  </label>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  PROPRIEDADES
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4 text-sm">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="prop1toacc2"
                      checked={checkboxes.prop1toacc2}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Propriedades (TO 1)
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="prop2toacc2"
                      checked={checkboxes.prop2toacc2}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Propriedades (TO 2)
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="prop1maacc2"
                      checked={checkboxes.prop1maacc2}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Propriedades (MA 1)
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="prop2maacc2"
                      checked={checkboxes.prop2maacc2}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Propriedades (MA 2)
                  </label>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  TERMINAIS
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4 text-sm">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="portsaoluisacc2"
                      checked={checkboxes.portsaoluisacc2}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Terminal Portuário São Luís (TPSL)
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="portnacionalacc2"
                      checked={checkboxes.portnacionalacc2}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Terminal Integrador Porto Nacional (TIPN)
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="intpalemeiranteacc2"
                      checked={checkboxes.intpalemeiranteacc2}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Terminal Integrador de Palmeirante (TIPA)
                  </label>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  TIPOS DE USOS DE SOLO
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4 text-sm">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="usosolomiranorteacc2"
                      className="w-3 h-3"
                      checked={checkboxes.usosolomiranorteacc2}
                      onChange={handleCheckboxChange}
                    />
                    Miranorte
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="usosolosilvanopolisacc2"
                      checked={checkboxes.usosolosilvanopolisacc2}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Silvanópolis
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="usosololagoaacc2"
                      checked={checkboxes.usosololagoaacc2}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Lagoa da Confusão
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="usosoloformosoacc2"
                      checked={checkboxes.usosoloformosoacc2}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Formoso do Araguaia
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="usosoloportoacc2"
                      checked={checkboxes.usosoloportoacc2}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Porto Nacional
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="usosolotassoacc2"
                      className="w-3 h-3"
                      checked={checkboxes.usosolotassoacc2}
                      onChange={handleCheckboxChange}
                    />
                    Tasso Fragoso
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      className="w-3 h-3"
                      name="usosolobalsasacc2"
                      checked={checkboxes.usosolobalsasacc2}
                      onChange={handleCheckboxChange}
                    />
                    Balsas
                  </label>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  ASPECTOS SOCIOECONÔMICOS
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4 text-sm">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="popacc2"
                      checked={checkboxes.popacc2}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    População (Municípios)
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="rendaacc2"
                      checked={checkboxes.rendaacc2}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Renda (Municípios)
                  </label>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  ASPECTOS FÍSICOS
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4 text-sm">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="viasgeralacc2"
                      checked={checkboxes.viasgeralacc2}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Vias
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="hidroviasofcacc2"
                      checked={checkboxes.hidroviasofcacc2}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Hidrovias Oficiais
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="rodoviasacc2"
                      checked={checkboxes.rodoviasacc2}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Rodovias
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="instalacoesportacc2"
                      checked={checkboxes.instalacoesportacc2}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Instalações Portuárias
                  </label>

                  {/* <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="aeroacc2"
                      checked={checkboxes.aeroacc2}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Aeródromos públicos add
                  </label> */}

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="ferroviasacc2"
                      checked={checkboxes.ferroviasacc2}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Ferrovias
                  </label>

                  {/* <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="declacc2"
                      checked={checkboxes.declacc2}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Declividade add
                  </label> */}
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>
          </div>
        )}

        {selectedMun && userType === "accenture" && (
          <div className="container-items-check">
            <label>
              <input
                type="checkbox"
                name="angradosreisacc"
                checked={checkboxes.angradosreisacc}
                onChange={handleCheckboxChange}
              />
              Competidores
            </label>

            <label>
              <input
                type="checkbox"
                name="br101km414acc"
                checked={checkboxes.br101km414acc}
                onChange={handleCheckboxChange}
              />
              Concentração de Competidores
            </label>

            <label>
              <input
                type="checkbox"
                name="br101km447acc"
                checked={checkboxes.br101km447acc}
                onChange={handleCheckboxChange}
              />
              Trecho
            </label>

            <label>
              <input
                type="checkbox"
                name="br101km538acc"
                checked={checkboxes.br101km538acc}
                onChange={handleCheckboxChange}
              />
              Áreas Protegidas
            </label>

            <label>
              <input
                type="checkbox"
                name="rendaacc"
                checked={checkboxes.rendaacc}
                onChange={handleCheckboxChange}
              />
              Renda
            </label>

            <label>
              <input
                type="checkbox"
                name="pedagiosac"
                checked={checkboxes.pedagiosac}
                onChange={handleCheckboxChange}
              />
              Pedágios
            </label>
            <label>
              <input
                type="checkbox"
                name="itaguaiacc"
                checked={checkboxes.itaguaiacc}
                onChange={handleCheckboxChange}
              />
              Comportamento
            </label>
            <label>
              <input
                type="checkbox"
                name="faixaac"
                checked={checkboxes.faixaac}
                onChange={handleCheckboxChange}
              />
              Faixa de domínio
            </label>
            {/* 

            <label>
              <input
                type="checkbox"
                name="mangaratibaacc"
                checked={checkboxes.mangaratibaacc}
                onChange={handleCheckboxChange}
              />
              Mangaratiba
            </label>

            <label>
              <input
                type="checkbox"
                name="paratyacc"
                checked={checkboxes.paratyacc}
                onChange={handleCheckboxChange}
              />
              Paraty
            </label>

            <label>
              <input
                type="checkbox"
                name="trechobr101acc"
                checked={checkboxes.trechobr101acc}
                onChange={handleCheckboxChange}
              />
              Trecho BR101
            </label>


            <label>
              <input
                type="checkbox"
                name="areasconmataatlaacc"
                checked={checkboxes.areasconmataatlaacc}
                onChange={handleCheckboxChange}
              />
              Áreas de conservação da Mata Atlântica
            </label>

            <label>
              <input
                type="checkbox"
                name="vegetacaonativaacc"
                checked={checkboxes.vegetacaonativaacc}
                onChange={handleCheckboxChange}
              />
              Vegetação Nativa
            </label>

            <label>
              <input
                type="checkbox"
                name="reservalegalacc"
                checked={checkboxes.reservalegalacc}
                onChange={handleCheckboxChange}
              />
              Reserva Legal
            </label>

            <label>
              <input
                type="checkbox"
                name="appacc"
                checked={checkboxes.appacc}
                onChange={handleCheckboxChange}
              />
              Áreas de Preservação Permanente (APP)
            </label>

            <label>
              <input
                type="checkbox"
                name="uniconsfederaisacc"
                checked={checkboxes.uniconsfederaisacc}
                onChange={handleCheckboxChange}
              />
              Unidades de Conservação Federais
            </label>

            <label>
              <input
                type="checkbox"
                name="areasprotacc"
                checked={checkboxes.areasprotacc}
                onChange={handleCheckboxChange}
              />
              Áreas Protegidas
            </label>

            <label>
              <input
                type="checkbox"
                name="moteisac"
                checked={checkboxes.moteisac}
                onChange={handleCheckboxChange}
              />
              Motéis
            </label>

            

            <label>
              <input
                type="checkbox"
                name="postosac"
                checked={checkboxes.postosac}
                onChange={handleCheckboxChange}
              />
              Postos de Gasolina
            </label>

            <label>
              <input
                type="checkbox"
                name="restaurantesac"
                checked={checkboxes.restaurantesac}
                onChange={handleCheckboxChange}
              />
              Restaurantes
            </label>

            <label>
              <input
                type="checkbox"
                name="hoteisac"
                checked={checkboxes.hoteisac}
                onChange={handleCheckboxChange}
              />
              Hotéis
            </label>

            <label>
              <input
                type="checkbox"
                name="pousadasac"
                checked={checkboxes.pousadasac}
                onChange={handleCheckboxChange}
              />
              Pousadas
            </label> */}
          </div>
        )}

        {selectedMun && userType === "rsg" && (
          <div className="container-items-check">
            <label>
              <input
                type="checkbox"
                name="areaspotpucrsg"
                checked={checkboxes.areaspotpucrsg}
                onChange={handleCheckboxChange}
              />
              Áreas Potenciais PUC
            </label>

            <label>
              <input
                type="checkbox"
                name="areaspotunicampport2rsg"
                checked={checkboxes.areaspotunicampport2rsg}
                onChange={handleCheckboxChange}
              />
              Áreas Potenciais Unicamp - Portaria 2
            </label>

            <label>
              <input
                type="checkbox"
                name="areaspotunicampprincrsg"
                checked={checkboxes.areaspotunicampprincrsg}
                onChange={handleCheckboxChange}
              />
              Áreas Potenciais Unicamp - Principal
            </label>

            <label>
              <input
                type="checkbox"
                name="portariasrsg"
                checked={checkboxes.portariasrsg}
                onChange={handleCheckboxChange}
              />
              Portarias
            </label>

            <label>
              <input
                type="checkbox"
                name="raio200rsg"
                checked={checkboxes.raio200rsg}
                onChange={handleCheckboxChange}
              />
              Raio de 200m em torno das portarias
            </label>

            <label>
              <input
                type="checkbox"
                name="terrenosdisprsg"
                checked={checkboxes.terrenosdisprsg}
                onChange={handleCheckboxChange}
              />
              Terrenos Disponíveis
            </label>
          </div>
        )}

        {selectedMun &&
          userType === "thejoyschool" &&
          selectedMun.value === "Americana" && (
            <div className="container-items-check">
              <label>
                <input
                  type="checkbox"
                  name="areasinfluenciatja"
                  checked={checkboxes.areasinfluenciatja}
                  onChange={handleCheckboxChange}
                />
                Áreas de Influência
              </label>

              <label>
                <input
                  type="checkbox"
                  name="areasindicadasinfantiltja"
                  checked={checkboxes.areasindicadasinfantiltja}
                  onChange={handleCheckboxChange}
                />
                Áreas Indicadas Ed. Infantil
              </label>

              <label>
                <input
                  type="checkbox"
                  name="areasindicadasfundamentaltja"
                  checked={checkboxes.areasindicadasfundamentaltja}
                  onChange={handleCheckboxChange}
                />
                Áreas Indicadas Ed. Fundamental
              </label>

              <label>
                <input
                  type="checkbox"
                  name="compdiretosinfantiltja"
                  checked={checkboxes.compdiretosinfantiltja}
                  onChange={handleCheckboxChange}
                />
                Competidores Diretos Ed. Infantil
              </label>

              <label>
                <input
                  type="checkbox"
                  name="compdiretosfundamentaltja"
                  checked={checkboxes.compdiretosfundamentaltja}
                  onChange={handleCheckboxChange}
                />
                Competidores Diretos Ed. Fundamental
              </label>

              <label>
                <input
                  type="checkbox"
                  name="compindiretosinfantiltja"
                  checked={checkboxes.compindiretosinfantiltja}
                  onChange={handleCheckboxChange}
                />
                Competidores Indiretos Ed. Infantil
              </label>

              <label>
                <input
                  type="checkbox"
                  name="compindiretosfundamentaltja"
                  checked={checkboxes.compindiretosfundamentaltja}
                  onChange={handleCheckboxChange}
                />
                Competidores Indiretos Ed. Fundamental
              </label>

              <label>
                <input
                  type="checkbox"
                  name="concentracao0_5tja"
                  checked={checkboxes.concentracao0_5tja}
                  onChange={handleCheckboxChange}
                />
                Concentração Crianças 0 a 5 anos
              </label>

              <label>
                <input
                  type="checkbox"
                  name="concentracao6_10tja"
                  checked={checkboxes.concentracao6_10tja}
                  onChange={handleCheckboxChange}
                />
                Concentração Crianças 6 a 10 anos
              </label>

              <label>
                <input
                  type="checkbox"
                  name="escolatja"
                  checked={checkboxes.escolatja}
                  onChange={handleCheckboxChange}
                />
                Escola The Joy
              </label>

              <label>
                <input
                  type="checkbox"
                  name="limitetja"
                  checked={checkboxes.limitetja}
                  onChange={handleCheckboxChange}
                />
                Limite Município
              </label>

              <label>
                <input
                  type="checkbox"
                  name="pontosfluxotja"
                  checked={checkboxes.pontosfluxotja}
                  onChange={handleCheckboxChange}
                />
                Pontos de Fluxo
              </label>

              <label>
                <input
                  type="checkbox"
                  name="rendatja"
                  checked={checkboxes.rendatja}
                  onChange={handleCheckboxChange}
                />
                Renda
              </label>
            </div>
          )}

        {selectedMun &&
          userType === "thejoyschool" &&
          selectedMun.value === "Guarulhos" && (
            <div className="container-items-check">
              <label>
                <input
                  type="checkbox"
                  name="areasindicadasinfantiltjg"
                  checked={checkboxes.areasindicadasinfantiltjg}
                  onChange={handleCheckboxChange}
                />
                Áreas Indicadas Ed. Infantil
              </label>

              <label>
                <input
                  type="checkbox"
                  name="areasindicadasfundamentaltjg"
                  checked={checkboxes.areasindicadasfundamentaltjg}
                  onChange={handleCheckboxChange}
                />
                Áreas Indicadas Ed. Fundamental
              </label>

              <label>
                <input
                  type="checkbox"
                  name="compdiretosinfantiltjg"
                  checked={checkboxes.compdiretosinfantiltjg}
                  onChange={handleCheckboxChange}
                />
                Competidores Diretos Ed. Infantil
              </label>

              <label>
                <input
                  type="checkbox"
                  name="compdiretosfundamentaltjg"
                  checked={checkboxes.compdiretosfundamentaltjg}
                  onChange={handleCheckboxChange}
                />
                Competidores Diretos Ed. Fundamental
              </label>

              <label>
                <input
                  type="checkbox"
                  name="compindiretosinfantiltjg"
                  checked={checkboxes.compindiretosinfantiltjg}
                  onChange={handleCheckboxChange}
                />
                Competidores Indiretos Ed. Infantil
              </label>

              <label>
                <input
                  type="checkbox"
                  name="compindiretosfundamentaltjg"
                  checked={checkboxes.compindiretosfundamentaltjg}
                  onChange={handleCheckboxChange}
                />
                Competidores Indiretos Ed. Fundamental
              </label>

              <label>
                <input
                  type="checkbox"
                  name="concentracao0_5tjg"
                  checked={checkboxes.concentracao0_5tjg}
                  onChange={handleCheckboxChange}
                />
                Concentração Crianças 0 a 5 anos
              </label>

              <label>
                <input
                  type="checkbox"
                  name="concentracao6_10tjg"
                  checked={checkboxes.concentracao6_10tjg}
                  onChange={handleCheckboxChange}
                />
                Concentração Crianças 6 a 10 anos
              </label>

              <label>
                <input
                  type="checkbox"
                  name="familiastjg"
                  checked={checkboxes.familiastjg}
                  onChange={handleCheckboxChange}
                />
                Concentração de Famílias
              </label>

              <label>
                <input
                  type="checkbox"
                  name="rendatjg"
                  checked={checkboxes.rendatjg}
                  onChange={handleCheckboxChange}
                />
                Concentração de Renda
              </label>

              <label>
                <input
                  type="checkbox"
                  name="imovelpretendidotjg"
                  checked={checkboxes.imovelpretendidotjg}
                  onChange={handleCheckboxChange}
                />
                Imóveis Pretendidos
              </label>

              <label>
                <input
                  type="checkbox"
                  name="limitetjg"
                  checked={checkboxes.limitetjg}
                  onChange={handleCheckboxChange}
                />
                Limite urbano de Guarulhos
              </label>

              <label>
                <input
                  type="checkbox"
                  name="pontosfluxotjg"
                  checked={checkboxes.pontosfluxotjg}
                  onChange={handleCheckboxChange}
                />
                Pontos de Fluxo
              </label>

              <label>
                <input
                  type="checkbox"
                  name="raioinfluenciatjg"
                  checked={checkboxes.raioinfluenciatjg}
                  onChange={handleCheckboxChange}
                />
                Raio de influência
              </label>
            </div>
          )}

        {selectedMun &&
          userType === "thejoyschool" &&
          selectedMun.value === "Dracena" && (
            <div className="container-items-check">
              <label>
                <input
                  type="checkbox"
                  name="areasindicadasinfantiltjd"
                  checked={checkboxes.areasindicadasinfantiltjd}
                  onChange={handleCheckboxChange}
                />
                Áreas Indicadas Ed. Infantil
              </label>

              <label>
                <input
                  type="checkbox"
                  name="areasindicadasfundamentaltjd"
                  checked={checkboxes.areasindicadasfundamentaltjd}
                  onChange={handleCheckboxChange}
                />
                Áreas Indicadas Ed. Fundamental
              </label>

              <label>
                <input
                  type="checkbox"
                  name="compdiretosinfantiltjd"
                  checked={checkboxes.compdiretosinfantiltjd}
                  onChange={handleCheckboxChange}
                />
                Competidores Diretos Ed. Infantil
              </label>

              <label>
                <input
                  type="checkbox"
                  name="compdiretosfundamentaltjd"
                  checked={checkboxes.compdiretosfundamentaltjd}
                  onChange={handleCheckboxChange}
                />
                Competidores Diretos Ed. Fundamental
              </label>

              <label>
                <input
                  type="checkbox"
                  name="compindiretosinfantiltjd"
                  checked={checkboxes.compindiretosinfantiltjd}
                  onChange={handleCheckboxChange}
                />
                Competidores Indiretos Ed. Infantil
              </label>

              <label>
                <input
                  type="checkbox"
                  name="compindiretosfundamentaltjd"
                  checked={checkboxes.compindiretosfundamentaltjd}
                  onChange={handleCheckboxChange}
                />
                Competidores Indiretos Ed. Fundamental
              </label>

              <label>
                <input
                  type="checkbox"
                  name="concentracaocriancasinfantiltgd"
                  checked={checkboxes.concentracaocriancasinfantiltgd}
                  onChange={handleCheckboxChange}
                />
                Concentração Crianças Ed. Infantil
              </label>

              <label>
                <input
                  type="checkbox"
                  name="concentracaocriancasfundtgd"
                  checked={checkboxes.concentracaocriancasfundtgd}
                  onChange={handleCheckboxChange}
                />
                Concentração Crianças Ed. Fundamental
              </label>

              <label>
                <input
                  type="checkbox"
                  name="familiastjd"
                  checked={checkboxes.familiastjd}
                  onChange={handleCheckboxChange}
                />
                Concentração de Famílias
              </label>

              <label>
                <input
                  type="checkbox"
                  name="rendatjd"
                  checked={checkboxes.rendatjd}
                  onChange={handleCheckboxChange}
                />
                Concentração de Renda
              </label>

              <label>
                <input
                  type="checkbox"
                  name="limitetjd"
                  checked={checkboxes.limitetjd}
                  onChange={handleCheckboxChange}
                />
                Limite urbano de Dracena
              </label>

              <label>
                <input
                  type="checkbox"
                  name="pontosfluxotjd"
                  checked={checkboxes.pontosfluxotjd}
                  onChange={handleCheckboxChange}
                />
                Pontos de Fluxo
              </label>

              <label>
                <input
                  type="checkbox"
                  name="raioinfluenciatjd"
                  checked={checkboxes.raioinfluenciatjd}
                  onChange={handleCheckboxChange}
                />
                The Joy Dracena
              </label>
            </div>
          )}

        {selectedMun &&
          userType === "thejoyschool" &&
          selectedMun.value === "Chapadão" && (
            <div className="container-items-check">
              {/* <label>
                <input
                  type="checkbox"
                  name="tjchpdtjd"
                  checked={checkboxes.tjchpdtjd}
                  onChange={handleCheckboxChange}
                  className="mr-2"
                />
                TheJoy Campinas Chapadão
              </label> */}

              <label>
                <input
                  type="checkbox"
                  name="compdiretoschpdtjd"
                  checked={checkboxes.compdiretoschpdtjd}
                  className="mr-2"
                  onChange={handleCheckboxChange}
                />
                Competidores Diretos
              </label>
              <label>
                <input
                  type="checkbox"
                  name="criancas05chpdtjd"
                  checked={checkboxes.criancas05chpdtjd}
                  className="mr-2"
                  onChange={handleCheckboxChange}
                />
                Crianças 0-5 anos
              </label>
              <label>
                <input
                  type="checkbox"
                  name="escolasidiomaschpdtjd"
                  checked={checkboxes.escolasidiomaschpdtjd}
                  className="mr-2"
                  onChange={handleCheckboxChange}
                />
                Escolas de Idiomas
              </label>
              <label>
                <input
                  type="checkbox"
                  name="escolasinfantischpdtjd"
                  checked={checkboxes.escolasinfantischpdtjd}
                  className="mr-2"
                  onChange={handleCheckboxChange}
                />
                Escolas Infantis
              </label>
              <label>
                <input
                  type="checkbox"
                  name="escolaschpdtjd"
                  checked={checkboxes.escolaschpdtjd}
                  className="mr-2"
                  onChange={handleCheckboxChange}
                />
                Escolas
              </label>
              <label>
                <input
                  type="checkbox"
                  name="imovelindicadochpdtjd"
                  checked={checkboxes.imovelindicadochpdtjd}
                  className="mr-2"
                  onChange={handleCheckboxChange}
                />
                Imóvel Indicado
              </label>
              <label>
                <input
                  type="checkbox"
                  name="pontosfluxochpdtjd"
                  checked={checkboxes.pontosfluxochpdtjd}
                  className="mr-2"
                  onChange={handleCheckboxChange}
                />
                Pontos de Fluxo
              </label>
              <label>
                <input
                  type="checkbox"
                  name="principaisviaschpdtjd"
                  checked={checkboxes.principaisviaschpdtjd}
                  className="mr-2"
                  onChange={handleCheckboxChange}
                />
                Principais Vias
              </label>
              <label>
                <input
                  type="checkbox"
                  name="raio1kmcompchpdtjd"
                  checked={checkboxes.raio1kmcompchpdtjd}
                  className="mr-2"
                  onChange={handleCheckboxChange}
                />
                Raio 1km Competidores
              </label>
              <label>
                <input
                  type="checkbox"
                  name="raio2kmcompchpdtjd"
                  checked={checkboxes.raio2kmcompchpdtjd}
                  className="mr-2"
                  onChange={handleCheckboxChange}
                />
                Raio 2km Competidores
              </label>

              <label>
                <input
                  type="checkbox"
                  name="rendafamiliarchpdtjd"
                  checked={checkboxes.rendafamiliarchpdtjd}
                  className="mr-2"
                  onChange={handleCheckboxChange}
                />
                Renda Familiar
              </label>
            </div>
          )}

        {selectedMun &&
          userType === "thejoyschool" &&
          selectedMun.value === "Rio Claro" && (
            <div className="container-items-check">
              <label>
                <input
                  type="checkbox"
                  name="areainflurctjd"
                  checked={checkboxes.areainflurctjd}
                  onChange={handleCheckboxChange}
                  className="mr-2"
                />
                Área Influência Competidores
              </label>

              <label>
                <input
                  type="checkbox"
                  name="areasindirctjd"
                  checked={checkboxes.areasindirctjd}
                  onChange={handleCheckboxChange}
                  className="mr-2"
                />
                Áreas Indicadas
              </label>

              <label>
                <input
                  type="checkbox"
                  name="competidoresrctjd"
                  checked={checkboxes.competidoresrctjd}
                  onChange={handleCheckboxChange}
                  className="mr-2"
                />
                Competidores
              </label>

              <label>
                <input
                  type="checkbox"
                  name="condominiosrctjd"
                  checked={checkboxes.condominiosrctjd}
                  onChange={handleCheckboxChange}
                  className="mr-2"
                />
                Condomínios
              </label>

              <label>
                <input
                  type="checkbox"
                  name="imoveisrctjd"
                  checked={checkboxes.imoveisrctjd}
                  onChange={handleCheckboxChange}
                  className="mr-2"
                />
                Imóveis
              </label>

              <label>
                <input
                  type="checkbox"
                  name="pontosfluxirctjd"
                  checked={checkboxes.pontosfluxirctjd}
                  onChange={handleCheckboxChange}
                  className="mr-2"
                />
                Pontos de Fluxo
              </label>
            </div>
          )}

        {selectedMun &&
          userType === "thejoyschool" &&
          selectedMun.value === "Vila Prudente" && (
            <div className="container-items-check">
              <label>
                <input
                  type="checkbox"
                  name="compdiretosvptjd"
                  checked={checkboxes.compdiretosvptjd}
                  onChange={handleCheckboxChange}
                  className="mr-2"
                />
                Competidores Diretos
              </label>

              <label>
                <input
                  type="checkbox"
                  name="imoveissapopembavptjd"
                  checked={checkboxes.imoveissapopembavptjd}
                  onChange={handleCheckboxChange}
                  className="mr-2"
                />
                Imóveis Sapopemba
              </label>

              <label>
                <input
                  type="checkbox"
                  name="imoveisvpvptjd"
                  checked={checkboxes.imoveisvpvptjd}
                  onChange={handleCheckboxChange}
                  className="mr-2"
                />
                Imóveis Vila Prudente
              </label>

              <label>
                <input
                  type="checkbox"
                  name="pontosfluxovptjd"
                  checked={checkboxes.pontosfluxovptjd}
                  onChange={handleCheckboxChange}
                  className="mr-2"
                />
                Pontos de Fluxo
              </label>

              <label>
                <input
                  type="checkbox"
                  name="rendafamiliarvptjd"
                  checked={checkboxes.rendafamiliarvptjd}
                  onChange={handleCheckboxChange}
                  className="mr-2"
                />
                Renda Familiar Acima de R$ 7.000,00
              </label>

              <label>
                <input
                  type="checkbox"
                  name="tjschoolvptjd"
                  checked={checkboxes.tjschoolvptjd}
                  onChange={handleCheckboxChange}
                  className="mr-2"
                />
                TheJoy School
              </label>
            </div>
          )}

        {selectedMun && userType === "gurgel" && (
          <div className="container-items-check">
            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  Mapeamento Survey
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4 text-sm">
                  <label className="flex items-center gap-2">
                    <input
                      className="mr-2"
                      type="checkbox"
                      name="bdgu"
                      checked={checkboxes.bdgu}
                      onChange={handleCheckboxChange}
                    />
                    Base de Dados
                  </label>

                  <label className="flex items-center gap-2">
                    <input
                      className="mr-2"
                      type="checkbox"
                      name="demandagu"
                      checked={checkboxes.demandagu}
                      onChange={handleCheckboxChange}
                    />
                    Demanda de Espaço
                  </label>

                  <label className="flex items-center gap-2">
                    <input
                      className="mr-2"
                      type="checkbox"
                      name="ativpesquisagu"
                      checked={checkboxes.ativpesquisagu}
                      onChange={handleCheckboxChange}
                    />
                    Atividades de Pesquisa
                  </label>

                  <label className="flex items-center gap-2">
                    <input
                      className="mr-2"
                      type="checkbox"
                      name="interessehubgu"
                      checked={checkboxes.interessehubgu}
                      onChange={handleCheckboxChange}
                    />
                    Interesse em se Associar a um HUB de Inovação
                  </label>

                  <label className="flex items-center gap-2">
                    <input
                      className="mr-2"
                      type="checkbox"
                      name="modelotrabalhogu"
                      checked={checkboxes.modelotrabalhogu}
                      onChange={handleCheckboxChange}
                    />
                    Modelo de Trabalho
                  </label>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  Tipos de Fomentos
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4 text-sm">
                  <label className="flex items-center gap-2">
                    <input
                      className="mr-2"
                      type="checkbox"
                      name="capitalpropriogu"
                      checked={checkboxes.capitalpropriogu}
                      onChange={handleCheckboxChange}
                    />
                    Capital próprio
                  </label>

                  <label className="flex items-center gap-2">
                    <input
                      className="mr-2"
                      type="checkbox"
                      name="fundestaduaisgu"
                      checked={checkboxes.fundestaduaisgu}
                      onChange={handleCheckboxChange}
                    />
                    Fundações Estaduais de Amparo à Pesquisa
                  </label>

                  <label className="flex items-center gap-2">
                    <input
                      className="mr-2"
                      type="checkbox"
                      name="sebraegu"
                      checked={checkboxes.sebraegu}
                      onChange={handleCheckboxChange}
                    />
                    Sebrae
                  </label>

                  <label className="flex items-center gap-2">
                    <input
                      className="mr-2"
                      type="checkbox"
                      name="bndesgu"
                      checked={checkboxes.bndesgu}
                      onChange={handleCheckboxChange}
                    />
                    BNDES
                  </label>

                  <label className="flex items-center gap-2">
                    <input
                      className="mr-2"
                      type="checkbox"
                      name="investidoranjogu"
                      checked={checkboxes.investidoranjogu}
                      onChange={handleCheckboxChange}
                    />
                    Investidor Anjo
                  </label>

                  <label className="flex items-center gap-2">
                    <input
                      className="mr-2"
                      type="checkbox"
                      name="fundosinvestgu"
                      checked={checkboxes.fundosinvestgu}
                      onChange={handleCheckboxChange}
                    />
                    Fundos de investimento
                  </label>

                  <label className="flex items-center gap-2">
                    <input
                      className="mr-2"
                      type="checkbox"
                      name="aceleradorasgu"
                      checked={checkboxes.aceleradorasgu}
                      onChange={handleCheckboxChange}
                    />
                    Aceleradoras
                  </label>

                  <label className="flex items-center gap-2">
                    <input
                      className="mr-2"
                      type="checkbox"
                      name="semrespostagu"
                      checked={checkboxes.semrespostagu}
                      onChange={handleCheckboxChange}
                    />
                    Sem resposta
                  </label>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  Mapeamento HIDS
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4 text-sm">
                  <label className="flex items-center gap-2">
                    <input
                      className="mr-2"
                      type="checkbox"
                      name="bempresasgu"
                      checked={checkboxes.bempresasgu}
                      onChange={handleCheckboxChange}
                    />
                    Base de Empresas
                  </label>
                  <label className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      name="ashidsgu"
                      className="mr-2"
                      checked={checkboxes.ashidsgu}
                      onChange={handleCheckboxChange}
                    />
                    Áreas HIDS
                  </label>

                  <label className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      className="mr-2"
                      name="aspidsgu"
                      checked={checkboxes.aspidsgu}
                      onChange={handleCheckboxChange}
                    />
                    Áreas PIDS
                  </label>

                  <label className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      className="mr-2"
                      name="apidsgu"
                      checked={checkboxes.apidsgu}
                      onChange={handleCheckboxChange}
                    />
                    Área PIDS
                  </label>
                  <label className="flex items-center gap-2">
                    <input
                      className="mr-2"
                      type="checkbox"
                      name="campusdagu"
                      checked={checkboxes.campusdagu}
                      onChange={handleCheckboxChange}
                    />
                    Campus da Unicamp
                  </label>

                  <label className="flex items-center gap-2">
                    <input
                      className="mr-2"
                      type="checkbox"
                      name="campusgu"
                      checked={checkboxes.campusgu}
                      onChange={handleCheckboxChange}
                    />
                    Campus Unicamp
                  </label>

                  <label className="flex items-center gap-2">
                    <input
                      className="mr-2"
                      type="checkbox"
                      name="fzdarggu"
                      checked={checkboxes.fzdarggu}
                      onChange={handleCheckboxChange}
                    />
                    Fazenda Argentina
                  </label>

                  <label className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      className="mr-2"
                      name="ferroviasgu"
                      checked={checkboxes.ferroviasgu}
                      onChange={handleCheckboxChange}
                    />
                    Ferrovias
                  </label>

                  <label className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      className="mr-2"
                      name="ictsgu"
                      checked={checkboxes.ictsgu}
                      onChange={handleCheckboxChange}
                    />
                    ICT's
                  </label>

                  <label className="flex items-center gap-2">
                    <input
                      className="mr-2"
                      type="checkbox"
                      name="parquesgu"
                      checked={checkboxes.parquesgu}
                      onChange={handleCheckboxChange}
                    />
                    Parques Tecnológicos
                  </label>

                  <label className="flex items-center gap-2">
                    <input
                      className="mr-2"
                      type="checkbox"
                      name="riosgu"
                      checked={checkboxes.riosgu}
                      onChange={handleCheckboxChange}
                    />
                    Rios
                  </label>

                  <label className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      className="mr-2"
                      name="viasgu"
                      checked={checkboxes.viasgu}
                      onChange={handleCheckboxChange}
                    />
                    Vias
                  </label>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>
          </div>
        )}

        {/* {(selectedMun && userType === 'gen') &&
          <div className='container-items-check'>

            <label>
              <input
                type="checkbox"
                name="areaverdegl"
                checked={checkboxes.areaverdegl}
                onChange={handleCheckboxChange}
              />
              Área Verde
            </label>

            <label>
              <input
                type="checkbox"
                name="municipiogl"
                checked={checkboxes.municipiogl}
                onChange={handleCheckboxChange}
              />
              Município
            </label>

            <label>
              <input
                type="checkbox"
                name="condominiogooglegl"
                checked={checkboxes.condominiogooglegl}
                onChange={handleCheckboxChange}
              />
              Condomínios (Google)
            </label>

            <label>
              <input
                type="checkbox"
                name="condominiograpgl"
                checked={checkboxes.condominiograpgl}
                onChange={handleCheckboxChange}
              />
              Condomínios (Graprohab)
            </label>

            <label>
              <input
                type="checkbox"
                name="rendagl"
                checked={checkboxes.rendagl}
                onChange={handleCheckboxChange}
              />
              Renda
            </label>


          </div>
        } */}

        {selectedMun && userType === "edu" && (
          <div className="container-items-check">
            <label>
              <input
                type="checkbox"
                name="edumap"
                checked={checkboxes.edumap}
                onChange={handleCheckboxChange}
              />
              PizzaEdu
            </label>

            <label>
              <input
                type="checkbox"
                name="educomp"
                checked={checkboxes.educomp}
                onChange={handleCheckboxChange}
              />
              Competidores
            </label>

            <label>
              <input
                type="checkbox"
                name="edufamilia"
                checked={checkboxes.edufamilia}
                onChange={handleCheckboxChange}
              />
              Famílias
            </label>

            <label>
              <input
                type="checkbox"
                name="edurenda"
                checked={checkboxes.edurenda}
                onChange={handleCheckboxChange}
              />
              Renda
            </label>

            <label>
              <input
                type="checkbox"
                name="eduimoveis"
                checked={checkboxes.eduimoveis}
                onChange={handleCheckboxChange}
              />
              Imóveis Santo Amaro
            </label>
          </div>
        )}

        {selectedMun && userType === "buddahspa" && (
          <div className="container-items-check">
            <label>
              <input
                type="checkbox"
                name="clubesbs"
                checked={checkboxes.clubesbs}
                onChange={handleCheckboxChange}
              />
              Clubes
            </label>

            <label>
              <input
                type="checkbox"
                name="familiasbs"
                checked={checkboxes.familiasbs}
                onChange={handleCheckboxChange}
              />
              Famílias
            </label>

            <label>
              <input
                type="checkbox"
                name="lojasdepartamentobs"
                checked={checkboxes.lojasdepartamentobs}
                onChange={handleCheckboxChange}
              />
              Lojas de departamento
            </label>

            <label>
              <input
                type="checkbox"
                name="parquesbs"
                checked={checkboxes.parquesbs}
                onChange={handleCheckboxChange}
              />
              Parques
            </label>

            <label>
              <input
                type="checkbox"
                name="pontosbuddhaspabs"
                checked={checkboxes.pontosbuddhaspabs}
                onChange={handleCheckboxChange}
              />
              Pontos Buddha SPA
            </label>

            <label>
              <input
                type="checkbox"
                name="rendabs"
                checked={checkboxes.rendabs}
                onChange={handleCheckboxChange}
              />
              Renda
            </label>

            <label>
              <input
                type="checkbox"
                name="restaurantesbs"
                checked={checkboxes.restaurantesbs}
                onChange={handleCheckboxChange}
              />
              Restaurantes
            </label>

            <label>
              <input
                type="checkbox"
                name="shoppingsbs"
                checked={checkboxes.shoppingsbs}
                onChange={handleCheckboxChange}
              />
              Shoppings
            </label>

            <label>
              <input
                type="checkbox"
                name="supermercadosbs"
                checked={checkboxes.supermercadosbs}
                onChange={handleCheckboxChange}
              />
              Supermercados
            </label>

            <label>
              <input
                type="checkbox"
                name="estacionamentosbs"
                checked={checkboxes.estacionamentosbs}
                onChange={handleCheckboxChange}
              />
              Estacionamentos
            </label>

            <label>
              <input
                type="checkbox"
                name="imoveismenos18bs"
                checked={checkboxes.imoveismenos18bs}
                onChange={handleCheckboxChange}
              />
              Imóveis abaixo de 18 mil reais
            </label>

            <label>
              <input
                type="checkbox"
                name="imoveismais18bs"
                checked={checkboxes.imoveismais18bs}
                onChange={handleCheckboxChange}
              />
              Imóveis acima de 18 mil reais
            </label>

            <label>
              <input
                type="checkbox"
                name="areainflucompbs"
                checked={checkboxes.areainflucompbs}
                onChange={handleCheckboxChange}
              />
              Área de influência dos competidores
            </label>

            <label>
              <input
                type="checkbox"
                name="areapotcomrecorte"
                checked={checkboxes.areapotcomrecorte}
                onChange={handleCheckboxChange}
              />
              Áreas potenciais com recorte
            </label>

            <label>
              <input
                type="checkbox"
                name="areapotsemrecorte"
                checked={checkboxes.areapotsemrecorte}
                onChange={handleCheckboxChange}
              />
              Áreas potenciais sem recorte
            </label>

            <label>
              <input
                type="checkbox"
                name="competidoresbs"
                checked={checkboxes.competidoresbs}
                onChange={handleCheckboxChange}
              />
              Competidores
            </label>

            <label>
              <input
                type="checkbox"
                name="popadultabs"
                checked={checkboxes.popadultabs}
                onChange={handleCheckboxChange}
              />
              População adulta
            </label>

            <label>
              <input
                type="checkbox"
                name="zonasusobs"
                checked={checkboxes.zonasusobs}
                onChange={handleCheckboxChange}
              />
              Zonas de uso residencial e comercial
            </label>
          </div>
        )}

        {selectedMun && userType === "baldoni" && (
          <div className="container-items-check">
            <label>
              <input
                type="checkbox"
                name="areaspotbd"
                checked={checkboxes.areaspotbd}
                onChange={handleCheckboxChange}
              />
              Áreas potenciais
            </label>

            <label>
              <input
                type="checkbox"
                name="baldonibd"
                checked={checkboxes.baldonibd}
                onChange={handleCheckboxChange}
              />
              Baldoni Agro-Pesca
            </label>

            <label>
              <input
                type="checkbox"
                name="baldoni2030bd"
                checked={checkboxes.baldoni2030bd}
                onChange={handleCheckboxChange}
              />
              Baldoni 2030
            </label>

            <label>
              <input
                type="checkbox"
                name="lojaspescabd"
                checked={checkboxes.lojaspescabd}
                onChange={handleCheckboxChange}
              />
              Lojas de Pesca
            </label>

            <label>
              <input
                type="checkbox"
                name="pesquesbd"
                checked={checkboxes.pesquesbd}
                onChange={handleCheckboxChange}
              />
              Pesque-Pague
            </label>

            <label>
              <input
                type="checkbox"
                name="petshopsbd"
                checked={checkboxes.petshopsbd}
                onChange={handleCheckboxChange}
              />
              Pet Shops
            </label>

            <label>
              <input
                type="checkbox"
                name="popadultabd"
                checked={checkboxes.popadultabd}
                onChange={handleCheckboxChange}
              />
              População Adulta
            </label>

            <label>
              <input
                type="checkbox"
                name="rendabd"
                checked={checkboxes.rendabd}
                onChange={handleCheckboxChange}
              />
              Renda familiar
            </label>

            <label>
              <input
                type="checkbox"
                name="restaurantesbd"
                checked={checkboxes.restaurantesbd}
                onChange={handleCheckboxChange}
              />
              Supermercados
            </label>

            <label>
              <input
                type="checkbox"
                name="vetsbd"
                checked={checkboxes.vetsbd}
                onChange={handleCheckboxChange}
              />
              Veterinários
            </label>
          </div>
        )}

        {selectedMun && userType === "caribu" && (
          <div className="container-items-check">
            {/* <label>
              <input
                type="checkbox"
                name="fluxokernelcb"
                checked={checkboxes.fluxokernelcb}
                onChange={handleCheckboxChange}
              />
              Fluxo (Kernel)
            </label> */}
            <label>
              <input
                type="checkbox"
                name="perimetrourbanocb"
                checked={checkboxes.perimetrourbanocb}
                onChange={handleCheckboxChange}
              />
              Perímetro urbano
            </label>
            <label>
              <input
                type="checkbox"
                name="academiascb"
                checked={checkboxes.academiascb}
                onChange={handleCheckboxChange}
              />
              Academias
            </label>

            <label>
              <input
                type="checkbox"
                name="acaiteriacb"
                checked={checkboxes.acaiteriacb}
                onChange={handleCheckboxChange}
              />
              Açaiterias
            </label>

            <label>
              <input
                type="checkbox"
                name="areaspotcb"
                checked={checkboxes.areaspotcb}
                onChange={handleCheckboxChange}
              />
              Áreas potenciais
            </label>

            <label>
              <input
                type="checkbox"
                name="cafescb"
                checked={checkboxes.cafescb}
                onChange={handleCheckboxChange}
              />
              Cafés
            </label>

            <label>
              <input
                type="checkbox"
                name="comerciocb"
                checked={checkboxes.comerciocb}
                onChange={handleCheckboxChange}
              />
              Comércio
            </label>

            <label>
              <input
                type="checkbox"
                name="culturalcb"
                checked={checkboxes.culturalcb}
                onChange={handleCheckboxChange}
              />
              Cultural
            </label>

            <label>
              <input
                type="checkbox"
                name="escolascb"
                checked={checkboxes.escolascb}
                onChange={handleCheckboxChange}
              />
              Escolas
            </label>

            <label>
              <input
                type="checkbox"
                name="esportescb"
                checked={checkboxes.esportescb}
                onChange={handleCheckboxChange}
              />
              Esportes
            </label>

            <label>
              <input
                type="checkbox"
                name="faculdadescb"
                checked={checkboxes.faculdadescb}
                onChange={handleCheckboxChange}
              />
              Faculdades
            </label>

            <label>
              <input
                type="checkbox"
                name="imoveiscb"
                checked={checkboxes.imoveiscb}
                onChange={handleCheckboxChange}
              />
              Imóveis comerciais disponíveis
            </label>

            <label>
              <input
                type="checkbox"
                name="lanchonetescb"
                checked={checkboxes.lanchonetescb}
                onChange={handleCheckboxChange}
              />
              Lanchonetes
            </label>

            <label>
              <input
                type="checkbox"
                name="rendasetorescb"
                checked={checkboxes.rendasetorescb}
                onChange={handleCheckboxChange}
              />
              Renda dos setores censitários
            </label>

            <label>
              <input
                type="checkbox"
                name="comidasaudavelcb"
                checked={checkboxes.comidasaudavelcb}
                onChange={handleCheckboxChange}
              />
              Restaurantes de comida saudável
            </label>

            <label>
              <input
                type="checkbox"
                name="sorveteriacb"
                checked={checkboxes.sorveteriacb}
                onChange={handleCheckboxChange}
              />
              Sorveterias
            </label>

            <label>
              <input
                type="checkbox"
                name="concentrafluxocb"
                checked={checkboxes.concentrafluxocb}
                onChange={handleCheckboxChange}
              />
              Concentração de Fluxo
            </label>

            <label>
              <input
                type="checkbox"
                name="raiofluxocb"
                checked={checkboxes.raiofluxocb}
                onChange={handleCheckboxChange}
              />
              Raio de Fluxo de concorrentes
            </label>

            <label>
              <input
                type="checkbox"
                name="fluxoconcocb"
                checked={checkboxes.fluxoconcocb}
                onChange={handleCheckboxChange}
              />
              Fluxo de concorrentes
            </label>
          </div>
        )}

        {selectedMun && userType === "crca" && (
          // <div className='container-items-check'>
          //   <label>
          //     <input
          //       type="checkbox"
          //       name="bairroscrca"
          //       checked={checkboxes.bairroscrca}
          //       onChange={handleCheckboxChange}
          //     />
          //     Bairros CRCA
          //   </label>

          //   <label>
          //     <input
          //       type="checkbox"
          //       name="dadossociosfamiliascampinascrca"
          //       checked={checkboxes.dadossociosfamiliascampinascrca}
          //       onChange={handleCheckboxChange}
          //     />
          //     Dados Socioeconômicos Famílias
          //   </label>

          //   <label>
          //     <input
          //       type="checkbox"
          //       name="dadossociosrendacampinascrca"
          //       checked={checkboxes.dadossociosrendacampinascrca}
          //       onChange={handleCheckboxChange}
          //     />
          //     Dados Socioeconômicos Renda
          //   </label>

          //   <label>
          //     <input
          //       type="checkbox"
          //       name="zoneamentocampinascrca"
          //       checked={checkboxes.zoneamentocampinascrca}
          //       onChange={handleCheckboxChange}
          //     />
          //     Zoneamento Campinas
          //   </label>

          //   <label>
          //     <input
          //       type="checkbox"
          //       name="limitecampinascrca"
          //       checked={checkboxes.limitecampinascrca}
          //       onChange={handleCheckboxChange}
          //     />
          //     Limite Municipal Campinas
          //   </label>
          //   <label>
          //     <input
          //       type="checkbox"
          //       name="dadosporbairro"
          //       checked={checkboxes.dadosporbairro}
          //       onChange={handleCheckboxChange}
          //     />
          //     Dados por Bairro
          //   </label>

          // </div>
          <div className="container-items-check">
            <label>
              <input
                type="checkbox"
                name="abrangenciacrascrca2"
                checked={checkboxes.abrangenciacrascrca2}
                onChange={handleCheckboxChange}
              />
              Abrangência do CRAS
            </label>

            <label>
              <input
                type="checkbox"
                name="assistenciateccrca2"
                checked={checkboxes.assistenciateccrca2}
                onChange={handleCheckboxChange}
              />
              Assistência Técnica - Celulares e Informática
            </label>

            {/* <label>
              <input
                type="checkbox"
                name="bairroscrca2"
                checked={checkboxes.bairroscrca2}
                onChange={handleCheckboxChange}
              />
              Bairros CRCA
            </label> */}

            <label>
              <input
                type="checkbox"
                name="campinasdadossociosrendacrca2"
                checked={checkboxes.campinasdadossociosrendacrca2}
                onChange={handleCheckboxChange}
              />
              Dados Socioeconômicos Renda
            </label>

            <label>
              <input
                type="checkbox"
                name="campinasdadossociosfamiliascrca2"
                checked={checkboxes.campinasdadossociosfamiliascrca2}
                onChange={handleCheckboxChange}
              />
              Dados Socioeconômicos Famílias
            </label>

            <label>
              <input
                type="checkbox"
                name="campinaslimitecrca2"
                checked={checkboxes.campinaslimitecrca2}
                onChange={handleCheckboxChange}
              />
              Campinas Limite Municipal
            </label>
            <label>
              <input
                type="checkbox"
                name="campinaszoneamentocrca2"
                checked={checkboxes.campinaszoneamentocrca2}
                onChange={handleCheckboxChange}
              />
              Campinas Zoneamento
            </label>

            <label>
              <input
                type="checkbox"
                name="comercioalimentoscrca2"
                checked={checkboxes.comercioalimentoscrca2}
                onChange={handleCheckboxChange}
              />
              Comércio de Alimentos
            </label>

            <label>
              <input
                type="checkbox"
                name="dadosporbairrocrca2"
                checked={checkboxes.dadosporbairrocrca2}
                onChange={handleCheckboxChange}
              />
              Dados por Bairro
            </label>

            <label>
              <input
                type="checkbox"
                name="impressao3dcrca2"
                checked={checkboxes.impressao3dcrca2}
                onChange={handleCheckboxChange}
              />
              Impressão 3D
            </label>

            <label>
              <input
                type="checkbox"
                name="lojaderoupascrca2"
                checked={checkboxes.lojaderoupascrca2}
                onChange={handleCheckboxChange}
              />
              Loja de roupas
            </label>

            <label>
              <input
                type="checkbox"
                name="lojasartesanatocrca2"
                checked={checkboxes.lojasartesanatocrca2}
                onChange={handleCheckboxChange}
              />
              Lojas de Artesanato
            </label>

            <label>
              <input
                type="checkbox"
                name="oficinasmecanicascrca2"
                checked={checkboxes.oficinasmecanicascrca2}
                onChange={handleCheckboxChange}
              />
              Oficinas mecânicas
            </label>

            <label>
              <input
                type="checkbox"
                name="orgcivilcrca2"
                checked={checkboxes.orgcivilcrca2}
                onChange={handleCheckboxChange}
              />
              Organizações da Sociedade Civil - OSC
            </label>

            <label>
              <input
                type="checkbox"
                name="padariasconfeitariascrca2"
                checked={checkboxes.padariasconfeitariascrca2}
                onChange={handleCheckboxChange}
              />
              Padarias e Confeitarias
            </label>

            <label>
              <input
                type="checkbox"
                name="petshopscrca2"
                checked={checkboxes.petshopscrca2}
                onChange={handleCheckboxChange}
              />
              Pet Shops
            </label>

            <label>
              <input
                type="checkbox"
                name="regioesvulcrca2"
                checked={checkboxes.regioesvulcrca2}
                onChange={handleCheckboxChange}
              />
              Regiões de Vulnerabilidade Social
            </label>

            <label>
              <input
                type="checkbox"
                name="salaobelezacrc2"
                checked={checkboxes.salaobelezacrc2}
                onChange={handleCheckboxChange}
              />
              Salão de Beleza
            </label>
          </div>
        )}

        {selectedMun && userType === "pesquisaunicamp" && (
          <div className="container-items-check">
            <label>
              <input
                type="checkbox"
                name="demandaespacopu"
                checked={checkboxes.demandaespacopu}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              Demanda por Espaço (m²)
            </label>

            <label>
              <input
                type="checkbox"
                name="demandaqualidadepu"
                checked={checkboxes.demandaqualidadepu}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              Demanda por Qualidade
            </label>

            <label>
              <input
                type="checkbox"
                name="faixafaturamentopu"
                checked={checkboxes.faixafaturamentopu}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              Faixa de faturamento
            </label>

            <label>
              <input
                type="checkbox"
                name="leispu"
                checked={checkboxes.leispu}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              Leis de incentivo a inovação
            </label>

            <label>
              <input
                type="checkbox"
                name="mapageralpu"
                checked={checkboxes.mapageralpu}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              Mapa geral
            </label>

            <label>
              <input
                type="checkbox"
                name="modelopu"
                checked={checkboxes.modelopu}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              Modelo de trabalho pós pandemia
            </label>

            <label>
              <input
                type="checkbox"
                name="preferenpu"
                checked={checkboxes.preferenpu}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              Preferência por um Novo Espaço
            </label>

            <label>
              <input
                type="checkbox"
                name="necessidadepu"
                checked={checkboxes.necessidadepu}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              Qual a necessidade de pesquisadores especializados
            </label>

            <label>
              <input
                type="checkbox"
                name="relacaopu"
                checked={checkboxes.relacaopu}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              Relação com desenvolvimento sustentável
            </label>

            <label>
              <input
                type="checkbox"
                name="setorpu"
                checked={checkboxes.setorpu}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              Setor de atuação
            </label>
          </div>
        )}

        {selectedMun && userType === "melten" && (
          <div className="container-items-check">
            <label>
              <input
                type="checkbox"
                name="acadmelten"
                checked={checkboxes.acadmelten}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              Academias e Centros Esportivos
            </label>

            <label>
              <input
                type="checkbox"
                name="areasafastmelten"
                checked={checkboxes.areasafastmelten}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              Áreas Potenciais
            </label>

            <label>
              <input
                type="checkbox"
                name="comerciosmelten"
                checked={checkboxes.comerciosmelten}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              Comércios
            </label>

            <label>
              <input
                type="checkbox"
                name="concconcmelten"
                checked={checkboxes.concconcmelten}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              Concentração dos Concorrentes
            </label>

            <label>
              <input
                type="checkbox"
                name="concmelten"
                checked={checkboxes.concmelten}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              Concorrentes
            </label>

            <label>
              <input
                type="checkbox"
                name="crescurbanomelten"
                checked={checkboxes.crescurbanomelten}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              Crescimento Urbano
            </label>

            <label>
              <input
                type="checkbox"
                name="domiciliosmelten"
                checked={checkboxes.domiciliosmelten}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              Domicílios
            </label>

            <label>
              <input
                type="checkbox"
                name="equipublimelten"
                checked={checkboxes.equipublimelten}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              Equipamentos Públicos
            </label>

            <label>
              <input
                type="checkbox"
                name="escolasprivadasmelten"
                checked={checkboxes.escolasprivadasmelten}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              Escolas Privadas
            </label>

            <label>
              <input
                type="checkbox"
                name="hidroviasmelten"
                checked={checkboxes.hidroviasmelten}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              Hidrovias
            </label>

            <label>
              <input
                type="checkbox"
                name="hospitaismelten"
                checked={checkboxes.hospitaismelten}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              Hospitais Clínicas e UBS
            </label>

            <label>
              <input
                type="checkbox"
                name="imoveismelten"
                checked={checkboxes.imoveismelten}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              Imóveis Comerciais
            </label>

            <label>
              <input
                type="checkbox"
                name="infraviariamelten"
                checked={checkboxes.infraviariamelten}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              Infraestrutura Viária
            </label>

            <label>
              <input
                type="checkbox"
                name="melten"
                checked={checkboxes.melten}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              MeltenCo
            </label>

            <label>
              <input
                type="checkbox"
                name="palmasmelten"
                checked={checkboxes.palmasmelten}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              Palmas
            </label>

            <label>
              <input
                type="checkbox"
                name="popmelten"
                checked={checkboxes.popmelten}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              População
            </label>

            <label>
              <input
                type="checkbox"
                name="rendamelten"
                checked={checkboxes.rendamelten}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              Renda
            </label>

            <label>
              <input
                type="checkbox"
                name="regionalizacaomelten"
                checked={checkboxes.regionalizacaomelten}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              Regionalização de Palmas
            </label>

            <label>
              <input
                type="checkbox"
                name="restaurantesmelten"
                checked={checkboxes.restaurantesmelten}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              Restaurantes
            </label>

            <label>
              <input
                type="checkbox"
                name="transportemelten"
                checked={checkboxes.transportemelten}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              Transporte
            </label>

            <label>
              <input
                type="checkbox"
                name="viasmelten"
                checked={checkboxes.viasmelten}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              Vias
            </label>
          </div>
        )}

        {selectedMun && userType === "emccamp" && (
          <div className="container-items-check">
            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  Aspectos Físicos
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="areaspreservavespemcp"
                      checked={checkboxes.areaspreservavespemcp}
                      onChange={handleCheckboxChange}
                    />
                    Áreas de Preservação
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="vegnativavespemcp"
                      checked={checkboxes.vegnativavespemcp}
                      onChange={handleCheckboxChange}
                    />
                    Vegetação Nativa
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="reservalegalvespemcp"
                      checked={checkboxes.reservalegalvespemcp}
                      onChange={handleCheckboxChange}
                    />
                    Reserva Legal
                  </label>

                  {/* <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="nascentesvespemcp"
                        checked={checkboxes.nascentesvespemcp}
                        onChange={handleCheckboxChange}
                      />
                      Nascentes
                    </label> */}

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="cursosaguavespemcp"
                      checked={checkboxes.cursosaguavespemcp}
                      onChange={handleCheckboxChange}
                    />
                    Cursos d’água
                  </label>
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="topografiavespemcp"
                      checked={checkboxes.topografiavespemcp}
                      onChange={handleCheckboxChange}
                    />
                    Topografia
                  </label>
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="limitevespemcp"
                      checked={checkboxes.limitevespemcp}
                      onChange={handleCheckboxChange}
                    />
                    Limite Municipal
                  </label>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  Aspectos Legais
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="zoneamentovespemcp"
                      checked={checkboxes.zoneamentovespemcp}
                      onChange={handleCheckboxChange}
                    />
                    Zoneamento Municipal
                  </label>
                  {/* <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="usovertvespemcp"
                        checked={checkboxes.usovertvespemcp}
                        onChange={handleCheckboxChange}
                      />
                      Uso Vertical
                    </label> */}
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="permissaovespemcp"
                      checked={checkboxes.permissaovespemcp}
                      onChange={handleCheckboxChange}
                    />
                    Permissão
                  </label>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  Infraestrutura
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="locterrenovespemcp"
                      checked={checkboxes.locterrenovespemcp}
                      onChange={handleCheckboxChange}
                    />
                    Localização do terreno
                  </label>
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="servidaovespemcp"
                      checked={checkboxes.servidaovespemcp}
                      onChange={handleCheckboxChange}
                    />
                    Servidão Administrativa
                  </label>
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="areaconsovespemcp"
                      checked={checkboxes.areaconsovespemcp}
                      onChange={handleCheckboxChange}
                    />
                    Área Consolidada
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="areascontavespemcp"
                      checked={checkboxes.areascontavespemcp}
                      onChange={handleCheckboxChange}
                    />
                    Áreas Contaminadas
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="dutoviasvespemcp"
                      checked={checkboxes.dutoviasvespemcp}
                      onChange={handleCheckboxChange}
                    />
                    Dutovias
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="linhaovespemcp"
                      checked={checkboxes.linhaovespemcp}
                      onChange={handleCheckboxChange}
                    />
                    Linhão
                  </label>

                  {/* <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="novoscondvespemcp"
                        checked={checkboxes.novoscondvespemcp}
                        onChange={handleCheckboxChange}
                      />
                      Novos condomínios
                    </label> */}
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  Aspectos Socioeconômicos
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="rendavespemcp"
                      checked={checkboxes.rendavespemcp}
                      onChange={handleCheckboxChange}
                    />
                    Renda
                  </label>
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="popvespemcp"
                      checked={checkboxes.popvespemcp}
                      onChange={handleCheckboxChange}
                    />
                    População
                  </label>
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="domicivespemcp"
                      checked={checkboxes.domicivespemcp}
                      onChange={handleCheckboxChange}
                    />
                    Domicílios
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="domicialuvespemcp"
                      checked={checkboxes.domicialuvespemcp}
                      onChange={handleCheckboxChange}
                    />
                    Domicílios de aluguel
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="expurbanavespemcp"
                      checked={checkboxes.expurbanavespemcp}
                      onChange={handleCheckboxChange}
                    />
                    Expansão urbana
                  </label>

                  {/* <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="veloeprecovespemcp"
                        checked={checkboxes.veloeprecovespemcp}
                        onChange={handleCheckboxChange}
                      />
                      Velocidade e Preço médio
                    </label> */}

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="taxahabvespemcp"
                      checked={checkboxes.taxahabvespemcp}
                      onChange={handleCheckboxChange}
                    />
                    Taxa Habitacional
                  </label>
                  {/* 
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="concorrenciavespemcp"
                        checked={checkboxes.concorrenciavespemcp}
                        onChange={handleCheckboxChange}
                      />
                      Concorrência
                    </label> */}

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="areaurbavespemcp"
                      checked={checkboxes.areaurbavespemcp}
                      onChange={handleCheckboxChange}
                    />
                    Área urbanizada
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="areasruraisvespemcp"
                      checked={checkboxes.areasruraisvespemcp}
                      onChange={handleCheckboxChange}
                    />
                    Áreas rurais cadastradas
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="consuaguavespemcp"
                      checked={checkboxes.consuaguavespemcp}
                      onChange={handleCheckboxChange}
                    />
                    Consumo de Água por Bairro
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="dispaguavespemcp"
                      checked={checkboxes.dispaguavespemcp}
                      onChange={handleCheckboxChange}
                    />
                    Dispêndio de Água por Bairro
                  </label>

                  {/* <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="custoprojetovespemcp"
                        checked={checkboxes.custoprojetovespemcp}
                        onChange={handleCheckboxChange}
                      />
                      Custo de projeto (m²)
                    </label> */}
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  Terrenos
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="terrenosanunvespemcp"
                      checked={checkboxes.terrenosanunvespemcp}
                      onChange={handleCheckboxChange}
                    />
                    Terrenos anunciados
                  </label>
                  {/* <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="imoveisanunvespemcp"
                        checked={checkboxes.imoveisanunvespemcp}
                        onChange={handleCheckboxChange}
                      />
                      Imóveis anunciados
                    </label> */}
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  Pontos de interesse
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="pontosatravespemcp"
                      checked={checkboxes.pontosatravespemcp}
                      onChange={handleCheckboxChange}
                    />
                    Pontos Atratores
                  </label>
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="pontosdetravespemcp"
                      checked={checkboxes.pontosdetravespemcp}
                      onChange={handleCheckboxChange}
                    />
                    Pontos Detratores
                  </label>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  Vendas
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="imoveisvendidosemcp"
                      checked={checkboxes.imoveisvendidosemcp}
                      onChange={handleCheckboxChange}
                    />
                    Imóveis vendidos
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="outrossetoresemcp"
                      checked={checkboxes.outrossetoresemcp}
                      onChange={handleCheckboxChange}
                    />
                    Outros setores
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="setorescomimoveisemcp"
                      checked={checkboxes.setorescomimoveisemcp}
                      onChange={handleCheckboxChange}
                    />
                    Setores com imóveis vendidos
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="vendasemcp"
                      checked={checkboxes.vendasemcp}
                      onChange={handleCheckboxChange}
                    />
                    Vendas
                  </label>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>
          </div>
        )}

        {selectedMun &&
          userType === "tenda" &&
          selectedMun.value === "Osasco" && (
            <div className="container-items-check">
              <Accordion alwaysOpen className="w-full mb-4">
                <Accordion.Panel>
                  <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                    Aspectos Físicos
                  </Accordion.Title>
                  <Accordion.Content className="px-0 py-4">
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="limiteosascotnd"
                        checked={checkboxes.limiteosascotnd}
                        onChange={handleCheckboxChange}
                      />
                      Limite Municipal
                    </label>

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="areapreserosascotnd"
                        checked={checkboxes.areapreserosascotnd}
                        onChange={handleCheckboxChange}
                      />
                      Áreas de Preservação
                    </label>

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="vegnaivaosascotnd"
                        checked={checkboxes.vegnaivaosascotnd}
                        onChange={handleCheckboxChange}
                      />
                      Vegetação Nativa
                    </label>

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="reservaosascotnd"
                        checked={checkboxes.reservaosascotnd}
                        onChange={handleCheckboxChange}
                      />
                      Reserva Legal
                    </label>

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="nascentesosascotnd"
                        checked={checkboxes.nascentesosascotnd}
                        onChange={handleCheckboxChange}
                      />
                      Nascentes
                    </label>

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="cursososascotnd"
                        checked={checkboxes.cursososascotnd}
                        onChange={handleCheckboxChange}
                      />
                      Cursos e Corpos d’ água
                    </label>

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="topografiaosascotnd"
                        checked={checkboxes.topografiaosascotnd}
                        onChange={handleCheckboxChange}
                      />
                      Topografia
                    </label>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>

              <Accordion alwaysOpen className="w-full mb-4">
                <Accordion.Panel>
                  <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                    Aspectos Legais
                  </Accordion.Title>
                  <Accordion.Content className="px-0 py-4">
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="zoneamentoosascotnd"
                        checked={checkboxes.zoneamentoosascotnd}
                        onChange={handleCheckboxChange}
                      />
                      Zoneamento Municipal
                    </label>

                    {/* <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="usovertosascotnd"
                        checked={checkboxes.usovertosascotnd}
                        onChange={handleCheckboxChange}
                      />
                      Áreas de Uso Vertical
                    </label>

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="implantacaohishmposascotnd"
                        checked={checkboxes.implantacaohishmposascotnd}
                        onChange={handleCheckboxChange}
                      />
                      Benefícios/ implantação HIS ou HMP
                    </label> */}
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>

              <Accordion alwaysOpen className="w-full mb-4">
                <Accordion.Panel>
                  <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                    Infraestrutura
                  </Accordion.Title>
                  <Accordion.Content className="px-0 py-4">
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="servadmosascotnd"
                        checked={checkboxes.servadmosascotnd}
                        onChange={handleCheckboxChange}
                      />
                      Servidão Administrativa
                    </label>

                    {/* <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="areausorestosascotnd"
                        checked={checkboxes.areausorestosascotnd}
                        onChange={handleCheckboxChange}
                      />
                      Áreas de Uso restrito
                    </label> */}

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="areaconsoosascotnd"
                        checked={checkboxes.areaconsoosascotnd}
                        onChange={handleCheckboxChange}
                      />
                      Área Consolidada
                    </label>

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="areascontaosascotnd"
                        checked={checkboxes.areascontaosascotnd}
                        onChange={handleCheckboxChange}
                      />
                      Áreas Contaminadas
                    </label>

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="dutoviasosascotnd"
                        checked={checkboxes.dutoviasosascotnd}
                        onChange={handleCheckboxChange}
                      />
                      Dutovias
                    </label>

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="linhaoosascotnd"
                        checked={checkboxes.linhaoosascotnd}
                        onChange={handleCheckboxChange}
                      />
                      Linhão
                    </label>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>

              <Accordion alwaysOpen className="w-full mb-4">
                <Accordion.Panel>
                  <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                    Pontos de Interesse
                  </Accordion.Title>
                  <Accordion.Content className="px-0 py-4">
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="pontosatratosascotnd"
                        checked={checkboxes.pontosatratosascotnd}
                        onChange={handleCheckboxChange}
                      />
                      Pontos Atratores (escolas, hospitais e UBS, mercados)
                    </label>

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="pontosdetratosascotnd"
                        checked={checkboxes.pontosdetratosascotnd}
                        onChange={handleCheckboxChange}
                      />
                      Pontos Detratores (Cemitérios, Penitenciária, Indústrias
                      de transformação)
                    </label>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>

              <Accordion alwaysOpen className="w-full mb-4">
                <Accordion.Panel>
                  <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                    Aspectos Socioeconômicos
                  </Accordion.Title>
                  <Accordion.Content className="px-0 py-4">
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="rendaosascotnd"
                        checked={checkboxes.rendaosascotnd}
                        onChange={handleCheckboxChange}
                      />
                      Renda
                    </label>

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="domiciliososascotnd"
                        checked={checkboxes.domiciliososascotnd}
                        onChange={handleCheckboxChange}
                      />
                      Domicílios
                    </label>

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="domiciliosaluguelosascotnd"
                        checked={checkboxes.domiciliosaluguelosascotnd}
                        onChange={handleCheckboxChange}
                      />
                      Domicílios de Aluguel
                    </label>

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="expcidadesosascotnd"
                        checked={checkboxes.expcidadesosascotnd}
                        onChange={handleCheckboxChange}
                      />
                      Expansão Urbana da Cidades
                    </label>
                    {/* 
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="precomedioosascotnd"
                        checked={checkboxes.precomedioosascotnd}
                        onChange={handleCheckboxChange}
                      />
                      Preço médio dos  Anúncios de Terreno por Área
                    </label> */}

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="taxahabosascotnd"
                        checked={checkboxes.taxahabosascotnd}
                        onChange={handleCheckboxChange}
                      />
                      Taxa Habitacional
                    </label>

                    {/* <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="concoosascotnd"
                        checked={checkboxes.concoosascotnd}
                        onChange={handleCheckboxChange}
                      />
                      Concorrência
                    </label> */}

                    {/* <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="areaurbanizadaosascotnd"
                        checked={checkboxes.areaurbanizadaosascotnd}
                        onChange={handleCheckboxChange}
                      />
                      Área urbanizada
                    </label> */}

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="areasruraisosascotnd"
                        checked={checkboxes.areasruraisosascotnd}
                        onChange={handleCheckboxChange}
                      />
                      Áreas rurais cadastradas
                    </label>

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="vaziosurbanososascotnd"
                        checked={checkboxes.vaziosurbanososascotnd}
                        onChange={handleCheckboxChange}
                      />
                      Vazios Urbanos
                    </label>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>

              <Accordion alwaysOpen className="w-full mb-4">
                <Accordion.Panel>
                  <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                    Terrenos
                  </Accordion.Title>
                  <Accordion.Content className="px-0 py-4">
                    {/* <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="grapohabosascotnd"
                        checked={checkboxes.grapohabosascotnd}
                        onChange={handleCheckboxChange}
                      />
                      Cadastros da Grapohab
                    </label> */}

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="imoveisosascotnd"
                        checked={checkboxes.imoveisosascotnd}
                        onChange={handleCheckboxChange}
                      />
                      Imóveis anunciados
                    </label>

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="terrenoslivre"
                        checked={checkboxes.terrenoslivre}
                        onChange={handleCheckboxChange}
                      />
                      Terrenos (Parceiro Terreno Livre)
                    </label>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>

              <Accordion alwaysOpen className="w-full mb-4">
                <Accordion.Panel>
                  <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                    Setor
                  </Accordion.Title>
                  <Accordion.Content className="px-0 py-4">
                    {/* <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="tendenciassetorosascotnd"
                        checked={checkboxes.tendenciassetorosascotnd}
                        onChange={handleCheckboxChange}
                      />
                      Tendências setoriais
                    </label>

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="sinaismudancaosascotnd"
                        checked={checkboxes.sinaismudancaosascotnd}
                        onChange={handleCheckboxChange}
                      />
                      Sinais de mudança de mercado
                    </label>

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="oportunidadeosascotnd"
                        checked={checkboxes.oportunidadeosascotnd}
                        onChange={handleCheckboxChange}
                      />
                      Oportunidade de negócio
                    </label> */}
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>
            </div>
          )}

        {selectedMun && userType === "tenda2" && (
          <div className="container-items-check">
            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  Aspectos Físicos
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="limitestatnd"
                      checked={checkboxes.limitestatnd}
                      onChange={handleCheckboxChange}
                    />
                    Limite Municipal
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="areapreserstatnd"
                      checked={checkboxes.areapreserstatnd}
                      onChange={handleCheckboxChange}
                    />
                    Áreas de Preservação
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="vegnaivastatnd"
                      checked={checkboxes.vegnaivastatnd}
                      onChange={handleCheckboxChange}
                    />
                    Vegetação Nativa
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="reservastatnd"
                      checked={checkboxes.reservastatnd}
                      onChange={handleCheckboxChange}
                    />
                    Reserva Legal
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="nascentesstatnd"
                      checked={checkboxes.nascentesstatnd}
                      onChange={handleCheckboxChange}
                    />
                    Nascentes
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="hidrocarstatnd"
                      checked={checkboxes.hidrocarstatnd}
                      onChange={handleCheckboxChange}
                    />
                    Hidrografia CAR
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="hidrocompstatnd"
                      checked={checkboxes.hidrocompstatnd}
                      onChange={handleCheckboxChange}
                    />
                    Hidrografia Complementar
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="topografiastatnd"
                      checked={checkboxes.topografiastatnd}
                      onChange={handleCheckboxChange}
                    />
                    Topografia
                  </label>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  Aspectos Legais
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="zoneamentostatnd"
                      checked={checkboxes.zoneamentostatnd}
                      onChange={handleCheckboxChange}
                    />
                    Zoneamento Municipal
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="usovertstatnd"
                      checked={checkboxes.usovertstatnd}
                      onChange={handleCheckboxChange}
                    />
                    Áreas de Uso Vertical
                  </label>

                  {/* <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="implantacaohishmpstatnd"
                        checked={checkboxes.implantacaohishmpstatnd}
                        onChange={handleCheckboxChange}
                      />
                      Benefícios/Implantação HIS ou HMP
                    </label> */}
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  Infraestrutura
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="servadmstatnd"
                      checked={checkboxes.servadmstatnd}
                      onChange={handleCheckboxChange}
                    />
                    Servidão Administrativa
                  </label>

                  {/* <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="areausoreststatnd"
                        checked={checkboxes.areausoreststatnd}
                        onChange={handleCheckboxChange}
                      />
                      Áreas de Uso restrito
                    </label> */}

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="areaconsostatnd"
                      checked={checkboxes.areaconsostatnd}
                      onChange={handleCheckboxChange}
                    />
                    Área Consolidada
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="areascontastatnd"
                      checked={checkboxes.areascontastatnd}
                      onChange={handleCheckboxChange}
                    />
                    Áreas Contaminadas
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="dutoviasstatnd"
                      checked={checkboxes.dutoviasstatnd}
                      onChange={handleCheckboxChange}
                    />
                    Dutovias
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="linhaostatnd"
                      checked={checkboxes.linhaostatnd}
                      onChange={handleCheckboxChange}
                    />
                    Linhão
                  </label>

                  {/* <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="rededrenagemstatnd"
                      checked={checkboxes.rededrenagemstatnd}
                      onChange={handleCheckboxChange}
                    />
                    Rede de Drenagem
                  </label> */}
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  Pontos de Interesse
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="pontosatratstatnd"
                      checked={checkboxes.pontosatratstatnd}
                      onChange={handleCheckboxChange}
                    />
                    Pontos Atratores (escolas, hospitais e UBS, mercados)
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="pontosdetratstatnd"
                      checked={checkboxes.pontosdetratstatnd}
                      onChange={handleCheckboxChange}
                    />
                    Pontos Detratores (Cemitérios, Penitenciária, Indústrias de
                    transformação)
                  </label>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  Aspectos Socioeconômicos
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="domiciliosstatnd"
                      checked={checkboxes.domiciliosstatnd}
                      onChange={handleCheckboxChange}
                    />
                    Domicílios
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="rendastatnd"
                      checked={checkboxes.rendastatnd}
                      onChange={handleCheckboxChange}
                    />
                    Renda
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="domiciliosaluguelstatnd"
                      checked={checkboxes.domiciliosaluguelstatnd}
                      onChange={handleCheckboxChange}
                    />
                    Domicílios de Aluguel
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="expcidadesstatnd"
                      checked={checkboxes.expcidadesstatnd}
                      onChange={handleCheckboxChange}
                    />
                    Expansão Urbana da Cidades
                  </label>

                  {/* <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="expverticalstatnd"
                      checked={checkboxes.expverticalstatnd}
                      onChange={handleCheckboxChange}
                    />
                    Expansão Vertical
                  </label> */}

                  {/* <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="precomediostatnd"
                        checked={checkboxes.precomediostatnd}
                        onChange={handleCheckboxChange}
                      />
                      Preço médio dos  Anúncios de Terreno por Área
                    </label> */}

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="taxahabstatnd"
                      checked={checkboxes.taxahabstatnd}
                      onChange={handleCheckboxChange}
                    />
                    Taxa Habitacional
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="areaurbanizadastatnd"
                      checked={checkboxes.areaurbanizadastatnd}
                      onChange={handleCheckboxChange}
                    />
                    Área urbanizada
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="areasruraisstatnd"
                      checked={checkboxes.areasruraisstatnd}
                      onChange={handleCheckboxChange}
                    />
                    Áreas rurais cadastradas
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="vaziosurbanosstatnd"
                      checked={checkboxes.vaziosurbanosstatnd}
                      onChange={handleCheckboxChange}
                    />
                    Vazios Urbanos
                  </label>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  Terrenos
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="grapohabstatnd"
                      checked={checkboxes.grapohabstatnd}
                      onChange={handleCheckboxChange}
                    />
                    Cadastros da Grapohab
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="terrenosstatnd"
                      checked={checkboxes.terrenosstatnd}
                      onChange={handleCheckboxChange}
                    />
                    Terrenos anunciados
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="terrenoslivre"
                      checked={checkboxes.terrenoslivre}
                      onChange={handleCheckboxChange}
                    />
                    Terrenos anunciados (parceiro Terreno Livre)
                  </label>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <label className="flex items-center gap-2 px-6">
              <input
                type="checkbox"
                name="piscinao"
                checked={checkboxes.piscinao}
                onChange={handleCheckboxChange}
              />
              Piscinão
            </label>

            <label className="flex items-center gap-2 px-6">
              <input
                type="checkbox"
                name="piscininha"
                checked={checkboxes.piscininha}
                onChange={handleCheckboxChange}
              />
              Piscininha
            </label>
          </div>
        )}

        {selectedMun && userType === "gb" && (
          <div className="container-items-check">
            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  Aspectos Físicos
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="areapreserslgb"
                      checked={checkboxes.areapreserslgb}
                      onChange={handleCheckboxChange}
                    />
                    Áreas de Preservação
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="vegnativaslgb"
                      checked={checkboxes.vegnativaslgb}
                      onChange={handleCheckboxChange}
                    />
                    Vegetação Nativa
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="reservaslgb"
                      checked={checkboxes.reservaslgb}
                      onChange={handleCheckboxChange}
                    />
                    Reserva Legal
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="nascentesslgb"
                      checked={checkboxes.nascentesslgb}
                      onChange={handleCheckboxChange}
                    />
                    Nascentes
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="hidrografiaslgb"
                      checked={checkboxes.hidrografiaslgb}
                      onChange={handleCheckboxChange}
                    />
                    Hidrografia
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="topografiaslgb"
                      checked={checkboxes.topografiaslgb}
                      onChange={handleCheckboxChange}
                    />
                    Topografia
                  </label>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  Aspectos Legais
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="zoneamentoslgb"
                      checked={checkboxes.zoneamentoslgb}
                      onChange={handleCheckboxChange}
                    />
                    Zoneamento Municipal
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="usovertslgb"
                      checked={checkboxes.usovertslgb}
                      onChange={handleCheckboxChange}
                    />
                    Áreas de Uso Vertical
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="usohorslgb"
                      checked={checkboxes.usohorslgb}
                      onChange={handleCheckboxChange}
                    />
                    Áreas de Uso Horizontal
                  </label>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  Infraestrutura
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="raiopesquisaslgb"
                      checked={checkboxes.raiopesquisaslgb}
                      onChange={handleCheckboxChange}
                    />
                    Raio de pesquisa
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="terrenosanaslgb"
                      checked={checkboxes.terrenosanaslgb}
                      onChange={handleCheckboxChange}
                    />
                    Terrenos em análise
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="viasslgb"
                      checked={checkboxes.viasslgb}
                      onChange={handleCheckboxChange}
                    />
                    Vias de Acesso
                  </label>

                  {/* <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="dutoviasslgb"
                      checked={checkboxes.dutoviasslgb}
                      onChange={handleCheckboxChange}
                    />
                    Dutovias
                  </label> */}

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="linhaoslgb"
                      checked={checkboxes.linhaoslgb}
                      onChange={handleCheckboxChange}
                    />
                    Linhão
                  </label>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  Pontos de Interesse
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="pontosatratosslgb"
                      checked={checkboxes.pontosatratosslgb}
                      onChange={handleCheckboxChange}
                    />
                    Pontos Atratores (Escolas, mercados, shoppings, padarias)
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="pontosdetratslgb"
                      checked={checkboxes.pontosdetratslgb}
                      onChange={handleCheckboxChange}
                    />
                    Pontos Detratores (Cemitérios, penitenciárias)
                  </label>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  Aspectos Socioeconômicos
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="rendaslgb"
                      checked={checkboxes.rendaslgb}
                      onChange={handleCheckboxChange}
                    />
                    Renda
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="domiciliosslgb"
                      checked={checkboxes.domiciliosslgb}
                      onChange={handleCheckboxChange}
                    />
                    Domicílios
                  </label>

                  {/* <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="concoslgb"
                        checked={checkboxes.concoslgb}
                        onChange={handleCheckboxChange}
                      />
                      Concorrência/Graprohab
                    </label> */}

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="areasruraisslgb"
                      checked={checkboxes.areasruraisslgb}
                      onChange={handleCheckboxChange}
                    />
                    Áreas rurais cadastradas
                  </label>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  Terrenos
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4">
                  {/* 
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="terrenosslgb"
                        checked={checkboxes.terrenosslgb}
                        onChange={handleCheckboxChange}
                      />
                      Terrenos anunciados
                    </label> */}

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="imoveisslgb"
                      checked={checkboxes.imoveisslgb}
                      onChange={handleCheckboxChange}
                    />
                    Imóveis anunciados
                  </label>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>
          </div>
        )}

        {selectedMun && userType === "riva" && (
          <div className="container-items-check">
            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  Aspectos Físicos
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="limiteriva"
                      checked={checkboxes.limiteriva}
                      onChange={handleCheckboxChange}
                    />
                    Limite Municipal
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="recorteriva"
                      checked={checkboxes.recorteriva}
                      onChange={handleCheckboxChange}
                    />
                    Recorte da área de estudo
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="areapreserriva"
                      checked={checkboxes.areapreserriva}
                      onChange={handleCheckboxChange}
                    />
                    Áreas de preservação permanente
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="conservacaoriva"
                      checked={checkboxes.conservacaoriva}
                      onChange={handleCheckboxChange}
                    />
                    Unidades de conservação ambiental
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="cursosriva"
                      checked={checkboxes.cursosriva}
                      onChange={handleCheckboxChange}
                    />
                    Cursos d'água
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="nascentesriva"
                      checked={checkboxes.nascentesriva}
                      onChange={handleCheckboxChange}
                    />
                    Nascentes
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="areasriscoriva"
                      checked={checkboxes.areasriscoriva}
                      onChange={handleCheckboxChange}
                    />
                    Áreas de risco de inundação
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="areasriscocontriva"
                      checked={checkboxes.areasriscocontriva}
                      onChange={handleCheckboxChange}
                    />
                    Áreas de risco de contaminação do lençol freático
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="topografiariva"
                      checked={checkboxes.topografiariva}
                      onChange={handleCheckboxChange}
                    />
                    Topografia
                  </label>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            {/* <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  Aspectos Legais
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4">
                  <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="zoneamentoriva"
                        checked={checkboxes.zoneamentoriva}
                        onChange={handleCheckboxChange}
                      />
                      Zoneamento Municipal
                    </label>

                  <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="usovertriva"
                        checked={checkboxes.usovertriva}
                        onChange={handleCheckboxChange}
                      />
                      Áreas de Uso Vertical
                    </label>

                </Accordion.Content>
              </Accordion.Panel>
            </Accordion> */}

            {/* <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  Infraestrutura
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="aeroportosriva"
                      checked={checkboxes.aeroportosriva}
                      onChange={handleCheckboxChange}
                    />
                    Áreas de aeroportos
                  </label>


                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="dutoviasriva"
                      checked={checkboxes.dutoviasriva}
                      onChange={handleCheckboxChange}
                    />
                    Dutovias
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="linhaoriva"
                      checked={checkboxes.linhaoriva}
                      onChange={handleCheckboxChange}
                    />
                    Linhão
                  </label>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion> */}

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  Patrimônio Cultural
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="protculturalriva"
                      checked={checkboxes.protculturalriva}
                      onChange={handleCheckboxChange}
                    />
                    Área de Proteção Cultural CDPCM
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="bemcultriva"
                      checked={checkboxes.bemcultriva}
                      onChange={handleCheckboxChange}
                    />
                    Bem Cultural Imaterial
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="bemcultimovelriva"
                      checked={checkboxes.bemcultimovelriva}
                      onChange={handleCheckboxChange}
                    />
                    Bem Cultural Imóvel
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="bemcultnaturalriva"
                      checked={checkboxes.bemcultnaturalriva}
                      onChange={handleCheckboxChange}
                    />
                    Bem Cultural Natural
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="diretrizriva"
                      checked={checkboxes.diretrizriva}
                      onChange={handleCheckboxChange}
                    />
                    Diretriz de Proteção
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="tombamentoriva"
                      checked={checkboxes.tombamentoriva}
                      onChange={handleCheckboxChange}
                    />
                    Tombamento Municipal - ICMS
                  </label>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  Pontos de Interesse
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="pontosatratriva"
                      checked={checkboxes.pontosatratriva}
                      onChange={handleCheckboxChange}
                    />
                    Pontos Atratores
                  </label>

                  {/* <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="pontosdetratriva"
                      checked={checkboxes.pontosdetratriva}
                      onChange={handleCheckboxChange}
                    />
                    Pontos Detratores 
                  </label> */}
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  Aspectos Socioeconômicos
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4">
                  {/* <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="ocupacaoriva"
                      checked={checkboxes.ocupacaoriva}
                      onChange={handleCheckboxChange}
                    />
                    Ocupação (classificação supervisionada)
                  </label> */}

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="rendariva"
                      checked={checkboxes.rendariva}
                      onChange={handleCheckboxChange}
                    />
                    Renda
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="pmedioriva"
                      checked={checkboxes.pmedioriva}
                      onChange={handleCheckboxChange}
                    />
                    Preço médio dos Anúncios de Terreno por Área (preço compra)
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="taxahabriva"
                      checked={checkboxes.taxahabriva}
                      onChange={handleCheckboxChange}
                    />
                    Taxa Habitacional
                  </label>

                  {/* <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="concoriva"
                      checked={checkboxes.concoriva}
                      onChange={handleCheckboxChange}
                    />
                    Concorrência
                  </label> */}

                  {/* <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="vaziosriva"
                      checked={checkboxes.vaziosriva}
                      onChange={handleCheckboxChange}
                    />
                    Vazios Urbanos
                  </label> */}
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  Terrenos
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="terrenoslivre"
                      checked={checkboxes.terrenoslivre}
                      onChange={handleCheckboxChange}
                    />
                    Terrenos anunciados (Parceito Terreno Livre)
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="catimoveisriva"
                      checked={checkboxes.catimoveisriva}
                      onChange={handleCheckboxChange}
                    />
                    Categorização dos Imóveis
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="imoveisanunriva"
                      checked={checkboxes.imoveisanunriva}
                      onChange={handleCheckboxChange}
                    />
                    Imóveis anunciados
                  </label>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>
          </div>
        )}

        {selectedMun &&
          userType === "aureo" &&
          selectedMun.value === "Sorocaba" && (
            <div className="container-items-check">
              
            
            
              <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  Raio de 2km
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4">
                
                <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="aeror2soroaureo"
                  checked={checkboxes.aeror2soroaureo}
                  onChange={handleCheckboxChange}
                />
                Aeroporto
              </label>

              <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="shoppingr2soroaureo"
                  checked={checkboxes.shoppingr2soroaureo}
                  onChange={handleCheckboxChange}
                />
                Shopping
              </label>

              <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="condor2soroaureo"
                  checked={checkboxes.condor2soroaureo}
                  onChange={handleCheckboxChange}
                />
                Condomínio no entorno
              </label>
                
                <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="domiciliosaureo"
                  checked={checkboxes.domiciliosaureo}
                  onChange={handleCheckboxChange}
                />
                Domicílios
              </label>

              <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="empresasaureo"
                  checked={checkboxes.empresasaureo}
                  onChange={handleCheckboxChange}
                />
                Empresas no entorno
              </label>

              <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="locinteresseaureo"
                  checked={checkboxes.locinteresseaureo}
                  onChange={handleCheckboxChange}
                />
                Localização de Interesse
              </label>

              <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="marcasaureo"
                  checked={checkboxes.marcasaureo}
                  onChange={handleCheckboxChange}
                />
                Marcas de varejo
              </label>

              <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="pontosaureo"
                  checked={checkboxes.pontosaureo}
                  onChange={handleCheckboxChange}
                />
                Pontos de onibus
              </label>

              {/* <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="raio1kmaureo"
                  checked={checkboxes.raio1kmaureo}
                  onChange={handleCheckboxChange}
                />
                Raio de 1 Km
              </label> */}

              <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="raio2kmaureo"
                  checked={checkboxes.raio2kmaureo}
                  onChange={handleCheckboxChange}
                />
                Raio de 2 Km
              </label>

              <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="rendaaureo"
                  checked={checkboxes.rendaaureo}
                  onChange={handleCheckboxChange}
                />
                Renda
              </label>

              <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="viasaureo"
                  checked={checkboxes.viasaureo}
                  onChange={handleCheckboxChange}
                />
                Vias de acesso
              </label>

              <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="zoneamentoaureo"
                  checked={checkboxes.zoneamentoaureo}
                  onChange={handleCheckboxChange}
                />
                Zoneamento
              </label>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  Raio de 3km
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4">
                
                <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="domicilior3soroaureo"
                  checked={checkboxes.domicilior3soroaureo}
                  onChange={handleCheckboxChange}
                />
                Domicílios
              </label>

              <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="popr3soroaureo"
                  checked={checkboxes.popr3soroaureo}
                  onChange={handleCheckboxChange}
                />
                População
              </label>

              <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="varejor3soroaureo"
                  checked={checkboxes.varejor3soroaureo}
                  onChange={handleCheckboxChange}
                />
                Varejo
              </label>
                
                <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="industriar3soroaureo"
                  checked={checkboxes.industriar3soroaureo}
                  onChange={handleCheckboxChange}
                />
                Indústria
              </label>

                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  Raio de 4km
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4">
                
                <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="domicilior4soroaureo"
                  checked={checkboxes.domicilior4soroaureo}
                  onChange={handleCheckboxChange}
                />
                Domicílios
              </label>

              <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="popr4soroaureo"
                  checked={checkboxes.popr4soroaureo}
                  onChange={handleCheckboxChange}
                />
                População
              </label>

              <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="varejor4soroaureo"
                  checked={checkboxes.varejor4soroaureo}
                  onChange={handleCheckboxChange}
                />
                Varejo
              </label>
                
                <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="industriar4soroaureo"
                  checked={checkboxes.industriar4soroaureo}
                  onChange={handleCheckboxChange}
                />
                Indústria
              </label>

                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                  Raio de 5km
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4">
                
                <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="domicilior5soroaureo"
                  checked={checkboxes.domicilior5soroaureo}
                  onChange={handleCheckboxChange}
                />
                Domicílios
              </label>

              <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="popr5soroaureo"
                  checked={checkboxes.popr5soroaureo}
                  onChange={handleCheckboxChange}
                />
                População
              </label>

              <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="varejor5soroaureo"
                  checked={checkboxes.varejor5soroaureo}
                  onChange={handleCheckboxChange}
                />
                Varejo
              </label>
                
                <label className="flex items-center gap-2 px-6">
                <input
                  type="checkbox"
                  name="industriar5soroaureo"
                  checked={checkboxes.industriar5soroaureo}
                  onChange={handleCheckboxChange}
                />
                Indústria
              </label>

                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>
            </div>
          )}

        {selectedMun &&
          userType === "aureo" &&
          selectedMun.value === "Petrópolis" && (
            <div className="container-items-check">
              <Accordion alwaysOpen className="w-full mb-4">
                <Accordion.Panel>
                  <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                    Tv. José Machado de Costa, 15325 (Ponto 1)
                  </Accordion.Title>
                  <Accordion.Content className="px-0 py-4">
                    
                  <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="sud1petrop1aureo"
                        checked={checkboxes.sud1petrop1aureo}
                        onChange={handleCheckboxChange}
                      />
                      SUD1
                    </label>

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="sud2petrop1aureo"
                        checked={checkboxes.sud2petrop1aureo}
                        onChange={handleCheckboxChange}
                      />
                      SUD2
                    </label>
                    
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="comerciosaureopet1"
                        checked={checkboxes.comerciosaureopet1}
                        onChange={handleCheckboxChange}
                      />
                      Comércios
                    </label>
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="domiciliosaureopet1"
                        checked={checkboxes.domiciliosaureopet1}
                        onChange={handleCheckboxChange}
                      />
                      Domicílios
                    </label>

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="hidrologiaaureopet1"
                        checked={checkboxes.hidrologiaaureopet1}
                        onChange={handleCheckboxChange}
                      />
                      Hidrologia
                    </label>
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="empreendimentoaureopet1"
                        checked={checkboxes.empreendimentoaureopet1}
                        onChange={handleCheckboxChange}
                      />
                      Empreendimento
                    </label>
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="limiteaureopet1"
                        checked={checkboxes.limiteaureopet1}
                        onChange={handleCheckboxChange}
                      />
                      Limite de Petrópolis
                    </label>
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="localizacaoaureopet1"
                        checked={checkboxes.localizacaoaureopet1}
                        onChange={handleCheckboxChange}
                      />
                      Localização do empreendimento
                    </label>
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="marcasvareaureopet1"
                        checked={checkboxes.marcasvareaureopet1}
                        onChange={handleCheckboxChange}
                      />
                      Marcas de varejo
                    </label>
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="r1kmaureopet1"
                        checked={checkboxes.r1kmaureopet1}
                        onChange={handleCheckboxChange}
                      />
                      Raio de 1km
                    </label>
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="r2kmaureopet1"
                        checked={checkboxes.r2kmaureopet1}
                        onChange={handleCheckboxChange}
                      />
                      Raio de 2km
                    </label>
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="rendaaureopet1"
                        checked={checkboxes.rendaaureopet1}
                        onChange={handleCheckboxChange}
                      />
                      Renda
                    </label>

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="topografiaaureopet1"
                        checked={checkboxes.topografiaaureopet1}
                        onChange={handleCheckboxChange}
                      />
                      Topografia
                    </label>
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="viasacessoaureopet1"
                        checked={checkboxes.viasacessoaureopet1}
                        onChange={handleCheckboxChange}
                      />
                      Vias de acesso
                    </label>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>

              <Accordion alwaysOpen className="w-full my-4">
                <Accordion.Panel>
                  <Accordion.Title className="h-4 bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                    Rod. Presidente Juscelino Kubitschek (Ponto 2)
                  </Accordion.Title>
                  <Accordion.Content className="px-0 py-4">
                  <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="sud1petrop2aureo"
                        checked={checkboxes.sud1petrop2aureo}
                        onChange={handleCheckboxChange}
                      />
                      SUD1
                    </label>
                  <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="sud2petrop2aureo"
                        checked={checkboxes.sud2petrop2aureo}
                        onChange={handleCheckboxChange}
                      />
                      SUD2
                    </label>
                    
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="comerciosaureopet2"
                        checked={checkboxes.comerciosaureopet2}
                        onChange={handleCheckboxChange}
                      />
                      Comércios
                    </label>
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="domiciliosaureopet2"
                        checked={checkboxes.domiciliosaureopet2}
                        onChange={handleCheckboxChange}
                      />
                      Domicílios
                    </label>
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="hidrologiaaureopet2"
                        checked={checkboxes.hidrologiaaureopet2}
                        onChange={handleCheckboxChange}
                      />
                      Hidrologia
                    </label>
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="empreendimentoaureopet2"
                        checked={checkboxes.empreendimentoaureopet2}
                        onChange={handleCheckboxChange}
                      />
                      Empreendimento
                    </label>

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="localizacaoaureopet2"
                        checked={checkboxes.localizacaoaureopet2}
                        onChange={handleCheckboxChange}
                      />
                      Localização do empreendimento
                    </label>

                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="r1kmaureopet2"
                        checked={checkboxes.r1kmaureopet2}
                        onChange={handleCheckboxChange}
                      />
                      Raio de 1km
                    </label>
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="r2kmaureopet2"
                        checked={checkboxes.r2kmaureopet2}
                        onChange={handleCheckboxChange}
                      />
                      Raio de 2km
                    </label>
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="rendaaureopet2"
                        checked={checkboxes.rendaaureopet2}
                        onChange={handleCheckboxChange}
                      />
                      Renda
                    </label>
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="topografiaaureopet2"
                        checked={checkboxes.topografiaaureopet2}
                        onChange={handleCheckboxChange}
                      />
                      Topografia
                    </label>
                    <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="viasacessoaureopet2"
                        checked={checkboxes.viasacessoaureopet2}
                        onChange={handleCheckboxChange}
                      />
                      Vias de acesso
                    </label>
                    {/* <label className="flex items-center gap-2 px-6">
                      <input
                        type="checkbox"
                        name="zoneamentoaureopet2"
                        checked={checkboxes.zoneamentoaureopet2}
                        onChange={handleCheckboxChange}
                      />
                      Zoneamento_SUD_2
                    </label> */}
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>
            </div>
          )}

{selectedMun && userType === "acc3" && (
          <div className="container-items-check">
            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                ASPECTOS FÍSICOS
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4 text-sm">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="topografiaacc3"
                      checked={checkboxes.topografiaacc3}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Curvas de nível
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="spacc3"
                      checked={checkboxes.spacc3}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    São Paulo
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="guarulhosacc3"
                      checked={checkboxes.guarulhosacc3}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Guarulhos
                  </label>

                  
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            {/* <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                ASPECTOS LEGAIS
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4 text-sm">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="zoneamentoacc3"
                      checked={checkboxes.zoneamentoacc3}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Zoneamento Municipal
                  </label>

                </Accordion.Content>
              </Accordion.Panel>
            </Accordion> */}

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                INFRAESTRUTURA
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4 text-sm">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="areapousoacc3"
                      checked={checkboxes.areapousoacc3}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Área Pouso - Aeroporto Pista
                  </label>

                  {/* <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="rotaaviaoacc3"
                      checked={checkboxes.rotaaviaoacc3}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Rota de Avião
                  </label> */}

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="areascontacc3"
                      checked={checkboxes.areascontacc3}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Áreas Contaminadas
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="dutoviasacc3"
                      checked={checkboxes.dutoviasacc3}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Dutovias
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="trechoacc3"
                      checked={checkboxes.trechoacc3}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Trecho
                  </label>

                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                PONTOS DE INTERESSE
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4 text-sm">
                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="estabelecimentosacc3"
                      checked={checkboxes.estabelecimentosacc3}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Estabelecimentos no Entorno
                  </label>

                  {/* <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="veiculosacc3"
                      checked={checkboxes.veiculosacc3}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Número de veículos (ANTT)
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="fluxoveicacc3"
                      checked={checkboxes.fluxoveicacc3}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Fluxo de Veículos (Moto, Caminhão, Carro...)
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="movimentacaoacc3"
                      checked={checkboxes.movimentacaoacc3}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Movimentação
                  </label> */}
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion alwaysOpen className="w-full mb-4">
              <Accordion.Panel>
                <Accordion.Title className="h-4 font-bold text-xs bg-transparent border-none hover:bg-transparent focus:border-none focus:ring-0">
                ASPECTOS SOCIOECONÔMICOS
                </Accordion.Title>
                <Accordion.Content className="px-0 py-4 text-sm">
                  {/* <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="rendaacc3"
                      checked={checkboxes.rendaacc3}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Renda
                  </label> */}

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="populacaoacc3"
                      checked={checkboxes.populacaoacc3}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    População 
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="domiciliosacc3"
                      checked={checkboxes.domiciliosacc3}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Domicílios 
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="domialuguelacc3"
                      checked={checkboxes.domialuguelacc3}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Domicílios de aluguel
                  </label>

                  <label className="flex items-center gap-2 px-6">
                    <input
                      type="checkbox"
                      name="areaurbaacc3"
                      checked={checkboxes.areaurbaacc3}
                      className="w-3 h-3"
                      onChange={handleCheckboxChange}
                    />
                    Área urbanizada
                  </label>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

          </div>
        )}

        <Tooltip
          anchorSelect=".locked"
          style={{ backgroundColor: "orange", color: "white" }}
        >
          Faça login para desbloquear as camadas!
        </Tooltip>

        {!user && (
          <div
            className="login-section"
            style={{ cursor: "pointer" }}
            onClick={() => {
              history("/login");
            }}
          >
            <p>Faça Login</p>
            <AiOutlineArrowRight color="white" size={20}></AiOutlineArrowRight>
          </div>
        )}

        {/* {user &&

    <div className='login-section'>

      <div className='loggedasdiv'>
      <p className='loggedas'>Logado como:</p>
      <p className='uemail'>{user.email}</p>
      </div>
    <FaSignOutAlt color='orange' size={20} onClick={deslogar} style={{cursor: "pointer"}}></FaSignOutAlt>


    </div>

  } */}

        {/* <div className='user-infos'>
        <div className='avatar'>
            <img src={} referrerPolicy="no-referrer"></img>
        </div>
        <div className='user-name'>
            <p>saaa</p>
            <FaSignOutAlt className='signout' onClick={()=>{}} size={18} color='red'></FaSignOutAlt>
        </div>
    </div> */}

        {/* <Link to="/notas">
        <FaFileInvoiceDollar size={24} color='#fffff'></FaFileInvoiceDollar>
        Notas e Reembolsos
    </Link> */}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* Same as */}
        <ToastContainer />
      </div>
    )
  );
}

import {useState, createContext, useEffect} from 'react';
import {signInWithEmailAndPassword} from 'firebase/auth'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import { auth, db} from '../services/firebaseConnection';
import { toast } from 'react-toastify';



export const AuthContext = createContext({});

function AuthProvider({children}){

    const [user, setUser] = useState(null);

    useEffect(()=>{

        function loadStorage(){
            const storageUser = localStorage.getItem('@loggedAreaInsight');

            if(storageUser){
                setUser(JSON.parse(storageUser));
            }

        }

        loadStorage();

        

    },[])

    async function signIn(email, pass){
        await signInWithEmailAndPassword(auth, email, pass)
        .then(async (value)=>{
            let uid = value.user.uid;

            const docRef = doc(db, "users", uid)
            const docSnap = await getDoc(docRef);

            let data = {
                uid: uid,
                email: docSnap.data().email
            }

            setUser(data);
            storageUser(data);
        })
        .catch((error)=>{
            alert(error)
        })
    }

    function storageUser(data){
        localStorage.setItem('@loggedAreaInsight', JSON.stringify(data))
      }
    

      async function signOut(){
        await auth.signOut()
        .then(()=>{
            console.log('usuario deslogado')
        })
        .catch(()=>{
            console.log('nao foi possivel deslogar o usuario')
        })
        localStorage.removeItem('@loggedAreaInsight');
        setUser(null);
        
        toast.info('Desconectado', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
    }

    return(
        <AuthContext.Provider value={{ signed: !!user, user, signIn, signOut }}>
        {children}
      </AuthContext.Provider>
    )
}

export default AuthProvider;
import Routes from './RoutesLink';
import './App.css';
import AuthProvider from './contexts/auth';

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Routes/>
      </AuthProvider>
    </div>
  );
}

export default App;
